import {
  QueryDocumentSnapshot,
  Unsubscribe,
  addDoc,
  arrayUnion,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  updateDoc,
} from 'firebase/firestore';

import { FSCollections } from '@providers/firestoreProvider';
import { DemmiFS, DemmiLogType, Logger } from '@subhanhabib/demmilib';

import { parseToRefundRequest } from './_helper';
import {
  hasRefundRequestQuery,
  refundRequestQuery,
  refundRequestsQuery,
} from './_queries';

export class FSRefundRequests {
  static _listenToRefundRequests = async (
    vendorID: string,
    callback: (requests: DemmiFS.RefundRequest[]) => void,
  ): Promise<Unsubscribe> => {
    Logger({ objs: { vendorID } }, FSRefundRequests);
    return onSnapshot(refundRequestsQuery(vendorID), querySnapshot => {
      const requests: DemmiFS.RefundRequest[] = [];
      querySnapshot.forEach(
        (doc: QueryDocumentSnapshot<DemmiFS.FSRefundRequest>) => {
          requests.push(parseToRefundRequest(doc));
        },
      );
      callback(requests);
    });
  };

  static _listenToRefundRequest = async (
    requestID: string,
    callback: (requests?: DemmiFS.RefundRequest) => void,
  ): Promise<Unsubscribe> => {
    Logger({ objs: { requestID } }, FSRefundRequests);
    return onSnapshot(refundRequestQuery(requestID), querySnapshot => {
      callback(
        querySnapshot.exists()
          ? parseToRefundRequest(querySnapshot)
          : undefined,
      );
    });
  };

  static _hasRefundRequest = async (
    orderDocID: string,
    vendorID: string,
  ): Promise<string | undefined> => {
    Logger({ objs: { orderDocID, vendorID } }, FSRefundRequests);
    const docSnap = await getDocs(hasRefundRequestQuery(orderDocID, vendorID));
    return docSnap.empty ? undefined : docSnap.docs[0].id;
  };

  static _updateRefundRequest = async (
    requestID: string,
    update: DemmiFS.RefundRequestUpdate,
  ): Promise<void> => {
    Logger({ objs: { requestID, update } }, this._updateRefundRequest);
    const request = await getDoc(refundRequestQuery(requestID));
    if (!request) {
      Logger(
        {
          messages: ['Failed to find refund request to push update.'],
          objs: { requestID, update },
          type: DemmiLogType.error,
        },
        FSRefundRequests,
      );
      return;
    }

    const docRef = doc(FSCollections.RefundRequests, requestID);
    return updateDoc(docRef, {
      updates: arrayUnion(update),
    });
  };

  static _createRefundRequest = async (
    refundRequest: DemmiFS.FSRefundRequest,
  ): Promise<string> => {
    Logger({ objs: { refundRequest } }, FSRefundRequests);
    const docRef = await addDoc(FSCollections.RefundRequests, {
      ...refundRequest,
      // isArchived: product.isArchived === undefined ? false : product.isArchived,
      // createdAt: Timestamp.now(),
      // updatedAt: Timestamp.now(),
    });
    return docRef.id;
  };
}
