import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PageTabBase } from './_helper';

export interface Props<T extends PageTabBase> {
  tab: T;
}

const PageTab = <T extends PageTabBase>({ tab }: Props<T>): JSX.Element => {
  const CSSBlock = 'page-tab';

  return (
    <div className={`${CSSBlock} ${tab.active ? `${CSSBlock}--active` : ''}`} onClick={tab.onClick}>
      {tab.icon && (
        <span className={`${CSSBlock}__icon`}>
          <FontAwesomeIcon icon={tab.icon} />
        </span>
      )}
      <div className={`${CSSBlock}__text`}>{tab.text}</div>
    </div>
  );
};

export default PageTab;
