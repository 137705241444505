import React, { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '@core/hooks/appHooks';
import { NetworkService } from '@core/services/networkService/networkService';
import { selectVendor, setVendor } from '@core/store/appSlice/appSlice';
import DemmiButton from '@demmi-ui/Button';
import { demmiRequest } from '@helpers/app.helper';
import { TimeSlotsHelper } from '@helpers/timeslots.helper';
import { DemmiFS, DemmiHelpers } from '@subhanhabib/demmilib';

import { CSSBLOCK_SHOP_MANAGEMENT } from '../ShopManagement';
import {
  addTimeSlot,
  hasOpeningHoursChanged,
  isOpeningHoursValid,
  mapDaysDetailsToHours,
  mapHoursToDetails,
  onChangeTime,
  onToggleDay,
  removeTimeSlot,
} from './_helper';
import ShopManagementOpeningHoursDay from './partials/_day';

const ShopManagementOpeningHours: React.FC = () => {
  const CSSBlock = 'shop-management-opening-hours';
  const dispatch = useAppDispatch();
  const vendor = useAppSelector(selectVendor);
  const [hours, setHours] = useState<DemmiFS.VendorOpeningHours>(
    DemmiHelpers.Vendor.EmptyOpeningHours,
  );
  const [detectedChanges, setDetectedChanges] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [daysDetails, setDaysDetails] = useState<TimeSlotsHelper.DaysDetails>(
    mapHoursToDetails(hours),
  );

  const setValues = (vendor: DemmiFS.Vendor) => {
    setHours(vendor.shop?.openingHours);
    setDaysDetails(mapHoursToDetails(vendor.shop?.openingHours));
  };

  useEffect(() => {
    if (vendor) setValues(vendor);
  }, [vendor]);

  useEffect(() => {}, [hours]);

  useEffect(() => {
    setDetectedChanges(hasOpeningHoursChanged(hours, daysDetails));
    setIsValid(isOpeningHoursValid(daysDetails));
  }, [daysDetails]);

  const discardChanges = () => {
    if (vendor) setValues(vendor);
  };

  const saveChanges = async () => {
    if (isValid && vendor) {
      const v: DemmiFS.Vendor = {
        ...vendor,
        shop: {
          ...vendor.shop,
          openingHours: mapDaysDetailsToHours(daysDetails),
        },
      };
      demmiRequest(NetworkService.Vendors.updateVendorHours(v)).then(() =>
        dispatch(setVendor(v)),
      );
    }
  };

  const Day = (title: string, day: DemmiFS.DemmiHelper.DayKeys) => (
    <ShopManagementOpeningHoursDay
      CSSBlock={CSSBlock}
      dayTitle={title}
      dayDetail={daysDetails[day]}
      toggleDayOpen={onToggleDay(day, daysDetails, setDaysDetails)}
      onChangeTime={(key, i, type) =>
        onChangeTime(day, setDaysDetails, key)(i, type)
      }
      addTimeSlot={key => addTimeSlot(day, setDaysDetails, key)}
      removeTimeSlot={(key, i) => removeTimeSlot(day, setDaysDetails, key)(i)}
    />
  );

  return (
    <div className={`${CSSBLOCK_SHOP_MANAGEMENT}__content-wrapper ${CSSBlock}`}>
      {hours && (
        <div className={`${CSSBlock}__days`}>
          {Day('Monday', DemmiFS.DemmiHelper.DayKeys.MONDAY)}
          {Day('Tuesday', DemmiFS.DemmiHelper.DayKeys.TUESDAY)}
          {Day('Wednesday', DemmiFS.DemmiHelper.DayKeys.WEDNESDAY)}
          {Day('Thursday', DemmiFS.DemmiHelper.DayKeys.THURSDAY)}
          {Day('Friday', DemmiFS.DemmiHelper.DayKeys.FRIDAY)}
          {Day('Saturday', DemmiFS.DemmiHelper.DayKeys.SATURDAY)}
          {Day('Sunday', DemmiFS.DemmiHelper.DayKeys.SUNDAY)}
        </div>
      )}
      <div className={`${CSSBlock}__buttons`}>
        <span style={{ alignSelf: 'center', display: 'flex', gap: '1rem' }}>
          <DemmiButton
            text={'Discard Changes'}
            faIcon="fa-ban"
            disabled={!detectedChanges}
            onClick={() => discardChanges()}
          />
          <DemmiButton
            text={'Save Changes'}
            faIcon="fa-regular fa-floppy-disk"
            disabled={!detectedChanges || !isValid}
            onClick={() => saveChanges()}
          />
        </span>
      </div>
    </div>
  );
};

export default ShopManagementOpeningHours;
