import {
  DocumentData,
  QueryDocumentSnapshot,
  Timestamp,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';
import { getDownloadURL, ref } from 'firebase/storage';

import { addDoc } from '@firebase/firestore';
import { FSCollections } from '@providers/firestoreProvider';
import { DemmiFS, DemmiLogType, Logger } from '@subhanhabib/demmilib';

import { db, storage } from '../../../../firebase';
import { FSCollectionNames, StoragePaths } from '../networkService.helper';
import { NetworkService } from '../networkService';

export class FSProduct {
  static _createProduct = async (
    product: DemmiFS.FSProduct,
  ): Promise<string> => {
    Logger({ objs: { product } }, this._createProduct);
    const docRef = await addDoc(FSCollections.Products, {
      ...product,
      isArchived: product.isArchived === undefined ? false : product.isArchived,
      createdAt: Timestamp.now(),
      updatedAt: Timestamp.now(),
    });
    return docRef.id;
  };

  static _getProducts = async (
    vendorID: string,
    includeVariants = false,
  ): Promise<DemmiFS.Product[]> => {
    Logger({ objs: { vendorID } }, this._getProducts);
    const q = query(FSCollections.Products, where('vendorID', '==', vendorID));
    const querySnapshot = await getDocs(q);
    const products: DemmiFS.Product[] = [];

    if (includeVariants) {
      const variantRequests: {
        [key: string]: Promise<DemmiFS.ProductVariant[] | undefined>;
      } = {};

      querySnapshot.forEach((doc: QueryDocumentSnapshot<DocumentData>) => {
        products.push({
          docID: doc.id,
          ...(doc.data() as DemmiFS.FSProduct),
          variants: [],
        });
        variantRequests[doc.id] =
          NetworkService.ProductVariants.getProductVariants(doc.id);
      });

      await Promise.all(Object.values(variantRequests)).then(variants => {
        products.map(p => {
          const variantsIndex = Object.keys(variantRequests).indexOf(p.docID);
          return variantsIndex > -1
            ? { ...p, variants: variants[variantsIndex] }
            : p;
        });
      });
    } else {
      querySnapshot.forEach((doc: QueryDocumentSnapshot<DocumentData>) => {
        products.push({
          docID: doc.id,
          ...(doc.data() as DemmiFS.FSProduct),
          variants: [],
        });
      });
    }
    return products;
  };

  static _getProduct = async (
    productID: string,
    includeVariants = false,
  ): Promise<DemmiFS.Product | undefined> => {
    Logger({ objs: { productID } }, this._getProduct);
    const docSnap = await getDoc(
      doc(db, FSCollectionNames.PRODUCTS, productID),
    );

    if (docSnap.exists()) {
      if (includeVariants) {
        const variants =
          await NetworkService.ProductVariants.getProductVariants(productID);
        return {
          docID: docSnap.id,
          ...(docSnap.data() as DemmiFS.FSProduct),
          variants,
        };
      } else {
        return {
          docID: docSnap.id,
          ...(docSnap.data() as DemmiFS.FSProduct),
          variants: [],
        };
      }
    } else {
      Logger(
        {
          messages: ['No such document!'],
          objs: { productID },
          type: DemmiLogType.error,
        },
        this._getProduct,
      );
    }
    return undefined;
  };

  static _updateProduct = async (product: DemmiFS.Product): Promise<void> => {
    Logger({ objs: { product } }, this._updateProduct);
    const docRef = doc(FSCollections.Products, product.docID);
    return updateDoc(docRef, {
      name: product.name,
      categoryID: product.categoryID,
      shortDescription: product.shortDescription,
      longDescription: product.longDescription,
      images: product.images,
      tags: product.tags,
      updatedAt: Timestamp.now(),
    });
  };

  static _updateProductPublished = async (
    pID: string,
    isPublished: boolean,
  ): Promise<void> => {
    Logger({ objs: { pID, isPublished } }, this._updateProductPublished);
    const docRef = doc(FSCollections.Products, pID);
    return updateDoc(docRef, {
      isPublished: isPublished,
      updatedAt: Timestamp.now(),
    });
  };

  static _updateProductImages = async (
    id: string,
    images: DemmiFS.DemmiImageResource[],
  ): Promise<void> => {
    Logger({ objs: { id, images } }, this._updateProductImages);
    const docRef = doc(FSCollections.Products, id);
    return updateDoc(docRef, {
      images,
      updatedAt: Timestamp.now(),
    });
  };

  static _checkProductImageExists = async (
    vendorDocID: string,
    imageURL: string,
  ) => {
    const pathReference = ref(
      storage,
      `/${vendorDocID}/${StoragePaths.PRODUCT_IMAGES}/${imageURL}`,
    );
    return getDownloadURL(pathReference)
      .then(_ => true)
      .catch(_ => false);
  };

  static _deleteProduct = async (pID: string): Promise<void> => {
    Logger({ objs: { pID } }, this._deleteProduct);
    const docRef = doc(FSCollections.Products, pID);
    return deleteDoc(docRef);
  };

  static _updateProductArchived = async (
    pID: string,
    isArchived: boolean,
  ): Promise<void> => {
    Logger({ objs: { pID, isArchived } }, this._updateProductArchived);
    const docRef = doc(FSCollections.Products, pID);
    return updateDoc(docRef, {
      isArchived: isArchived,
      updatedAt: Timestamp.now(),
    });
  };
}
