import React from 'react';

import DemmiIconButton, { IconButtonSize } from '@demmi-ui/IconButton';
import DemmiInput from '@demmi-ui/Input';

import { SidebarProps, isSidebarTitleButton } from './_helper';

const SidebarHeader: React.FC<SidebarProps> = props => {
  const CSSBlock = 'sidebar';
  const { title, titleButtons, onSearch, searchPlaceholder } = props;

  const titleButtonsView = titleButtons && (
    <div className={`${CSSBlock}__title-buttons`}>
      {titleButtons.map((titleButton, i) =>
        isSidebarTitleButton(titleButton) ? (
          <DemmiIconButton
            key={i}
            faIcon={titleButton.faIcon}
            onClick={titleButton.onClick}
            size={IconButtonSize.SMALL}
          />
        ) : (
          titleButton
        ),
      )}
    </div>
  );

  const withTitleText = (
    <div className={`${CSSBlock}__title`}>
      {title}
      {titleButtonsView}
    </div>
  );

  const onlyTitleButtons = (
    <div className={`${CSSBlock}__title ${CSSBlock}__title--only-buttons`}>
      {titleButtonsView}
    </div>
  );

  return (
    <div className={`${CSSBlock}__header`}>
      {title ? withTitleText : titleButtons ? onlyTitleButtons : null}
      {onSearch && (
        <div className={`${CSSBlock}__search`}>
          <DemmiInput
            onChangeValue={onSearch}
            type="text"
            placeholder={searchPlaceholder}
          />
        </div>
      )}
    </div>
  );
};

export default SidebarHeader;
