import { Unsubscribe } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { DemmiFS, DemmiHelpers } from '@subhanhabib/demmilib';
import DashboardSection from '../DashboardSection';
import WidgetIncomingScheduleSlot from './WidgetIncomingScheduleSlot';
import { NetworkService } from '@core/services/networkService/networkService';

interface Props {
  vendor: DemmiFS.Vendor;
}

const getTodaysHours = (
  openingHours: DemmiFS.VendorOpeningHours,
): DemmiFS.OpeningHours | undefined => {
  switch (new Date().getDay()) {
    case 0:
      return openingHours.sun;
    case 1:
      return openingHours.mon;
    case 2:
      return openingHours.tue;
    case 3:
      return openingHours.wed;
    case 4:
      return openingHours.thu;
    case 5:
      return openingHours.fri;
    case 6:
      return openingHours.sat;
    default:
      return;
  }
};

interface TimeSlot {
  value: number;
  name: string;
  orders: DemmiFS.Order[];
}
const getTimes = (
  vendorID: string,
  hours: DemmiFS.OpeningHours | undefined,
  orders: DemmiFS.Order[],
): TimeSlot[] => {
  if (!hours) return [];
  // if (hours.open === hours.close) return [];
  const timeSlots: TimeSlot[] = [];
  const timeIncrement = 30;

  for (let index = hours.open; index < hours.close; index += timeIncrement) {
    // for (let index = 0; index < 1440; index += timeIncrement) {
    timeSlots.push({
      value: index,
      name: DemmiHelpers.DemmiTime.timeToString(index),
      orders: orders.filter(
        o =>
          o.vendors[vendorID]?.collectionTime !== undefined &&
          o.vendors[vendorID].collectionTime! >= index &&
          o.vendors[vendorID].collectionTime! < index + timeIncrement,
      ),
    });
  }
  return timeSlots;
};

const WidgetIncomingSchedule: React.FC<Props> = ({ vendor }) => {
  const CSSBlock = 'incoming-schedule';
  const hours = getTodaysHours(vendor.shop.openingHours);

  const [isLoading, setIsLoading] = useState(true);
  const [todaysOrders, setOrders] = useState<DemmiFS.Order[]>([]);
  const [slots, setSlots] = useState<TimeSlot[]>([]);

  useEffect(() => {
    let snapshotListener: Unsubscribe | undefined;
    if (vendor) {
      const ordersCallback = (o: DemmiFS.Order[]) => {
        const orders = DemmiFS.OrderHelper.getIncomingOrders(
          vendor.docID,
          o,
        ).filter(o => {
          const orderDate = o.vendors[vendor.docID].collectionDate;
          if (!orderDate) return false;
          const today = new Date();
          return (
            today.getFullYear() === orderDate.year &&
            today.getMonth() === orderDate.month - 1 &&
            today.getDate() === orderDate.day
          );
        });
        setOrders(DemmiFS.OrderHelper.sortByCollectionDate(orders));
        setIsLoading(false);
      };
      NetworkService.Orders.listenToOrders(vendor.docID, ordersCallback).then(
        u => (snapshotListener = u),
      );
    }

    return () => {
      if (snapshotListener) snapshotListener();
    };
  }, [vendor]);

  useEffect(() => {
    setSlots(getTimes(vendor.docID, hours, todaysOrders));
  }, [todaysOrders]);

  return (
    <DashboardSection
      className={CSSBlock}
      isLoading={isLoading}
      title="Collection Schedule">
      <div className={`${CSSBlock}__list`}>
        {slots.map((slot, i) => (
          <WidgetIncomingScheduleSlot
            slot={slot}
            key={i}
            hasPassed={i < 5}
            vendor={vendor}
          />
        ))}
      </div>
      {todaysOrders.length === 0 && (
        <div className={`${CSSBlock}__message`}>
          <div className={`${CSSBlock}__message-text`}>
            {!hours || hours.open === hours.close
              ? `Your shop is closed\nfor the day.`
              : 'No orders today'}
          </div>
        </div>
      )}
    </DashboardSection>
  );
};

export default WidgetIncomingSchedule;
