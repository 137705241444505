import { Unsubscribe } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import ModalCreateDemmiSupportTicket from '@components/modals/CreateDemmiSupportTicket/ModalCreateDemmiSupportTicket';
import PageWithSidebar from '@components/page/PageWithSidebar';
import { useAppSelector } from '@core/hooks/appHooks';
import { NetworkService } from '@core/services/networkService/networkService';
import { SearchParams } from '@core/services/routingService';
import { selectVendor } from '@core/store/appSlice/appSlice';
import { DemmiFS, Logger } from '@subhanhabib/demmilib';

import DemmiSupportPanelChat from './_chat';
import DemmiSupportSidebarButton from './_sidebarButton';

const DemmiSupport: React.FC = () => {
  const CSSBlock = 'demmi-support';
  const vendor = useAppSelector(selectVendor);
  const [searchParams, setSearchParams] = useSearchParams();
  const [chats, setChats] = useState<DemmiFS.DemmiSupportVendor[]>([]);
  const [filteredChats, setFilteredChats] = useState<
    DemmiFS.DemmiSupportVendor[]
  >([]);
  const [selectedChat, setSelectedChat] =
    useState<DemmiFS.DemmiSupportVendor>();

  useEffect(() => {
    let snapshotListener: Unsubscribe | undefined;
    if (vendor) {
      const cb = (r: DemmiFS.DemmiSupportVendor[]) => {
        Logger({ objs: { r } }, DemmiSupport);
        setChats(r);
        setFilteredChats(r);
        if (searchParams.get(SearchParams.SUPPORT_CHAT_ID)) {
          setSelectedChat(
            r.find(
              c => c.docID === searchParams.get(SearchParams.SUPPORT_CHAT_ID),
            ),
          );
        } else {
          setSelectedChat(undefined);
        }
      };
      NetworkService.DemmiSupport.listenToDemmiSupport(vendor.docID, cb).then(
        u => (snapshotListener = u),
      );
    }

    return () => {
      if (snapshotListener) snapshotListener();
    };
  }, [vendor]);

  useEffect(() => {
    if (searchParams.get(SearchParams.SUPPORT_CHAT_ID)) {
      setSelectedChat(
        filteredChats.find(
          c => c.docID === searchParams.get(SearchParams.SUPPORT_CHAT_ID),
        ),
      );
    } else {
      setSelectedChat(undefined);
    }
  }, [filteredChats, searchParams]);

  const onChatCreated = (id: string) => {
    setSearchParams({ [SearchParams.SUPPORT_CHAT_ID]: id });
  };

  const onClickChat = (i: number) => {
    const chat = chats[i];
    setSearchParams({ [SearchParams.SUPPORT_CHAT_ID]: chat.docID });
    setSelectedChat(chat);
  };

  const filterChats = (filter?: string) => {
    const organised = chats.reduce(
      (acc: DemmiFS.DemmiSupportVendor[], cur: DemmiFS.DemmiSupportVendor) => {
        if (filter && !cur.title.toLowerCase().includes(filter.toLowerCase()))
          return acc;
        else return [...acc, cur];
      },
      [],
    );
    setFilteredChats(organised);
  };

  const sidebarButtons = () =>
    chats.map((chat, i) => (
      <DemmiSupportSidebarButton
        key={i}
        CSSBlock={CSSBlock}
        chat={chat}
        lastMessage={chat.lastMessage}
      />
    ));

  const sidebarTitleButtons = vendor
    ? [
        <ModalCreateDemmiSupportTicket
          key={vendor.docID}
          onClose={() => {}}
          onConfirm={onChatCreated}
          vendorDocID={vendor?.docID}
        />,
      ]
    : [];

  return (
    <>
      <PageWithSidebar
        sidebar={{
          title: 'Demmi Support',
          titleButtons: sidebarTitleButtons,
          customButtons: sidebarButtons(),
          active: filteredChats.findIndex(f => selectedChat?.docID === f.docID),
          onClick: onClickChat,
          searchPlaceholder: 'Filter chats by title',
          onSearch: filterChats,
        }}
        title="Demmi Support Chat">
        <div className={`${CSSBlock}`}>
          {selectedChat && (
            <DemmiSupportPanelChat
              CSSBlock={CSSBlock}
              chatID={selectedChat.docID}
            />
          )}
        </div>
      </PageWithSidebar>
    </>
  );
};

export default DemmiSupport;
