import React, { useEffect, useState } from 'react';

import FakePhone from '@components/fakePhone/FakePhone';
import FakePhoneAppSwitcher from '@components/fakePhone/FakePhoneAppSwitcher';
import { useAppDispatch, useAppSelector } from '@core/hooks/appHooks';
import { selectVendor, setVendor } from '@core/store/appSlice/appSlice';
import DemmiButton from '@demmi-ui/Button';
import { demmiRequest } from '@helpers/app.helper';
import {
  CSSVARS,
  DemmiTheme,
  setCSSPhonePreviewPalette,
  setCSSVar,
} from '@helpers/theme.helper';
import { DemmiFS } from '@subhanhabib/demmilib';

import { CSSBLOCK_SETTINGS } from '../Settings';
import SettingsThemeHues from './SettingsThemeHues';
import { NetworkService } from '@core/services/networkService/networkService';

const SettingsTheme: React.FC = () => {
  const CSSBlock = 'settings-theme';
  const dispatch = useAppDispatch();
  const vendor = useAppSelector(selectVendor);
  const [app, setApp] = useState(0);

  const [hue, setHue] = useState(-1);
  const [detectedChanges, setDetectedChanges] = useState(false);

  const setValues = (vendor: DemmiFS.Vendor) => {
    setHue(vendor.themeHue);
    setCSSVar(CSSVARS.PHONE_PREVIEW_HUE, vendor.themeHue.toString());
    setCSSPhonePreviewPalette(DemmiTheme.get(vendor.themeHue).palette);
  };

  useEffect(() => {
    if (vendor) setValues(vendor);
  }, [vendor]);

  useEffect(() => {
    setDetectedChanges(hue !== vendor?.themeHue);
  }, [hue]);

  const discardChanges = () => {
    if (vendor) setValues(vendor);
  };

  const saveChanges = async () => {
    if (vendor) {
      const f: DemmiFS.Vendor = {
        ...vendor,
        themeHue: hue,
      };
      demmiRequest(NetworkService.Vendors.updateVendorTheme(f)).then(() =>
        dispatch(setVendor(f)),
      );
    }
  };

  const onHueChange = (hue: number) => {
    setCSSPhonePreviewPalette(DemmiTheme.get(hue).palette);
    setHue(hue);
  };

  return (
    <div className={`${CSSBLOCK_SETTINGS}__content-wrapper ${CSSBlock}`}>
      <FakePhone hue={hue} app={app} />
      <FakePhoneAppSwitcher app={app} setApp={setApp} />
      <SettingsThemeHues onHueChange={onHueChange} />

      <div className={`${CSSBlock}__buttons`}>
        <DemmiButton
          text={'Discard Changes'}
          faIcon="fa-ban"
          disabled={!detectedChanges}
          onClick={() => discardChanges()}
        />
        <DemmiButton
          text={'Save Changes'}
          faIcon="fa-regular fa-floppy-disk"
          disabled={!detectedChanges}
          onClick={() => saveChanges()}
        />
      </div>
    </div>
  );
};

export default SettingsTheme;
