export enum FSCollectionNames {
  CATEGORIES = 'categories',
  COUPONS = 'coupons',
  DEMMI = 'demmi',
  DEMMI_SUPPORT = 'demmi_support_vendor',
  LOGS_IMAGE_RESIZING = 'logs_image_resizing',
  NOTIFICATIONS = 'notifications_vendor',
  ORDERS = 'orders',
  PRODUCTS = 'products',
  REFUND_REQUEST = 'refund_requests',
  USERS = 'users',

  // Vendors
  VENDORS = 'vendors',
  VENDOR_USERS = 'vendor_users',
  VENDOR_TIME_SLOTS = 'vendor_time_slots',
  VENDOR_TIME_SLOT_SCHEDULE = 'vendor_time_slot_schedule',
  VENDOR_TIME_SLOT_EXCEPTIONS = 'vendor_time_slot_exceptions',
}

export enum FSSubCollectionNames {
  DEMMI_SUPPORT_MESSAGES = 'messages',
  REVIEWS = 'reviews',
  VARIANTS = 'variants',
}

export enum StorageBuckets {
  DEV_VENDOR_USERS = 'demmi-dev-vendors',
  DEV_PUBLIC = 'demmi-dev-public',
  PROD_VENDOR_USERS = 'demmi-prod-vendors',
  PROD_PUBLIC = 'demmi-prod-public',
}

export const getStorageBucketVendors = () =>
  process.env.IS_PRODUCTION
    ? StorageBuckets.PROD_VENDOR_USERS
    : StorageBuckets.DEV_VENDOR_USERS;
export const getStorageBucketPublic = () =>
  process.env.IS_PRODUCTION
    ? StorageBuckets.PROD_PUBLIC
    : StorageBuckets.DEV_PUBLIC;

export enum StoragePaths {
  PRODUCT_IMAGES = 'product_images',
  VENDOR_IMAGES = 'vendor_images',
}
