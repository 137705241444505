import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppSelector } from '@core/hooks/appHooks';
import useFetchCollectionSchedules from '@core/hooks/useFetchCollectionSchedules';
import useFetchExcludedDateIntervals from '@core/hooks/useFetchExcludedDateIntervals';
import { NetworkService } from '@core/services/networkService/networkService';
import { RoutingViews, routingPaths } from '@core/services/routingService';
import { selectVendor } from '@core/store/appSlice/appSlice';
import DemmiButton from '@demmi-ui/Button';
import Menu from '@demmi-ui/Menu/Menu';
import MenuTriggerButton from '@demmi-ui/Menu/MenuTriggerButton';
import * as faLight from '@fortawesome/pro-light-svg-icons';
import { demmiRequest } from '@helpers/app.helper';
import { ScheduleHelper } from '@helpers/schedule.helper';
import { CollectionScheduleMocks } from '@mocks/collectionSchedule.mock';
import {
  VendorMappedSchedule,
  VendorMappedScheduleDay,
} from '@models/vendorMappedSchedule.model';
import { CSSBLOCK_SHOP_MANAGEMENT } from '@pages/shopManagement/ShopManagement';
import { DemmiFS, DemmiLogType, Logger } from '@subhanhabib/demmilib';

import { CSSBLOCK_SM_COLLECTION_SCHEDULE } from '../CollectionSchedule';
import SMCollectionScheduleEditingView from '../_partials/_editingView';
import {
  VendorCollectionScheduleValidation,
  isCollectionScheduleDayValid,
} from './_helper';

const SMCollectionScheduleCreate: React.FC = () => {
  const CSSBlock = `${CSSBLOCK_SM_COLLECTION_SCHEDULE}-create`;
  const navigate = useNavigate();
  const vendor = useAppSelector(selectVendor);

  const [schedule, setSchedule] = useState<VendorMappedScheduleDay[]>([]);
  const [scheduleEndDate, setScheduleEndDate] = useState<Date>();
  const [scheduleStartDate, setScheduleStartDate] = useState<Date>();
  const excludedDateIntervals = useFetchExcludedDateIntervals();
  const existingSchedules = useFetchCollectionSchedules();

  const [isDayValid, setIsDayValid] =
    useState<VendorCollectionScheduleValidation>(
      isCollectionScheduleDayValid(schedule),
    );
  const [isDoingRequest, setIsDoingRequest] = useState(false);
  const [requestError, setRequestError] = useState<string>();

  useEffect(() => {
    setIsDayValid(isCollectionScheduleDayValid(schedule));
  }, [schedule]);

  const resetSchedule = () => setSchedule([]);
  const onClickDiscard = () => {
    navigate(routingPaths[RoutingViews.SHOP_MANAGEMENT_COLLECTION_SCHEDULE]);
  };
  const saveChanges = async () => {
    if (Object.values(isDayValid).filter(d => !!d).length === 0 && vendor) {
      setIsDoingRequest(true);
      const newSchedule = ScheduleHelper.mapToVendorTimeSlotSchedule(
        vendor.docID,
        crypto.randomUUID(),
        scheduleStartDate!,
        scheduleEndDate!,
        schedule,
      );

      await demmiRequest(
        NetworkService.VendorTimeSlotSchedule.createSchedule(newSchedule),
      )
        .then(a => {
          Logger(
            {
              messages: 'Created schedule',
              objs: { res: a },
            },
            SMCollectionScheduleCreate,
          );
          navigate(
            routingPaths[RoutingViews.SHOP_MANAGEMENT_COLLECTION_SCHEDULE],
          );
        })
        .catch(e => {
          setRequestError(e.message);
          Logger(
            {
              messages: 'Error creating schedule',
              objs: e,
              type: DemmiLogType.error,
            },
            SMCollectionScheduleCreate,
          );
        });
      setIsDoingRequest(false);
    }
  };

  const getDurationMenuContent = () => {
    return {
      sections: [
        {
          items: [mapMockedToSchedule(), ...existingSchedules].map(val => ({
            id: val.scheduleID,
            text: `${format(val.startDate!, 'dd MMM yyyy')} - ${format(val.endDate!, 'dd MMM yyyy')}`,
            item: val,
          })),
        },
      ],
    };
  };

  const mapMockedToSchedule = () => {
    const startDate = DemmiFS.getDateFromFirestore(
      CollectionScheduleMocks.mockSchedules[0].startDate,
    );
    const endDate = DemmiFS.getDateFromFirestore(
      CollectionScheduleMocks.mockSchedules[0].endDate,
    );
    const mapped = ScheduleHelper.mapToVendorMappedSchedule(
      CollectionScheduleMocks.mockSchedules[0].vendorID,
      CollectionScheduleMocks.mockSchedules[0].docID,
      startDate!,
      endDate!,
      CollectionScheduleMocks.mockSchedules,
    );
    return mapped as VendorMappedSchedule;
  };

  const onClickImportSchedule = (schedule: VendorMappedSchedule) => {
    setSchedule(schedule.days);
  };

  return (
    <div className={`${CSSBLOCK_SHOP_MANAGEMENT}__content-wrapper ${CSSBlock}`}>
      <div className={`${CSSBlock}__title`}>Create a new schedule</div>
      <div className={`${CSSBlock}__buttons`}>
        <span style={{ alignSelf: 'center', display: 'flex', gap: '1rem' }}>
          <Menu<VendorMappedSchedule>
            content={getDurationMenuContent()}
            triggerEl={
              <MenuTriggerButton
                text={'Import from previous'}
                icon={faLight.faCopy}
              />
            }
            onClick={onClickImportSchedule}
          />
          <DemmiButton
            text={'Reset Schedule'}
            faIcon="fa-regular fa-rotate-right"
            onClick={resetSchedule}
          />
        </span>
      </div>

      <SMCollectionScheduleEditingView
        schedule={schedule}
        setSchedule={setSchedule}
        startDate={scheduleStartDate}
        setStartDate={setScheduleStartDate}
        endDate={scheduleEndDate}
        setEndDate={setScheduleEndDate}
        isDoingRequest={isDoingRequest}
        onClickDiscard={onClickDiscard}
        onClickSave={saveChanges}
        isNewSchedule
        requestError={requestError}
        excludeDateIntevals={excludedDateIntervals}
        isDayValid={isDayValid}
        isStartDateValid={!!scheduleStartDate}
        isEndDateValid={!!scheduleEndDate}
      />
    </div>
  );
};

export default SMCollectionScheduleCreate;
