import { Toast } from 'radix-ui';
import React, { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '@core/hooks/appHooks';
import { popToast, selectToasts } from '@core/store/appSlice/appSlice';
import * as faSolid from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToastsHelper } from '@helpers/toasts.helper';
import { DemmiToast, DemmiToastType } from '@models/toast.model';

const DemmiToasts: React.FC = () => {
  const CSSBlock = 'demmi-toast';
  const toasts = useAppSelector(selectToasts);
  const dispatch = useAppDispatch();

  const getClassName = (toast: DemmiToast) =>
    `${CSSBlock} ${CSSBlock}--type-${toast.toastType}`;

  useEffect(() => {
    // ToastsHelper.createMockToasts();
  }, []);

  const onOpenChange = (toast: DemmiToast) => (open: boolean) => {
    console.log('onOpenChange', open, toast);
    if (!open) {
      dispatch(popToast(toast.uuid));
    }
  };

  const ToastButtonAction = (toast: DemmiToast) => (
    <Toast.Action
      className={`${CSSBlock}__action`}
      asChild
      altText="Goto schedule to undo">
      <button
        className={`${CSSBlock}__action-button`}
        onClick={() => {
          toast.action?.onClick();
        }}>
        {toast.action?.text}
      </button>
    </Toast.Action>
  );

  const ToastButtonClose = (toast: DemmiToast) => (
    <Toast.Close
      className={`${CSSBlock}__button-close`}
      aria-label="Close"
      onClick={() => dispatch(popToast(toast.uuid))}>
      <FontAwesomeIcon icon={faSolid.faClose} />
    </Toast.Close>
  );

  const ToastIcon = (toast: DemmiToast) => (
    <div className={`${CSSBlock}__icon`}>
      {toast.toastType === DemmiToastType.SUCCESS ? (
        <FontAwesomeIcon icon={faSolid.faCheck} />
      ) : toast.toastType === DemmiToastType.ERROR ? (
        <FontAwesomeIcon icon={faSolid.faExclamation} />
      ) : toast.toastType === DemmiToastType.WARNING ? (
        <FontAwesomeIcon icon={faSolid.faExclamation} />
      ) : (
        <FontAwesomeIcon icon={faSolid.faInfo} />
      )}
    </div>
  );

  const ToastAutoDismiss = (toast: DemmiToast) => (
    <Toast.Root
      className={getClassName(toast)}
      key={toast.uuid}
      // duration={toast.duration ?? 3000}
      defaultOpen={true}
      onOpenChange={onOpenChange(toast)}>
      {ToastIcon(toast)}
      <Toast.Title className={`${CSSBlock}__title`}>A{toast.title}</Toast.Title>
      {toast.description && (
        <Toast.Description className={`${CSSBlock}__description`}>
          {toast.description}
        </Toast.Description>
      )}

      <div className={`${CSSBlock}__buttons-area`}>
        {toast.action && ToastButtonAction(toast)}
      </div>
    </Toast.Root>
  );

  const ToastDismissable = (toast: DemmiToast) => (
    <Toast.Root
      className={getClassName(toast)}
      key={toast.uuid}
      onOpenChange={onOpenChange(toast)}>
      {ToastIcon(toast)}
      <Toast.Title className={`${CSSBlock}__title`}>{toast.title}</Toast.Title>
      {toast.description && (
        <Toast.Description className={`${CSSBlock}__description`}>
          {toast.description}
        </Toast.Description>
      )}
      <div className={`${CSSBlock}__buttons-area`}>
        {ToastButtonClose(toast)}
        {toast.action && ToastButtonAction(toast)}
      </div>
    </Toast.Root>
  );
  return (
    <>
      {toasts.map(toast =>
        toast.duration ? ToastAutoDismiss(toast) : ToastDismissable(toast),
      )}
      <Toast.Viewport className="demmi-toasts-viewport" />
    </>
  );
};

export default DemmiToasts;
