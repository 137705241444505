import { Unsubscribe } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useAppSelector } from '@core/hooks/appHooks';
import {
  selectDemmiSupportChatID,
  selectDemmiSupportOpen,
  selectVendor,
  setDemmiSupportChatID,
  setDemmiSupportOpen,
} from '@core/store/appSlice/appSlice';
import DemmiIconButton from '@demmi-ui/IconButton';
import { DemmiFS, Logger } from '@subhanhabib/demmilib';

import DemmiSupportPanel from './DemmiSupportPanel';
import { NetworkService } from '@core/services/networkService/networkService';

const DemmiSupport: React.FC = () => {
  const CSSBlock = 'demmi-support';
  const vendor = useAppSelector(selectVendor);
  const [chats, setChats] = useState<DemmiFS.DemmiSupport[]>([]);
  const isOpen = useAppSelector(selectDemmiSupportOpen);
  const selectedChatID = useAppSelector(selectDemmiSupportChatID);
  const dispatch = useDispatch();

  useEffect(() => {
    let snapshotListener: Unsubscribe | undefined;
    if (vendor) {
      const cb = (r: DemmiFS.DemmiSupport[]) => {
        Logger({ objs: { r } }, DemmiSupport);
        setChats(r);
      };
      NetworkService.DemmiSupport.listenToDemmiSupport(vendor.docID, cb).then(
        u => (snapshotListener = u),
      );
    }

    return () => {
      if (snapshotListener) snapshotListener();
    };
  }, [vendor]);

  const onChatCreated = (id: string) => {
    //refresh chats list
    setSelectedChatID(id);
  };

  const setIsOpen = (open: boolean) => dispatch(setDemmiSupportOpen(open));
  const setSelectedChatID = (id: string | undefined) =>
    dispatch(setDemmiSupportChatID(id));

  return (
    <>
      <span className={`${CSSBlock}__icon`}>
        <DemmiIconButton
          faIcon="fa-solid fa-message-bot"
          onClick={() => setIsOpen(true)}
        />
      </span>
      <DemmiSupportPanel
        chats={chats}
        selectedChatID={selectedChatID}
        setSelectedChatID={setSelectedChatID}
        onChatCreated={onChatCreated}
        isOpen={isOpen}
        onExited={() => setIsOpen(false)}
      />
    </>
  );
};

export default DemmiSupport;
