import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '@core/hooks/appHooks';
import {
  RoutingViews,
  getViewFromPath,
  routingPaths,
} from '@core/services/routingService';
import {
  getAppDate,
  getAppVersion,
  navigateTransitioned,
} from '@helpers/app.helper';
import { useAuth } from '@providers/authProvider';
import ThemeSwitcher from '@components/ThemeSwitcher';
import AsyncImage from '@components/asyncImage/AsyncImage';
import { selectVendor } from '@core/store/appSlice/appSlice';
import { DemmiFS, DemmiHelpers } from '@subhanhabib/demmilib';

interface Props {
  onNavigated: () => void;
}

const MobileMenu: React.FC<Props> = ({ onNavigated }) => {
  const CSSBlock = 'mobile-menu';
  const location = useLocation();
  const { logOut } = useAuth();
  const view = getViewFromPath(location.pathname, false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const vendor = useAppSelector(selectVendor);
  const [images, setImages] = useState<DemmiFS.DemmiImageResource[]>([]);

  useEffect(() => {
    if (vendor) setImages(vendor.images);
  }, [vendor]);

  return (
    <div className={`${CSSBlock}`}>
      <div className={`${CSSBlock}__vendor`}>
        <div className={`${CSSBlock}__vendor-image`}>
          {images.length > 0 && (
            <AsyncImage src={images[0].thumbnail} isVendor />
          )}
        </div>
        <div className={`${CSSBlock}__vendor-info-stack`}>
          <div className={`${CSSBlock}__vendor-name`}>{vendor?.name}</div>
          <div className={`${CSSBlock}__vendor-email`}>{vendor?.type}</div>
        </div>
      </div>

      <div className={`${CSSBlock}__divider`} />
      <ThemeSwitcher />
      <div className={`${CSSBlock}__divider`} />

      <div className={`${CSSBlock}__section`}>
        <div
          className={`${CSSBlock}__item ${
            view === RoutingViews.HOME ? `${CSSBlock}__item--active` : ''
          }`}
          onClick={() => {
            navigateTransitioned({ dispatch, navigate, to: routingPaths.home });
            onNavigated();
          }}>
          <span className={`${CSSBlock}__item-icon`}>
            <i className={'fa-solid fa-house'}></i>
          </span>
          <div className={`${CSSBlock}__item-text`}>Home</div>
        </div>

        <div
          className={`${CSSBlock}__item ${
            view === RoutingViews.INVENTORY ? `${CSSBlock}__item--active` : ''
          }`}
          onClick={() => {
            navigateTransitioned({
              dispatch,
              navigate,
              to: routingPaths.inventory,
            });
            onNavigated();
          }}>
          <span className={`${CSSBlock}__item-icon`}>
            <i className={'fa-solid fa-warehouse'}></i>
          </span>
          <div className={`${CSSBlock}__item-text`}>Inventory</div>
        </div>

        <div
          className={`${CSSBlock}__item ${
            view === RoutingViews.ANALYTICS ? `${CSSBlock}__item--active` : ''
          }`}
          onClick={() => {
            navigateTransitioned({
              dispatch,
              navigate,
              to: routingPaths.analytics,
            });
            onNavigated();
          }}>
          <span className={`${CSSBlock}__item-icon`}>
            <i className={'fa-solid fa-chart-mixed-up-circle-dollar'}></i>
          </span>
          <div className={`${CSSBlock}__item-text`}>Analytics</div>
        </div>

        <div
          className={`${CSSBlock}__item ${
            view === RoutingViews.ORDERS ? `${CSSBlock}__item--active` : ''
          }`}
          onClick={() => {
            navigateTransitioned({
              dispatch,
              navigate,
              to: routingPaths[RoutingViews.ORDERS_INCOMING],
            });
            onNavigated();
          }}>
          <span className={`${CSSBlock}__item-icon`}>
            <i className={'fa-solid fa-basket-shopping'}></i>
          </span>
          <div className={`${CSSBlock}__item-text`}>Orders</div>
        </div>

        <div
          className={`${CSSBlock}__item ${
            view === RoutingViews.REVIEWS ? `${CSSBlock}__item--active` : ''
          }`}
          onClick={() => {
            navigateTransitioned({
              dispatch,
              navigate,
              to: routingPaths.reviews,
            });
            onNavigated();
          }}>
          <span className={`${CSSBlock}__item-icon`}>
            <i className={'fa-solid fa-comment'}></i>
          </span>
          <div className={`${CSSBlock}__item-text`}>Reviews</div>
        </div>

        <div
          className={`${CSSBlock}__item ${
            view === RoutingViews.COUPONS ? `${CSSBlock}__item--active` : ''
          }`}
          onClick={() => {
            navigateTransitioned({
              dispatch,
              navigate,
              to: routingPaths.coupons,
            });
            onNavigated();
          }}>
          <span className={`${CSSBlock}__item-icon`}>
            <i className={'fa-solid fa-ticket'}></i>
          </span>
          <div className={`${CSSBlock}__item-text`}>Coupons</div>
        </div>
      </div>

      <div className={`${CSSBlock}__divider`} />

      <div className={`${CSSBlock}__section`}>
        <div
          className={`${CSSBlock}__item ${
            view === RoutingViews.SETTINGS ? `${CSSBlock}__item--active` : ''
          }`}
          onClick={() => {
            navigateTransitioned({
              dispatch,
              navigate,
              to: routingPaths[RoutingViews.SETTINGS_DETAILS],
            });
            onNavigated();
          }}>
          <span className={`${CSSBlock}__item-icon`}>
            <i className={'fa-solid fa-gear'}></i>
          </span>
          <div className={`${CSSBlock}__item-text`}>Settings</div>
        </div>
        <div
          className={`${CSSBlock}__item`}
          onClick={async () => {
            logOut(() => dispatch({ type: 'LOGOUT' }));
          }}>
          <span className={`${CSSBlock}__item-icon`}>
            <i className={'fa-solid fa-arrow-right-from-bracket'}></i>
          </span>
          <div className={`${CSSBlock}__item-text`}>Logout</div>
        </div>
      </div>

      {!DemmiHelpers.App.isProduction() && (
        <div className={`${CSSBlock}__version`}>
          <div className={`${CSSBlock}__version-build`}>
            <span className={`${CSSBlock}__version-key`}>Build version</span>
            <span className={`${CSSBlock}__version-val`}>
              {getAppVersion()}
            </span>
          </div>
          <div className={`${CSSBlock}__version-date`}>
            <span className={`${CSSBlock}__version-key`}>Build date</span>
            <span className={`${CSSBlock}__version-val`}>{getAppDate()}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileMenu;
