import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

export interface SidebarTitleButton {
  faIcon: string;
  onClick: () => void;
}

export interface SidebarCustomSection {
  sectionTitle: string;
  sectionIcon?: IconDefinition;
  buttons: { id: string; element: JSX.Element }[];
}

export interface SidebarSelfManagedWithSections extends _Base {
  customSectionedButtons: SidebarCustomSection[];
  onClick: (id: string) => void;
}

export interface SidebarSelfManaged extends _Base {
  customButtons: JSX.Element[];
  onClick: (index: number) => void;
}

export interface SidebarManagedButton {
  id: string;
  text: string;
  faIcon?: string;
}

export interface SidebarManaged extends _Base {
  buttons: SidebarManagedButton[];
  onClick: (id: string) => void;
}

interface _Base {
  active?: number | string;
  title?: string;
  titleButtons?: (SidebarTitleButton | JSX.Element)[];
  onSearch?: (val: string) => void;
  searchPlaceholder?: string;
  isLoading?: boolean;
  onClickParent?: () => void;
}

export type SidebarProps =
  | SidebarManaged
  | SidebarSelfManaged
  | SidebarSelfManagedWithSections;

export const isSidebarSelfManagedWithSections = (
  props: SidebarProps,
): props is SidebarSelfManagedWithSections => {
  return 'customSectionedButtons' in props;
};

export const isSidebarSelfManaged = (
  props: SidebarProps,
): props is SidebarSelfManaged => {
  return 'customButtons' in props;
};

export const isSidebarTitleButton = (
  b: SidebarTitleButton | JSX.Element,
): b is SidebarTitleButton => {
  return 'faIcon' in b;
};
