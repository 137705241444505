import { format } from 'date-fns';
import React from 'react';

import ElementLoadingIndicator from '@components/loadingIntro/ElementLoadingIndicator';
import DemmiButton from '@demmi-ui/Button';
import * as faLight from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ScheduleHelper } from '@helpers/schedule.helper';
import { VendorMappedScheduleDay } from '@models/vendorMappedSchedule.model';
import { DemmiFS } from '@subhanhabib/demmilib';

import { CSSBLOCK_SM_COLLECTION_SCHEDULE } from '../CollectionSchedule';
import {
  VendorCollectionScheduleValidation,
  onAddDayBreak,
  onChangeDayCapacity,
  onChangeDayDuration,
  onChangeDayTime,
  onRemoveDayBreak,
  onUpdateDayBreak,
  toggleDay,
} from '../Create/_helper';
import SMCollectionScheduleDatePicker from './_datePicker';
import SMCollectionScheduleDay from './_editingViewDay';

interface Props {
  schedule: VendorMappedScheduleDay[];
  setSchedule: React.Dispatch<React.SetStateAction<VendorMappedScheduleDay[]>>;
  startDate: Date | undefined;
  setStartDate: React.Dispatch<React.SetStateAction<Date | undefined>>;
  endDate: Date | undefined;
  setEndDate: React.Dispatch<React.SetStateAction<Date | undefined>>;
  isDoingRequest: boolean;
  onClickDiscard: () => void;
  onClickSave: () => void;
  isNewSchedule?: boolean;
  requestError?: string;
  excludeDateIntevals?: [Date, Date][];
  isDayValid: VendorCollectionScheduleValidation;
  isStartDateValid: boolean;
  isEndDateValid: boolean;
}

const SMCollectionScheduleEditingView: React.FC<Props> = ({
  schedule,
  setSchedule,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  isDoingRequest,
  onClickDiscard,
  onClickSave,
  isNewSchedule,
  requestError,
  excludeDateIntevals,
  isDayValid,
  isStartDateValid,
  isEndDateValid,
}) => {
  const CSSBlock = `${CSSBLOCK_SM_COLLECTION_SCHEDULE}-editing-view`;

  // Schedule dates
  const onChangeStartDate = (val: Date | undefined) => setStartDate(val);
  const onChangeEndDate = (val: Date | undefined) => setEndDate(val);

  // Day modifications
  const toggleDayEnabled = (
    day: DemmiFS.DemmiHelper.DayKeys,
    enabled: boolean,
  ) => {
    setSchedule(prev => {
      const updated = toggleDay(day, prev, enabled);
      return [...updated];
    });
  };
  const onChangeTime = (
    day: DemmiFS.DemmiHelper.DayKeys,
    type: 'start' | 'end',
    val: number,
  ) => {
    setSchedule(prev => {
      const updated = onChangeDayTime(day, prev, type, val);
      return [...updated];
    });
  };
  const onChangeDuration =
    (day: DemmiFS.DemmiHelper.DayKeys) =>
    (val: DemmiFS.VendorHelper.TimeSlotScheduleDuration) => {
      setSchedule(prev => {
        const updated = onChangeDayDuration(day, prev, val);
        return [...updated];
      });
    };
  const onChangeCapacity =
    (day: DemmiFS.DemmiHelper.DayKeys) => (val: number) => {
      setSchedule(prev => {
        const updated = onChangeDayCapacity(day, prev, val);
        return [...updated];
      });
    };

  // Break modifications
  const onAddBreak = (day: DemmiFS.DemmiHelper.DayKeys) => () => {
    setSchedule(prev => {
      const updated = onAddDayBreak(day, prev);
      return [...updated];
    });
  };
  const onRemoveBreak = (day: DemmiFS.DemmiHelper.DayKeys) => (i: number) => {
    setSchedule(prev => {
      const updated = onRemoveDayBreak(day, prev, i);
      return [...updated];
    });
  };
  const onUpdateBreak =
    (day: DemmiFS.DemmiHelper.DayKeys) =>
    (i: number, key: keyof DemmiFS.VendorHelper.TimeInterval, val: number) => {
      setSchedule(prev => {
        const updated = onUpdateDayBreak(day, prev, i, key, val);
        return [...updated];
      });
    };

  const Day = (day: DemmiFS.DemmiHelper.DayKeys) => (
    <SMCollectionScheduleDay
      day={day}
      key={day}
      existsInRange={ScheduleHelper.doesDayExistInDateRange(
        day,
        startDate!,
        endDate!,
      )}
      // eslint-disable-next-line react/prop-types
      slotSchedule={schedule.find(s => s.dayOfWeek === day)}
      toggleDayOpen={enabled => toggleDayEnabled(day, enabled)}
      onChangeTime={type => val => onChangeTime(day, type, val)}
      onChangeDuration={onChangeDuration(day)}
      onChangeCapacity={onChangeCapacity(day)}
      onAddBreak={onAddBreak(day)}
      onRemoveBreak={onRemoveBreak(day)}
      onUpdateBreak={onUpdateBreak(day)}
      isValid={isDayValid[day]}
    />
  );

  return (
    <div className={`${CSSBlock}`}>
      <div className={`${CSSBlock}__date`}>
        <SMCollectionScheduleDatePicker
          scheduleStartDate={startDate}
          scheduleEndDate={endDate}
          onChangeStartDate={onChangeStartDate}
          onChangeEndDate={onChangeEndDate}
          excludeDateIntevals={excludeDateIntevals}
        />
        <div className={`${CSSBlock}__date-text`}>
          From
          <span>{startDate && format(startDate, 'dd MMM yyyy')}</span>
          to
          <span>{endDate && format(endDate, 'dd MMM yyyy')}</span>
        </div>
      </div>

      <div className={`${CSSBlock}__days`}>
        {Object.values(DemmiFS.DemmiHelper.DayKeys).map(day => Day(day))}
      </div>
      {requestError && (
        <div className={`${CSSBlock}__error`}>
          <span>
            <FontAwesomeIcon icon={faLight.faWarning} />
            Failed to create your schedule
          </span>
          <div className={`${CSSBlock}__error-message`}>{requestError}</div>
        </div>
      )}
      <div className={`${CSSBlock}__buttons`}>
        <span style={{ alignSelf: 'center', display: 'flex', gap: '1rem' }}>
          <DemmiButton
            text={'Discard Changes'}
            faIcon="fa-ban"
            onClick={onClickDiscard}
          />
          <DemmiButton
            text={isNewSchedule ? 'Create Schedule' : 'Update Schedule'}
            faIcon="fa-regular fa-floppy-disk"
            disabled={
              Object.values(isDayValid).filter(d => !!d).length > 0 ||
              !isStartDateValid ||
              !isEndDateValid
            }
            onClick={() => onClickSave()}
          />
        </span>
      </div>

      <ElementLoadingIndicator isLoading={isDoingRequest} />
    </div>
  );
};

export default SMCollectionScheduleEditingView;
