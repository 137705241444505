import { doc, query, where } from 'firebase/firestore';

import { FSCollections } from '@providers/firestoreProvider';

import { FSSubCollectionNames } from '../networkService.helper';

// ----------
// Common Queries
// ----------

export const orderQuery = (orderDocID: string) =>
  doc(FSCollections.Orders, orderDocID);

export const ordersVendorQuery = (vendorID: string) =>
  query(
    FSCollections.Orders,
    where(`vendors.${vendorID}.themeHue`, '>', -1), // only if this vendor exists
  );

export const orderReviewsVendorQuery = (vendorID: string, orderID: string) =>
  query(
    FSCollections.OrderReviews([vendorID, FSSubCollectionNames.REVIEWS]),
    where('orderID', '==', orderID),
  );
