import { NavigateFunction } from 'react-router-dom';

import { NetworkService } from '@core/services/networkService/networkService';
import { RoutingViews, routingPaths } from '@core/services/routingService';
import { demmiRequest } from '@helpers/app.helper';
import { ScheduleHelper } from '@helpers/schedule.helper';
import { VendorMappedScheduleDay } from '@models/vendorMappedSchedule.model';
import { DemmiFS, DemmiLogType, Logger } from '@subhanhabib/demmilib';

import { VendorCollectionScheduleValidation } from '../Create/_helper';

export const generateUpdatedModels = (
  vendorDocID: string,
  isDayValid: VendorCollectionScheduleValidation,
  scheduleDocIDs: Partial<{ [key in DemmiFS.DemmiHelper.DayKeys]: string }>,
  scheduleStartDate: Date,
  scheduleEndDate: Date,
  schedule: VendorMappedScheduleDay[],
) => {
  if (Object.values(isDayValid).filter(d => !!d).length > 0 || !scheduleDocIDs)
    return [];

  return ScheduleHelper.mapToVendorTimeSlotSchedule(
    vendorDocID,
    crypto.randomUUID(),
    scheduleStartDate!,
    scheduleEndDate!,
    schedule,
  )
    .map(s => {
      const docID = Object.entries(scheduleDocIDs).find(
        ([key, _]) => key === s.dayOfWeek,
      );
      if (!docID) return;
      return {
        ...s,
        docID: docID[1],
      };
    })
    .filter(s => !!s);
};

export const updateSchedule = async (
  scheduleID: string,
  vendorDocID: string,
  isDayValid: VendorCollectionScheduleValidation,
  scheduleDocIDs: Partial<{ [key in DemmiFS.DemmiHelper.DayKeys]: string }>,
  scheduleStartDate: Date,
  scheduleEndDate: Date,
  schedule: VendorMappedScheduleDay[],
  setIsDoingRequest: (value: React.SetStateAction<boolean>) => void,
  setRequestError: (value: React.SetStateAction<string | undefined>) => void,
  navigate: NavigateFunction,
) => {
  const toUpdate = generateUpdatedModels(
    vendorDocID,
    isDayValid,
    scheduleDocIDs,
    scheduleStartDate!,
    scheduleEndDate!,
    schedule,
  );

  if (toUpdate.length > 0 && scheduleID) {
    setIsDoingRequest(true);
    await demmiRequest(
      NetworkService.VendorTimeSlotSchedule.updateSchedule(
        scheduleID,
        toUpdate,
      ),
    )
      .then(a => {
        Logger(
          {
            messages: 'Updated schedule',
            objs: { res: a },
          },
          updateSchedule,
        );
        navigate(
          routingPaths[RoutingViews.SHOP_MANAGEMENT_COLLECTION_SCHEDULE],
        );
      })
      .catch(e => {
        setRequestError(e.message);
        Logger(
          {
            messages: 'Error creating schedule',
            objs: e,
            type: DemmiLogType.error,
          },
          updateSchedule,
        );
      });
    setIsDoingRequest(false);
  }
};
