import { Timestamp, Unsubscribe } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';

import ElementLoadingIndicator from '@components/loadingIntro/ElementLoadingIndicator';
import { useAppSelector } from '@core/hooks/appHooks';
import { selectVendor } from '@core/store/appSlice/appSlice';
import DemmiIconButton from '@demmi-ui/IconButton';
import DemmiInput from '@demmi-ui/Input';
import { DemmiFS } from '@subhanhabib/demmilib';

import SupportChatItem from './_chatItem';
import SupportChatSnippetOrder from './_chatSnippetOrder';
import { NetworkService } from '@core/services/networkService/networkService';

interface Props {
  chatID: string;
  onNavigate: () => void;
}

const DemmiSupportPanelChat: React.FC<Props> = ({ chatID, onNavigate }) => {
  const CSSBlock = 'demmi-support-panel-chat';
  const vendor = useAppSelector(selectVendor);
  const [isLoading, setIsLoading] = useState(false);
  const [isSendingMessage, setIsSendingMessage] = useState(false);

  const [chat, setChat] = useState<DemmiFS.DemmiSupport>();
  const [messages, setMessages] = useState<DemmiFS.DemmiSupportMessage[]>();
  const [inputText, setInputText] = useState('');

  const sendMessage = () => {
    setIsSendingMessage(true);
    const message: DemmiFS.FSDemmiSupportMessage = {
      text: inputText,
      isSenderDemmi: false,
      timestamp: Timestamp.now(),
      read: false,
    };
    NetworkService.DemmiSupport.sendDemmiSupportMessage(chatID, message).then(
      () => setIsSendingMessage(false),
    );
  };

  useEffect(() => {
    if (vendor) {
      setMessages([]);
      setIsLoading(true);
      NetworkService.DemmiSupport.getDemmiSupportChat(chatID).then(c => {
        setChat(c);
        setIsLoading(false);
      });
    }
  }, [vendor, chatID]);

  useEffect(() => {
    let snapshotListener: Unsubscribe | undefined;
    if (vendor) {
      const cb = (r: DemmiFS.DemmiSupportMessage[]) => setMessages(r);
      NetworkService.DemmiSupport.listenToDemmiSupportChatMessages(
        chatID,
        cb,
      ).then(u => (snapshotListener = u));
    }

    return () => {
      if (snapshotListener) snapshotListener();
    };
  }, [vendor, chatID]);

  return (
    <div
      className={`${CSSBlock} ${isLoading ? `${CSSBlock}--is-loading` : ``}`}>
      <div className={`${CSSBlock}__title`}>{chat && chat.title}</div>
      {chat?.relatedOrderID && (
        <SupportChatSnippetOrder
          onNavigate={onNavigate}
          orderDocID={chat.relatedOrderID}
          CSSBlock={CSSBlock}
        />
      )}
      <div className={`${CSSBlock}__content`}>
        {isLoading ? (
          <></>
        ) : messages ? (
          messages.length > 0 ? (
            messages.map((message, i) => (
              <SupportChatItem key={i} message={message} />
            ))
          ) : (
            <div className={`${CSSBlock}__no-messages`}>
              No messages (yet) ...
            </div>
          )
        ) : (
          <div className={`${CSSBlock}__no-messages`}>
            You&apos;ve created a new chat!
          </div>
        )}
      </div>
      <div
        className={`${CSSBlock}__input-area ${
          isSendingMessage ? `${CSSBlock}__input-area--is-loading` : ``
        }`}>
        <DemmiInput
          placeholder="Message"
          type="text"
          value={inputText}
          onChangeValue={setInputText}
        />
        <DemmiIconButton faIcon={'fa-send'} onClick={sendMessage} />
        <ElementLoadingIndicator isLoading={isSendingMessage} />
      </div>
      <ElementLoadingIndicator isLoading={isLoading} />
    </div>
  );
};

export default DemmiSupportPanelChat;
