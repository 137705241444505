import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import ModalCreateCoupon from '@components/modals/CreateCoupon/ModalCreateCoupon';
import PageWithSidebar from '@components/page/PageWithSidebar';
import { useAppSelector } from '@core/hooks/appHooks';
import { NetworkService } from '@core/services/networkService/networkService';
import { SearchParams } from '@core/services/routingService';
import { selectVendor } from '@core/store/appSlice/appSlice';
import { demmiRequest } from '@helpers/app.helper';
import { DemmiFS } from '@subhanhabib/demmilib';

import CouponDetail from './CouponDetail';
import CouponSidebarButton from './_sidebarButton';

const Coupons: React.FC = () => {
  const CSSBlock = 'coupons';
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedCoupon, setSelectedCoupon] = useState<DemmiFS.Coupon>();
  const vendor = useAppSelector(selectVendor);
  const [coupons, setCoupons] = useState<DemmiFS.Coupon[]>([]);

  useEffect(() => {
    setCoupons([]);
    if (vendor) {
      demmiRequest(NetworkService.Coupons.getCoupons(vendor.docID)).then(c => {
        setCoupons(c);
        if (searchParams.get(SearchParams.COUPON_ID)) {
          setSelectedCoupon(
            c.find(c => c.docID === searchParams.get(SearchParams.COUPON_ID)),
          );
        } else {
          setSelectedCoupon(undefined);
        }
      });
    }
  }, [vendor]);

  const onClickRefresh = () => {
    if (vendor) {
      demmiRequest(NetworkService.Coupons.getCoupons(vendor.docID)).then(c => {
        setCoupons(c);
      });
      setSelectedCoupon(undefined);
    }
  };

  const onCreated = async (code: string) => {
    if (vendor) {
      await demmiRequest(NetworkService.Coupons.getCoupons(vendor.docID)).then(
        coupons => {
          setCoupons(coupons);
          setSelectedCoupon(coupons.find(c => c.docID === code));
        },
      );
    }
  };

  const onClickDelete = (code: string) => {
    if (vendor)
      demmiRequest(
        NetworkService.Coupons.deleteCoupon(code, vendor.docID),
      ).then(() => onClickRefresh());
  };

  const onClickCoupon = (coupon: DemmiFS.Coupon) => {
    setSearchParams({ [SearchParams.COUPON_ID]: coupon.docID });
    setSelectedCoupon(coupon);
  };

  const onUpdated = (coupon: DemmiFS.Coupon) => {
    const i = coupons.findIndex(c => c.docID === coupon.docID);
    if (i > -1) {
      setCoupons([...coupons.slice(0, i), coupon, ...coupons.slice(i + 1)]);
      setSelectedCoupon(coupon);
    }
  };

  const sidebarButtons = () =>
    coupons.map((coupon, i) => (
      <CouponSidebarButton
        key={i}
        CSSBlock={CSSBlock}
        coupon={coupon}
        onClick={() => onClickCoupon(coupon)}
        active={selectedCoupon?.docID === coupon.docID}
      />
    ));

  const sidebarTitleButtons = [
    { faIcon: 'fa-rotate-right', onClick: onClickRefresh },
    <ModalCreateCoupon onClose={() => {}} onConfirm={onCreated} />,
  ];

  return (
    <PageWithSidebar
      sidebar={{
        title: 'Coupons',
        titleButtons: sidebarTitleButtons,
        customButtons: sidebarButtons(),
        active: coupons.findIndex(f => selectedCoupon?.docID === f.docID),
        onClick: (i: number) => onClickCoupon(coupons[i]),
        searchPlaceholder: 'Your Support Chats',
      }}
      title="Coupons">
      <div className={`${CSSBlock}`}>
        {selectedCoupon && vendor && (
          <CouponDetail
            vendor={vendor}
            coupon={selectedCoupon}
            onClickDelete={onClickDelete}
            onUpdated={onUpdated}
          />
        )}
      </div>
    </PageWithSidebar>
  );
};

export default Coupons;
