import React, { useEffect, useState } from 'react';

import { useAppSelector } from '@core/hooks/appHooks';
import { selectVendor } from '@core/store/appSlice/appSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { demmiRequest } from '@helpers/app.helper';
import { DemmiFS } from '@subhanhabib/demmilib';

import DisplayProduct from './DisplayProduct';
import DisplayShop from './DisplayShop';
import {
  faBatteryHalf,
  faSignal,
  faWifi,
} from '@fortawesome/pro-solid-svg-icons';
import { NetworkService } from '@core/services/networkService/networkService';

interface Props {
  hue?: number;
  product?: DemmiFS.Product;
  app?: number;
}

const getVendorAddress = (vendor: DemmiFS.Vendor) => {
  const addressDefinitions = DemmiFS.DemmiAddressHelper.getFieldDefinitions(
    vendor.address.countryCode,
  );
  if (!addressDefinitions || !vendor.address.address) return '';

  let address = '';
  Object.entries(addressDefinitions).forEach(([key, _]) => {
    if (vendor.address.address![key as keyof DemmiFS.DemmiAddressFields])
      address +=
        (address.length > 0 ? ', ' : '') +
        vendor.address.address![key as keyof DemmiFS.DemmiAddressFields];
  });
  return address;
};

const FakePhone: React.FC<Props> = ({ hue, product, app = 0 }) => {
  const CSSBlock = 'fake-phone';
  const vendor = useAppSelector(selectVendor);
  const [mod, setMod] = useState('');
  const [productImages, setProductImages] = useState<string[]>([]);
  const [vendorAddress, setVendorAddress] = useState('');

  useEffect(() => {
    setProductImages([]);
    if (vendor) {
      setVendorAddress(getVendorAddress(vendor));
      demmiRequest(NetworkService.Product.getProducts(vendor.docID)).then(f =>
        setProductImages(
          f.reduce((acc: string[], cur) => {
            return [
              ...acc,
              ...(cur.images.length > 0 ? [cur.images[0].main] : []),
            ];
          }, []),
        ),
      );
    }
  }, [vendor]);

  useEffect(() => {
    setMod(`${CSSBlock}--animate`);
    setTimeout(() => setMod(''), 400);
  }, [hue]);

  return (
    <>
      <div
        className={`${CSSBlock} ${mod} ${CSSBlock}--isDarkMode ${
          hue && hue >= 60 && hue <= 180 ? `${CSSBlock}--light` : ''
        }`}>
        <div className={`${CSSBlock}__display`}>
          <div className={`${CSSBlock}__status-bar`}>
            <div className={`${CSSBlock}__status-bar-left`}>
              {'13:32'}
              <span className={`${CSSBlock}__icon`}>
                <FontAwesomeIcon icon={faWhatsapp} />
              </span>
              <span className={`${CSSBlock}__icon`}>
                <FontAwesomeIcon icon={faInstagram} />
              </span>
            </div>
            <div className={`${CSSBlock}__status-bar-right`}>
              <span className={`${CSSBlock}__icon`}>
                <FontAwesomeIcon icon={faWifi} />
              </span>
              <span className={`${CSSBlock}__icon`}>
                <FontAwesomeIcon icon={faSignal} />
              </span>
              <span className={`${CSSBlock}__icon`}>
                <FontAwesomeIcon icon={faBatteryHalf} />
              </span>
              55%
            </div>
          </div>
          <div className={`${CSSBlock}__app`}>
            {app === 0 && (
              <DisplayShop
                productImages={productImages}
                vendorAddress={vendorAddress}
              />
            )}
            {app === 1 && (
              <DisplayProduct
                productImages={productImages}
                product={product}
                vendor={vendor!}
                vendorAddress={vendorAddress}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default FakePhone;
