import React from 'react';

import { TimeSlotsHelper } from '@helpers/timeslots.helper';
import { DemmiFS, DemmiHelpers } from '@subhanhabib/demmilib';

interface Props {
  CSSBlock: string;
  isOpen: boolean;
  dayDetail: TimeSlotsHelper.CalculatedDayDetail;
}

const timeInterval = 60; // 60 minutes
interface TimeSlot {
  title: string;
  startValue: number;
  isOpen: boolean;
}

const ShopManagementOpeningHoursDayPreview: React.FC<Props> = ({
  CSSBlock,
  isOpen,
  dayDetail,
}) => {
  const allTimes = () => {
    const slots = [];
    for (let index = 0; index < 1440; index += timeInterval) {
      slots.push({
        title: DemmiHelpers.DemmiTime.timeToString(index),
        startValue: index,
        isOpen: dayDetail.timeSlots.some(
          slot => slot.start <= index && slot.end >= index + timeInterval,
        ),
      });
    }
    return slots;
  };

  const slotView = (slot: TimeSlot, i: number) => (
    <div className={`${CSSBlock}__time-slot ${CSSBlock}__empty-slot`} key={i}>
      <div className={`${CSSBlock}__time`}>
        <div className={`${CSSBlock}__time-text`}>{slot.title}</div>
        <div className={`${CSSBlock}__time-line`}></div>
      </div>
    </div>
  );

  const openSlotView = (slot: DemmiFS.VendorHelper.TimeInterval, i: number) => (
    <div
      className={`${CSSBlock}__open-slot`}
      style={{
        left: `${(1 * slot.start) / 10}rem`,
        width: `${(1 * (slot.end - slot.start)) / 10}rem`,
      }}
      key={i}>
      <div className={`${CSSBlock}__time`}></div>
    </div>
  );

  return (
    <div
      className={`${CSSBlock}__preview ${!isOpen ? `${CSSBlock}__preview--closed` : ``}`}>
      <div className={`${CSSBlock}__preview-timeline`}>
        {allTimes().map((slot, i) => slotView(slot, i))}
        {dayDetail.timeSlots.map((slot, i) => openSlotView(slot, i))}
      </div>
      {isOpen ? (
        <div className={`${CSSBlock}__preview-text`}>
          Opening Hours {`(${dayDetail.totalOpenHours} hours)`}:
          {dayDetail.timeSlots
            .map(slot => [
              `${DemmiHelpers.DemmiTime.formateTimeSegment(DemmiHelpers.DemmiTime.timeToHour(slot.start))}:${DemmiHelpers.DemmiTime.formateTimeSegment(DemmiHelpers.DemmiTime.timeToMinute(slot.start))}`,
              `${DemmiHelpers.DemmiTime.formateTimeSegment(DemmiHelpers.DemmiTime.timeToHour(slot.end))}:${DemmiHelpers.DemmiTime.formateTimeSegment(DemmiHelpers.DemmiTime.timeToMinute(slot.end))}`,
            ])
            .map((slot, i) => (
              <span key={i}>
                {slot[0]} - {slot[1]}
                {`${i < dayDetail.timeSlots.length - 1 ? ', ' : ''}`}
              </span>
            ))}
        </div>
      ) : (
        <div
          className={`${CSSBlock}__preview-text ${CSSBlock}__preview-text--closed`}>
          Your Shop Is Closed
        </div>
      )}
    </div>
  );
};
export default ShopManagementOpeningHoursDayPreview;
