import React, { useEffect, useState } from 'react';
import { Country, parsePhoneNumber } from 'react-phone-number-input';

import { demmiRequest } from '@helpers/app.helper';
import { DemmiFS } from '@subhanhabib/demmilib';
import { NetworkService } from '@core/services/networkService/networkService';

interface Props {
  CSSBlock: string;
  order: DemmiFS.Order;
}

const OrderItemTabCustomer: React.FC<Props> = ({ CSSBlock, order }) => {
  const [customer, setCustomer] = useState<DemmiFS.User>();
  const [contactNumber, setContactNumber] = useState<string>();

  useEffect(() => {
    demmiRequest(NetworkService.Customer.doc.getByDocID(order.customerID)).then(
      c => {
        setCustomer(c);
        if (c) {
          setContactNumber(
            parsePhoneNumber(
              c.contactNumber,
              c.address!.countryCode as Country,
            )?.formatInternational(),
          );
        }
      },
    );
  }, [order]);

  return (
    <div className={`${CSSBlock}__customer-details`}>
      <div className={`${CSSBlock}__key-val`}>
        <div className={`${CSSBlock}__key`}>Customer Name</div>
        <div className={`${CSSBlock}__value`}>
          {customer
            ? `${customer.title} ${customer.firstName} ${customer.lastName}`
            : '-'}
        </div>
      </div>
      <div className={`${CSSBlock}__key-val`}>
        <div className={`${CSSBlock}__key`}>Customer Contact Number</div>
        <div className={`${CSSBlock}__value`}>{contactNumber}</div>
      </div>
    </div>
  );
};

export default OrderItemTabCustomer;
