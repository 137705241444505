import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import Toolbar from '@components/toolbar/Toolbar';
import { useAppDispatch, useAppSelector } from '@core/hooks/appHooks';
import { getViewFromPath } from '@core/services/routingService';
import {
  selectIsLoading,
  selectVendor,
  setVendor,
} from '@core/store/appSlice/appSlice';
import {
  selectVendorUser,
  setVendorUser,
} from '@core/store/userSlice/userSlice';
import DemmiButton from '@demmi-ui/Button';
import { demmiRequest } from '@helpers/app.helper';
import { useAuth } from '@providers/authProvider';
import { DemmiHooks, Logger } from '@subhanhabib/demmilib';

import { auth } from '../../firebase';
import LoadingGlobal from '../loadingIntro/LoadingGlobal';
import * as faSolid from '@fortawesome/pro-solid-svg-icons';
import { NetworkService } from '@core/services/networkService/networkService';
import MobileMenu from '../mobileMenu/MobileMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import VendorWrapperHeader from './_header';

interface Props extends PropsWithChildren {
  isMounted: boolean;
  shouldRender: boolean;
}

const VendorWrapper: React.FC<Props> = ({
  isMounted,
  shouldRender,
  children,
}) => {
  const CSSBlock = 'vendor-wrapper';
  const mountedStyle = { animation: 'inAnimation 400ms ease-in-out' };
  const unmountedStyle = { animation: 'outAnimation 200ms ease-in-out' };

  const user = auth.currentUser;
  const vendorUser = useAppSelector(selectVendorUser);
  const vendor = useAppSelector(selectVendor);
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { logOut } = useAuth();
  const [isMobileMenuActive, setIsMobileMenuActive] = useState(false);

  useEffect(() => {
    if (user && !vendorUser) {
      Logger({ messages: ['useEffect'], objs: { user } }, VendorWrapper);
      demmiRequest(NetworkService.Vendors.getVendorUser(user?.uid)).then(f =>
        dispatch(setVendorUser(f)),
      );
    }
  }, [user, vendorUser]);

  useEffect(() => {
    if (!vendor && vendorUser)
      demmiRequest(NetworkService.Vendors.getVendor(vendorUser?.vendorID)).then(
        f => dispatch(setVendor(f)),
      );
  }, [vendor, vendorUser]);

  // Global loading indicator
  const isLoading = useAppSelector(selectIsLoading);
  const [isGlobalLoadingMounted, setIsGlobalLoadingMounted] = useState(false);
  const shouldRenderGlobalLoading = DemmiHooks.useDelayUnmount(
    isGlobalLoadingMounted,
    400,
  );
  useEffect(() => {
    setIsGlobalLoadingMounted(isLoading);
  }, [isLoading]);

  return (
    <>
      <div
        className={`${CSSBlock}__mobile-menu  ${isMobileMenuActive ? `${CSSBlock}__mobile-menu--active` : ''}`}>
        <div className={`mobile-menu__content`}>
          <MobileMenu onNavigated={() => setIsMobileMenuActive(false)} />
        </div>
      </div>
      <div
        className={`${CSSBlock} ${isMobileMenuActive ? `${CSSBlock}--mobile-menu-active` : ''}`}>
        <div className={`${CSSBlock}__background`} />
        <VendorWrapperHeader
          setIsMobileMenuActive={setIsMobileMenuActive}
          isMobileMenuActive={isMobileMenuActive}
        />

        {vendor ? (
          <>
            <div
              className={`${CSSBlock}__content ${CSSBlock}--${getViewFromPath(
                pathname,
              )}`}
              style={isMounted ? mountedStyle : unmountedStyle}>
              {shouldRender && children}
            </div>
            <div className={`${CSSBlock}__toolbar`}>
              <Toolbar />
            </div>
          </>
        ) : (
          <div className={`${CSSBlock}__content`}>
            <div
              className={`${CSSBlock}__error`}
              style={isMounted ? mountedStyle : unmountedStyle}>
              <div className={`${CSSBlock}__error-title`}>
                Something went wrong
              </div>
              <div className={`${CSSBlock}__error-subtitle`}>
                We&apos;re sorry for the inconvenience.
              </div>
              <DemmiButton
                text="Logout"
                onClick={() => logOut(() => dispatch({ type: 'LOGOUT' }))}
              />
            </div>
          </div>
        )}

        {shouldRenderGlobalLoading && (
          <LoadingGlobal isMounted={isGlobalLoadingMounted} />
        )}

        <div
          className={`${CSSBlock}__foreground`}
          onClick={() => setIsMobileMenuActive(false)}>
          <span className={`${CSSBlock}__foreground-icon`}>
            <FontAwesomeIcon icon={faSolid.faLeft} />
          </span>
        </div>
      </div>

      {/* <ToastsArea /> */}
      {/* <div id="toasts-area" /> */}
      <div id="modal-container" />
      <div id="notifications-panel" />
      <div id="demmi-support-panel" />
    </>
  );
};

export default VendorWrapper;
