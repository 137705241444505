import React, { useEffect, useLayoutEffect, useRef } from 'react';

import { VendorMappedScheduleDay } from '@models/vendorMappedSchedule.model';
import { DemmiFS, DemmiHelpers } from '@subhanhabib/demmilib';

import { CSSBLOCK_SM_COLLECTION_SCHEDULE } from '../CollectionSchedule';

interface Props {
  isOpen: boolean;
  day: VendorMappedScheduleDay;
}

const timeInterval = 60; // 60 minutes
interface TimeSlot {
  title: string;
  startValue: number;
}

const SMCollectionScheduleDayPreviewTimeline: React.FC<Props> = ({
  isOpen,
  day,
}) => {
  const CSSBlock = `${CSSBLOCK_SM_COLLECTION_SCHEDULE}-day-preview-timeline`;
  const scrollViewRef = useRef<HTMLDivElement>(null);
  const [daySlots, setDaySlots] = React.useState<
    DemmiFS.VendorHelper.TimeInterval[]
  >(
    DemmiHelpers.VendorTimeSlotsHelper.calculateScheduleDay(
      day.startTime,
      day.endTime,
      day.breaks,
    ),
  );

  useEffect(() => {
    setDaySlots(
      DemmiHelpers.VendorTimeSlotsHelper.calculateScheduleDay(
        day.startTime,
        day.endTime,
        day.breaks,
      ),
    );
  }, [day]);

  useLayoutEffect(() => {
    if (scrollViewRef.current) {
      const container = scrollViewRef.current.parentElement; // Assuming parent is the scrollable container
      if (container) {
        container.scrollTo({
          left: scrollViewRef.current.offsetLeft - 10,
          behavior: 'smooth',
        });
      }
    }
  }, [scrollViewRef, daySlots]);

  const allTimes = () => {
    const slots: TimeSlot[] = [];
    for (let index = 0; index < 1440; index += timeInterval) {
      slots.push({
        title: DemmiHelpers.DemmiTime.timeToString(index),
        startValue: index,
      });
    }
    return slots;
  };

  const slotView = (slot: TimeSlot, i: number) => (
    <div className={`${CSSBlock}__time-slot ${CSSBlock}__empty-slot`} key={i}>
      <div className={`${CSSBlock}__time`}>
        <div className={`${CSSBlock}__time-text`}>{slot.title}</div>
        <div className={`${CSSBlock}__time-line`}></div>
      </div>
    </div>
  );

  const availableSlot = (
    slot: DemmiFS.VendorHelper.TimeInterval,
    i: number,
  ) => (
    <div
      className={`${CSSBlock}__available-slot`}
      style={{
        left: `${slot.start / 10}rem`,
        width: `${(1 * (slot.end - slot.start)) / 10}rem`,
      }}
      {...(i === 0 ? { ref: scrollViewRef } : {})}
      key={i}>
      <div className={`${CSSBlock}__time`}></div>
    </div>
  );

  return (
    <div
      className={`${CSSBlock} ${!isOpen ? `${CSSBlock}__preview--closed` : ``}`}>
      <div className={`${CSSBlock}__preview-timeline`}>
        {allTimes().map((slot, i) => slotView(slot, i))}
        {daySlots.map((slot, i) => availableSlot(slot, i))}
      </div>
      {!isOpen && (
        <div
          className={`${CSSBlock}__preview-text ${CSSBlock}__preview-text--closed`}>
          <span>No Collection Slots</span>
        </div>
      )}
    </div>
  );
};
export default SMCollectionScheduleDayPreviewTimeline;
