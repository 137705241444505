import {
  QueryDocumentSnapshot,
  Unsubscribe,
  arrayUnion,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  updateDoc,
} from 'firebase/firestore';

import { FSCollections } from '@providers/firestoreProvider';
import { DemmiFS, DemmiLogType, Logger } from '@subhanhabib/demmilib';

import { FSSubCollectionNames } from '../networkService.helper';
import { parseToOrder } from './_helper';
import {
  orderQuery,
  orderReviewsVendorQuery,
  ordersVendorQuery,
} from './_queries';
import { NetworkService } from '../networkService';

export class FSOrders {
  static _listenToOrders = async (
    vendorID: string,
    callback: (orders: DemmiFS.Order[]) => void,
  ): Promise<Unsubscribe> => {
    return onSnapshot(ordersVendorQuery(vendorID), querySnapshot => {
      const orders: DemmiFS.Order[] = [];
      querySnapshot.forEach((doc: QueryDocumentSnapshot<DemmiFS.FSOrder>) => {
        orders.push(parseToOrder(vendorID, doc));
      });
      callback(orders);
    });
  };

  static _getOrders = async (vendorID: string): Promise<DemmiFS.Order[]> => {
    Logger({ objs: { vendorID } }, this._getOrders);
    const querySnapshot = await getDocs(ordersVendorQuery(vendorID));
    const orders: DemmiFS.Order[] = [];

    querySnapshot.forEach(
      async (doc: QueryDocumentSnapshot<DemmiFS.FSOrder>) => {
        orders.push(parseToOrder(vendorID, doc));
      },
    );

    return orders;
  };

  static _getOrdersWithRefunds = async (
    vendorID: string,
  ): Promise<DemmiFS.Order[]> => {
    const querySnapshot = await getDocs(ordersVendorQuery(vendorID));
    const orders: DemmiFS.Order[] = [];
    const refundRequests: { [key: string]: Promise<string | undefined> } = {};

    querySnapshot.forEach((doc: QueryDocumentSnapshot<DemmiFS.FSOrder>) => {
      refundRequests[doc.id] = NetworkService.RefundRequests.hasRefundRequest(
        doc.id,
        vendorID,
      );
    });

    await Promise.all(Object.values(refundRequests)).then(refunds => {
      querySnapshot.forEach(
        async (doc: QueryDocumentSnapshot<DemmiFS.FSOrder>) => {
          const refundIndex = Object.keys(refundRequests).indexOf(doc.id);
          if (refunds[refundIndex]) {
            orders.push(parseToOrder(vendorID, doc));
          }
        },
      );
    });

    return orders;
  };

  static _listenToOrder = async (
    orderDocID: string,
    vendorID: string,
    callback: (order?: DemmiFS.Order) => void,
  ): Promise<Unsubscribe> => {
    return onSnapshot(orderQuery(orderDocID), async querySnapshot => {
      if (querySnapshot.exists()) {
        callback(parseToOrder(vendorID, querySnapshot));
      } else {
        Logger(
          {
            messages: ['No such document!'],
            objs: { orderDocID, vendorID },
            type: DemmiLogType.error,
          },
          this._listenToOrder,
        );
        callback(undefined);
      }
    });
  };

  static _listenToOrderReview = async (
    orderID: string,
    vendorID: string,
    callback: (review?: DemmiFS.VendorReview) => void,
  ): Promise<Unsubscribe | undefined> => {
    const docSnap = await getDoc(doc(FSCollections.Orders, orderID));
    if (!docSnap.exists()) {
      return;
    }
    return onSnapshot(
      orderReviewsVendorQuery(vendorID, docSnap.data().orderID),
      async querySnapshot => {
        if (querySnapshot.empty) {
          callback(undefined);
        } else {
          const review = querySnapshot.docs[0].data();
          callback({
            ...review,
            docID: querySnapshot.docs[0].id,
            timestamp: querySnapshot.docs[0].data()['timestamp'],
            vendorID,
            orderID,
          });
        }
      },
    );
  };

  static _updateOrderReviewResponse = async (
    vendorID: string,
    reviewID: string,
    response: DemmiFS.FSVendorReviewResponse,
  ): Promise<void> => {
    const docRef = doc(
      FSCollections.OrderReviews([vendorID, FSSubCollectionNames.REVIEWS]),
      reviewID,
    );
    return updateDoc(docRef, {
      response: response,
    });
  };

  static _updateOrderTimeline = async (
    orderID: string,
    update: DemmiFS.OrderTimelineUpdate,
  ): Promise<void> => {
    const order = await getDoc(orderQuery(orderID));
    if (!order) {
      Logger(
        {
          messages: ['Failed to find order to push update.'],
          objs: { orderID, update },
          type: DemmiLogType.error,
        },
        this._updateOrderTimeline,
      );
      return;
    }

    const docRef = doc(FSCollections.Orders, orderID);
    return updateDoc(docRef, {
      timeline: arrayUnion(update),
    });
  };

  static _updateOrderStatus = async (
    orderID: string,
    update: DemmiFS.OrderTimelineUpdate,
  ): Promise<void> => {
    const order = await orderQuery(orderID);
    if (!order) {
      Logger(
        {
          messages: ['Failed to find order to push update.'],
          objs: { orderID, update },
          type: DemmiLogType.error,
        },
        this._updateOrderStatus,
      );
      return;
    }

    const docRef = doc(FSCollections.Orders, orderID);
    return updateDoc(docRef, {
      timeline: arrayUnion(update),
    });
  };
}
