import React, { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '@core/hooks/appHooks';
import { LSKeys, LocalStorage } from '@core/localStorage';
import { selectThemeMode, setThemeMode } from '@core/store/appSlice/appSlice';
import { ThemeMode, ThemeModeConstants } from '@helpers/theme.helper';

const ThemeSwitcher: React.FC = () => {
  const CSSBlock = 'theme-switcher';
  const dispatch = useAppDispatch();
  const themeMode = useAppSelector(selectThemeMode);
  const [modifierClass, setModifierClass] = useState('system');

  useEffect(() => {
    setModifierClass(
      themeMode === ThemeModeConstants.LIGHT
        ? 'light'
        : themeMode === ThemeModeConstants.DARK
          ? 'dark'
          : 'system',
    );
  }, [themeMode]);

  const onClick = (type: ThemeMode) => {
    dispatch(setThemeMode(type));
    LocalStorage.set(LSKeys.THEME_MODE, type);
  };

  return (
    <div className={`${CSSBlock} ${CSSBlock}--${modifierClass}`}>
      <div
        className={`${CSSBlock}__item ${
          modifierClass === 'system' ? `${CSSBlock}--active` : ``
        }`}
        onClick={() => onClick(ThemeModeConstants.SYSTEM)}>
        <div className={`${CSSBlock}__item-icon`}>
          <i className="fa-solid fa-desktop"></i>
        </div>
        <div className={`${CSSBlock}__item-text`}>System</div>
      </div>
      <div
        className={`${CSSBlock}__item ${
          modifierClass === 'light' ? `${CSSBlock}--active` : ``
        }`}
        onClick={() => onClick(ThemeModeConstants.LIGHT)}>
        <div className={`${CSSBlock}__item-icon`}>
          <i className="fa-solid fa-sun"></i>
        </div>
        <div className={`${CSSBlock}__item-text`}>Light</div>
      </div>
      <div
        className={`${CSSBlock}__item ${
          modifierClass === 'dark' ? `${CSSBlock}--active` : ``
        }`}
        onClick={() => onClick(ThemeModeConstants.DARK)}>
        <div className={`${CSSBlock}__item-icon`}>
          <i className="fa-solid fa-moon"></i>
        </div>
        <div className={`${CSSBlock}__item-text`}>Dark</div>
      </div>
      <div className={`${CSSBlock}__indicator`}></div>
    </div>
  );
};

export default ThemeSwitcher;
