import { format } from 'date-fns';
import React from 'react';

import { VendorMappedSchedule } from '@models/vendorMappedSchedule.model';
import { DemmiFS } from '@subhanhabib/demmilib';

import { CSSBLOCK_SM_COLLECTION_SCHEDULE } from '../CollectionSchedule';
import SMCollectionSchedulePreviewViewDay from './_previewViewDay';

interface Props {
  schedule: VendorMappedSchedule;
}

const SMCollectionSchedulePreviewView: React.FC<Props> = ({ schedule }) => {
  const CSSBlock = `${CSSBLOCK_SM_COLLECTION_SCHEDULE}-preview-view`;

  const Day = (
    schedule: VendorMappedSchedule,
    day: DemmiFS.DemmiHelper.DayKeys,
  ) => (
    <SMCollectionSchedulePreviewViewDay
      day={day}
      key={day}
      // eslint-disable-next-line react/prop-types
      slotSchedule={schedule.days.find(s => s.dayOfWeek === day)}
    />
  );

  return (
    <div className={`${CSSBlock}`}>
      <div className={`${CSSBlock}__date`}>
        <div className={`${CSSBlock}__date-text`}>
          From
          <span>{schedule && format(schedule.startDate, 'dd MMM yyyy')}</span>
          to
          <span>{schedule && format(schedule.endDate, 'dd MMM yyyy')}</span>
        </div>
      </div>
      {schedule && (
        <div className={`${CSSBlock}__days`}>
          {Object.values(DemmiFS.DemmiHelper.DayKeys).map(day =>
            Day(schedule, day),
          )}
        </div>
      )}
    </div>
  );
};

export default SMCollectionSchedulePreviewView;
