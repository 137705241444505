import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import ModalBoolean from '@components/modals/ModalBoolean';
import { useAppSelector } from '@core/hooks/appHooks';
import useFetchExcludedDateIntervals from '@core/hooks/useFetchExcludedDateIntervals';
import { NetworkService } from '@core/services/networkService/networkService';
import {
  RouteParams,
  RoutingViews,
  routingPaths,
} from '@core/services/routingService';
import { selectVendor } from '@core/store/appSlice/appSlice';
import { demmiRequest } from '@helpers/app.helper';
import { ScheduleHelper } from '@helpers/schedule.helper';
import {
  VendorMappedSchedule,
  VendorMappedScheduleDay,
} from '@models/vendorMappedSchedule.model';
import { CSSBLOCK_SHOP_MANAGEMENT } from '@pages/shopManagement/ShopManagement';
import { DemmiFS } from '@subhanhabib/demmilib';

import { CSSBLOCK_SM_COLLECTION_SCHEDULE } from '../CollectionSchedule';
import {
  VendorCollectionScheduleValidation,
  isCollectionScheduleDayValid,
} from '../Create/_helper';
import SMCollectionScheduleEditingView from '../_partials/_editingView';
import SMCollectionSchedulePreviewView from '../_partials/_previewView';
import { updateSchedule } from './_helper';

const SMCollectionScheduleEditing: React.FC = () => {
  const CSSBlock = `${CSSBLOCK_SM_COLLECTION_SCHEDULE}-edit`;
  const navigate = useNavigate();
  const { [RouteParams.SM_SCHEDULE_ITEM_ID]: scheduleID } = useParams();
  const vendor = useAppSelector(selectVendor);

  const [schedule, setSchedule] = useState<VendorMappedSchedule>();
  const [scheduleDays, setScheduleDays] = useState<VendorMappedScheduleDay[]>(
    [],
  );
  const [scheduleDocIDs, setScheduleDaysDocIDs] =
    useState<Partial<{ [key in DemmiFS.DemmiHelper.DayKeys]: string }>>();
  const [scheduleStartDate, setScheduleDaysStartDate] = useState<Date>();
  const [scheduleEndDate, setScheduleDaysEndDate] = useState<Date>();
  const excludedDateIntervals = useFetchExcludedDateIntervals(scheduleID);

  const [isDayValid, setIsDayValid] =
    useState<VendorCollectionScheduleValidation>(
      isCollectionScheduleDayValid(scheduleDays),
    );
  const [isDoingRequest, setIsDoingRequest] = useState(false);
  const [requestError, setRequestError] = useState<string>();
  const [isEditable, setIsEditable] = useState(false);

  useEffect(() => {
    setIsDayValid(isCollectionScheduleDayValid(scheduleDays));
  }, [scheduleDays]);

  useEffect(() => {
    if (vendor && scheduleID) {
      demmiRequest(
        NetworkService.VendorTimeSlotSchedule.getSchedule(
          vendor.docID,
          scheduleID,
        ),
      ).then(o => {
        if (o.length === 0) return;

        const startDate = DemmiFS.getDateFromFirestore(
          o.find(s => s.startDate)?.startDate,
        );
        const endDate = DemmiFS.getDateFromFirestore(
          o.find(s => s.endDate)?.endDate,
        );
        setScheduleDaysDocIDs(
          o.reduce((acc, cur) => ({ ...acc, [cur.dayOfWeek]: cur.docID }), {}),
        );

        if (!startDate || !endDate) return;

        const mapped = ScheduleHelper.mapToVendorMappedSchedule(
          vendor.docID,
          scheduleID,
          startDate,
          endDate,
          o,
        );
        setSchedule(mapped);
        setScheduleDays(mapped.days);
        setScheduleDaysStartDate(startDate);
        setScheduleDaysEndDate(endDate);
        setIsEditable(startDate > new Date());
      });
    }
  }, [vendor, scheduleID]);

  const onClickDiscard = () => {
    navigate(routingPaths[RoutingViews.SHOP_MANAGEMENT_COLLECTION_SCHEDULE]);
  };

  const onClickSave = async () => {
    if (!vendor || !scheduleID || !scheduleDocIDs) return;
    await updateSchedule(
      scheduleID,
      vendor.docID,
      isDayValid,
      scheduleDocIDs,
      scheduleStartDate!,
      scheduleEndDate!,
      scheduleDays,
      setIsDoingRequest,
      setRequestError,
      navigate,
    );
  };

  const onClickDelete = () => {
    if (vendor && scheduleID)
      demmiRequest(
        NetworkService.VendorTimeSlotSchedule.deleteSchedule(
          vendor.docID,
          scheduleID,
        ),
      ).then(() => {
        navigate(
          routingPaths[RoutingViews.SHOP_MANAGEMENT_COLLECTION_SCHEDULE],
        );
      });
  };

  return (
    <div className={`${CSSBLOCK_SHOP_MANAGEMENT}__content-wrapper ${CSSBlock}`}>
      <div className={`${CSSBlock}__title`}>Update Your Schedule</div>
      {isEditable ? (
        scheduleDays && (
          <>
            <SMCollectionScheduleEditingView
              schedule={scheduleDays}
              setSchedule={setScheduleDays}
              startDate={scheduleStartDate}
              setStartDate={setScheduleDaysStartDate}
              endDate={scheduleEndDate}
              setEndDate={setScheduleDaysEndDate}
              isDoingRequest={isDoingRequest}
              onClickDiscard={onClickDiscard}
              onClickSave={onClickSave}
              requestError={requestError}
              excludeDateIntevals={excludedDateIntervals}
              isDayValid={isDayValid}
              isStartDateValid={!!scheduleStartDate}
              isEndDateValid={!!scheduleEndDate}
            />

            {scheduleStartDate && scheduleEndDate && (
              <div className={`${CSSBlock}__delete`}>
                <ModalBoolean
                  titleText={`Are you sure you want to delete`}
                  itemText={`${format(scheduleStartDate, 'dd MMM yyyy')} - ${format(scheduleEndDate, 'dd MMM yyyy')}`}
                  descriptionText={`Warning: This action cannot be undone.`}
                  onClose={() => {}}
                  onConfirm={onClickDelete}
                  triggerButtonText={'Delete Schedule'}
                  triggerButtonIcon={'fa-regular fa-trash'}
                />
                <div className={`${CSSBlock}__warning`}>
                  Warning: This cannot be undone.
                </div>
              </div>
            )}
          </>
        )
      ) : (
        <>
          <div className={`${CSSBlock}__no-edit`}>
            This schedule cannot be modified.
          </div>
          {schedule && <SMCollectionSchedulePreviewView schedule={schedule} />}
        </>
      )}
    </div>
  );
};

export default SMCollectionScheduleEditing;
