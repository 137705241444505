import { Timestamp } from 'firebase/firestore';

import { DemmiFS } from '@subhanhabib/demmilib';
import { NetworkService } from '@core/services/networkService/networkService';

export enum DemmiSupportTypes {
  MISSING_LOCATION,
  BUG_REPORT,
  DASHBOARD_HELP,
  ORDER_REFUND_DISPUTE,
  ORDER_GENERIC,
}

interface NewDemmiSupport {
  vendorID: string;
  title: string;
  initialMessage: string;
  relatedOrderID?: string;
}

const _createNewDemmiSupport = (
  type: DemmiSupportTypes,
  vendorID: string,
  vendorName: string,
  relatedOrderID?: string,
): NewDemmiSupport | undefined => {
  switch (type) {
    case DemmiSupportTypes.MISSING_LOCATION:
      return {
        title: `Unable to find correct vendor address.`,
        initialMessage: `${vendorName} was unable to find the correct address for their location.`,
        vendorID,
      };
    case DemmiSupportTypes.BUG_REPORT:
      return {
        title: `Bug report`,
        initialMessage: `${vendorName} was unable to find the correct address for their location.`,
        vendorID,
      };
    case DemmiSupportTypes.DASHBOARD_HELP:
      return {
        title: `Dashboard help`,
        initialMessage: `${vendorName} was unable to find the correct address for their location.`,
        vendorID,
      };
    case DemmiSupportTypes.ORDER_REFUND_DISPUTE:
      return {
        title: `Refund dispute`,
        initialMessage: `${vendorName} was unable to find the correct address for their location.`,
        vendorID,
        relatedOrderID,
      };
    case DemmiSupportTypes.ORDER_GENERIC:
      return {
        title: `Order query`,
        initialMessage: `${vendorName} was unable to find the correct address for their location.`,
        vendorID,
        relatedOrderID,
      };
    default:
      return;
  }
};

export const createSupportTicket = async (
  type: DemmiSupportTypes,
  vendor: DemmiFS.Vendor,
  onCreated?: (success: boolean, chatID: string | undefined) => void,
  relatedOrderID?: string,
) => {
  const supportTicket = _createNewDemmiSupport(
    type,
    vendor.docID,
    vendor.name,
    relatedOrderID,
  );
  if (!supportTicket) {
    if (onCreated) onCreated(false, undefined);
    return;
  }

  const id = await NetworkService.DemmiSupport.createDemmiSupportChat({
    title: supportTicket.title,
    vendorID: vendor.docID,
    createdAt: Timestamp.now(),
    ...(supportTicket.relatedOrderID
      ? { relatedOrderID: supportTicket.relatedOrderID }
      : {}),
  });

  await NetworkService.DemmiSupport.sendDemmiSupportMessage(id, {
    text: supportTicket.initialMessage,
    timestamp: Timestamp.now(),
    isSenderDemmi: false,
    read: false,
  });

  if (onCreated) onCreated(true, id);
};
