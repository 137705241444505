import React, { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '@core/hooks/appHooks';
import { selectVendor, setVendor } from '@core/store/appSlice/appSlice';
import DemmiButton from '@demmi-ui/Button';
import DemmiCard from '@demmi-ui/Card/Card';
import DemmiIconButton from '@demmi-ui/IconButton';
import InputTime from '@demmi-ui/InputTime/InputTime';
import { demmiRequest } from '@helpers/app.helper';
import { DemmiFS, DemmiHelpers } from '@subhanhabib/demmilib';

import DaysList from './DaysList';
import { NetworkService } from '@core/services/networkService/networkService';

const SettingsCollectionSlots: React.FC = () => {
  const CSSBlock = 'settings-collection-slots';
  const dispatch = useAppDispatch();
  const vendor = useAppSelector(selectVendor);
  const [hours, setHours] = useState<DemmiFS.VendorOpeningHours>(
    DemmiHelpers.Vendor.EmptyOpeningHours,
  );
  const [detectedChanges, setDetectedChanges] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const [selectedDay, setSelectedDay] = useState(0);

  const setValues = (vendor: DemmiFS.Vendor) => {
    setHours(vendor.shop?.openingHours);
  };

  useEffect(() => {
    if (vendor) setValues(vendor);
  }, [vendor]);

  useEffect(() => {
    if (vendor) {
      setDetectedChanges(
        DemmiHelpers.Vendor.openingHoursHasChanges(
          vendor.shop.openingHours,
          hours,
        ),
      );
      setIsValid(DemmiHelpers.Vendor.isOpeningHoursValid(hours));
    }
  }, [hours]);

  const discardChanges = () => {
    if (vendor) setValues(vendor);
  };

  const saveChanges = async () => {
    if (isValid && vendor) {
      const f: DemmiFS.Vendor = {
        ...vendor,
        shop: {
          ...vendor.shop,
          openingHours: hours,
        },
      };
      demmiRequest(NetworkService.Vendors.updateVendorHours(f)).then(() =>
        dispatch(setVendor(f)),
      );
    }
  };

  return (
    <div className={`${CSSBlock}`}>
      <DaysList selectedDay={selectedDay} onClickDay={setSelectedDay} />

      <DemmiCard rootClassName={`${CSSBlock}__details`}>
        <div className={`${CSSBlock}__slots`}>
          <div className={`${CSSBlock}__slot`}>
            <div className={`${CSSBlock}__slot-input-row`}>
              <div className={`${CSSBlock}__slot-input`}>
                <div className={`${CSSBlock}__slot-input-title`}>
                  Start Time
                </div>
                <InputTime value={540} onChange={_ => {}} />
              </div>
              <div className={`${CSSBlock}__slot-input`}>
                <div className={`${CSSBlock}__slot-input-title`}>End Time</div>
                <InputTime value={600} onChange={_ => {}} />
              </div>
            </div>
            <div className={`${CSSBlock}__slot-delete-button`}>
              <DemmiIconButton faIcon="fa-trash" variant="delete" />
            </div>
          </div>

          <div className={`${CSSBlock}__slot`}>
            <div className={`${CSSBlock}__slot-input-row`}>
              <div className={`${CSSBlock}__slot-input`}>
                <div className={`${CSSBlock}__slot-input-title`}>
                  Start Time
                </div>
                <InputTime value={540} onChange={_ => {}} />
              </div>
              <div className={`${CSSBlock}__slot-input`}>
                <div className={`${CSSBlock}__slot-input-title`}>End Time</div>
                <InputTime value={600} onChange={_ => {}} />
              </div>
            </div>
            <div className={`${CSSBlock}__slot-capacity`}></div>
            <div className={`${CSSBlock}__slot-delete-button`}>
              <DemmiIconButton faIcon="fa-trash" variant="delete" />
            </div>
          </div>
        </div>
        <DemmiButton text="Add Another Slot" faIcon="fa-plus" />

        <div className={`${CSSBlock}__buttons`}>
          <span style={{ alignSelf: 'center', display: 'flex', gap: '1rem' }}>
            <DemmiButton
              text={'Discard Changes'}
              faIcon="fa-ban"
              disabled={!detectedChanges}
              onClick={() => discardChanges()}
            />
            <DemmiButton
              text={'Save Changes'}
              faIcon="fa-regular fa-floppy-disk"
              disabled={!detectedChanges || !isValid}
              onClick={() => saveChanges()}
            />
          </span>
        </div>
      </DemmiCard>
    </div>
  );
};

export default SettingsCollectionSlots;
