import { Timestamp } from 'firebase/firestore';

import { NetworkService } from '@core/services/networkService/networkService';
import { DemmiFS } from '@subhanhabib/demmilib';

export namespace DemmiSupportHelper {
  export enum DemmiSupportTypes {
    MISSING_LOCATION,
    BUG_REPORT,
    DASHBOARD_HELP,
    ORDER_REFUND_DISPUTE,
    ORDER_GENERIC,
  }

  interface NewDemmiSupport {
    vendorID: string;
    title: string;
    initialMessage: string;
    relatedOrderID?: string;
  }

  const _createNewDemmiSupport = (
    type: DemmiSupportTypes,
    vendorID: string,
    vendorName: string,
    relatedOrderID?: string,
  ): NewDemmiSupport | undefined => {
    switch (type) {
      case DemmiSupportTypes.MISSING_LOCATION:
        return {
          title: `Unable to find correct vendor address.`,
          initialMessage: `${vendorName} reported a missing location. Please verify their address details.`,
          vendorID,
        };
      case DemmiSupportTypes.BUG_REPORT:
        return {
          title: `Bug report`,
          initialMessage: `${vendorName} encountered a technical issue that needs investigation.`,
          vendorID,
        };
      case DemmiSupportTypes.DASHBOARD_HELP:
        return {
          title: `Dashboard help`,
          initialMessage: `${vendorName} needs assistance navigating or using the dashboard features.`,
          vendorID,
        };
      case DemmiSupportTypes.ORDER_REFUND_DISPUTE:
        return {
          title: `Refund dispute`,
          initialMessage: `${vendorName} is raising a dispute regarding an order refund. Please review the details.`,
          vendorID,
          relatedOrderID,
        };
      case DemmiSupportTypes.ORDER_GENERIC:
        return {
          title: `Order query`,
          initialMessage: `${vendorName} has a general inquiry related to an order. Please provide further assistance.`,
          vendorID,
          relatedOrderID,
        };
      default:
        return;
    }
  };

  export const createSupportTicket = async (
    type: DemmiSupportTypes,
    vendor: DemmiFS.Vendor,
    onCreated?: (success: boolean, chatID: string | undefined) => void,
    relatedOrderID?: string,
  ) => {
    const supportTicket = _createNewDemmiSupport(
      type,
      vendor.docID,
      vendor.name,
      relatedOrderID,
    );
    if (!supportTicket) {
      if (onCreated) onCreated(false, undefined);
      return;
    }

    const id = await NetworkService.DemmiSupport.createDemmiSupportChat({
      title: supportTicket.title,
      vendorDocID: vendor.docID,
      createdAt: Timestamp.now(),
      ...(supportTicket.relatedOrderID
        ? { relatedOrderID: supportTicket.relatedOrderID }
        : {}),
    });

    await NetworkService.DemmiSupport.sendDemmiSupportMessage(id, {
      text: supportTicket.initialMessage,
      timestamp: Timestamp.now(),
      isSenderDemmi: false,
      read: false,
    });

    if (onCreated) onCreated(true, id);
  };
}
