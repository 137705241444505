import { Unsubscribe } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from '@core/hooks/appHooks';
import { selectVendor } from '@core/store/appSlice/appSlice';
import { DemmiFS } from '@subhanhabib/demmilib';
import { NetworkService } from '@core/services/networkService/networkService';
import OrderItemTabRefundExists from './_tab-refund-exists';
import OrderItemTabRefundNone from './_tab-refund-none';
import ElementLoadingIndicator from '@components/loadingIntro/ElementLoadingIndicator';
import { demmiRequest } from '@helpers/app.helper';

interface Props {
  order: DemmiFS.Order;
}

const OrderItemTabRefund: React.FC<Props> = ({ order }) => {
  // const CSSBlock = 'order-refund-request';
  const vendor = useAppSelector(selectVendor);
  const [isLoading, setIsLoading] = useState(false);
  const [refundRequestID, setRefundRequestID] = useState<string>();
  const [refundRequest, setRefundRequest] = useState<DemmiFS.RefundRequest>();

  useEffect(() => {
    if (vendor && order) fetchRefundRequest();
  }, [vendor, order]);

  useEffect(() => {
    let snapshotListener: Unsubscribe | undefined;
    if (refundRequestID) {
      const cb = (r?: DemmiFS.RefundRequest) => {
        setRefundRequest(r);
        setIsLoading(false);
      };

      NetworkService.RefundRequests.listenToRefundRequest(
        refundRequestID,
        cb,
      ).then(u => (snapshotListener = u));
    }

    return () => {
      if (snapshotListener) snapshotListener();
    };
  }, [refundRequestID]);

  const fetchRefundRequest = () => {
    setIsLoading(true);
    demmiRequest(
      NetworkService.RefundRequests.hasRefundRequest(
        order.docID,
        vendor!.docID,
      ),
    ).then(id => {
      setRefundRequestID(id);
      setIsLoading(false);
    });
  };

  return isLoading ? (
    <ElementLoadingIndicator isLoading={true} />
  ) : refundRequest ? (
    <OrderItemTabRefundExists
      refundRequest={refundRequest}
      isLoading={isLoading}
      order={order}
    />
  ) : (
    <OrderItemTabRefundNone
      order={order}
      onCreate={docID => setRefundRequestID(docID)}
    />
  );
};

export default OrderItemTabRefund;
