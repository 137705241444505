import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import 'remixicon/fonts/remixicon.css';

import '@assets/fa-all.min.js';
import AppCore from '@core/AppCore';
import { store } from '@core/store/store';
import { FirestoreProvider } from '@providers/firestoreProvider';
import '@styles/index.scss';
import { FirestoreDemmiProvider } from '@core/contexts/FirestoreDemmiContext';

const root = createRoot(document.getElementById('demmi-dash')!);
root.render(
  <Provider store={store}>
    <FirestoreProvider>
      <FirestoreDemmiProvider>
        <AppCore />
      </FirestoreDemmiProvider>
    </FirestoreProvider>
  </Provider>,
);
