import { Unsubscribe } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '@core/hooks/appHooks';
import { routingPaths } from '@core/services/routingService';
import { selectVendor } from '@core/store/appSlice/appSlice';
import DemmiButton, { DemmiButtonSize } from '@demmi-ui/Button';
import { navigateTransitioned } from '@helpers/app.helper';
import { DemmiFS } from '@subhanhabib/demmilib';

import DashboardSection from '../DashboardSection';
import WidgetReviewItem from './WidgetReviewItem';
import { NetworkService } from '@core/services/networkService/networkService';

const WidgetReviews: React.FC = () => {
  const CSSBlock = 'widget-reviews';

  const dispatch = useAppDispatch();
  const vendor = useAppSelector(selectVendor);
  const navigate = useNavigate();

  const [isLoading, _] = useState(true);
  const [reviews, setReviews] = useState<DemmiFS.VendorReview[]>([]);

  useEffect(() => {
    const snapshotListeners: Unsubscribe[] = [];
    if (vendor) {
      const callback = (reviews: DemmiFS.VendorReview[]) => setReviews(reviews);
      NetworkService.Reviews.listenToReviews(vendor.docID, callback, 5).then(
        u => snapshotListeners.push(u),
      );
    }
    return () => {
      snapshotListeners.forEach(snapshot => snapshot());
    };
  }, [vendor]);

  return (
    <DashboardSection
      className={CSSBlock}
      title="Reviews"
      titleButton={
        <DemmiButton
          text="View All"
          size={DemmiButtonSize.S}
          onClick={() => {
            navigateTransitioned({
              dispatch,
              navigate,
              to: routingPaths.reviews,
            });
          }}
        />
      }>
      <div className={`${CSSBlock}__list`}>
        {reviews.length > 0
          ? reviews.map((review, i) => (
              <WidgetReviewItem review={review} key={i} />
            ))
          : isLoading === false && (
              <div style={{ textAlign: 'center', margin: '4rem auto' }}>
                No reviews yet
              </div>
            )}
      </div>
    </DashboardSection>
  );
};

export default WidgetReviews;
