import { Timestamp } from 'firebase/firestore';
import React, { useRef, useState } from 'react';

import { NetworkService } from '@core/services/networkService/networkService';
import DemmiButton from '@demmi-ui/Button';
import DemmiIconButton, { IconButtonSize } from '@demmi-ui/IconButton';
import DemmiCardButtonArea from '@demmi-ui/Modal/ModalButtonArea';
import DemmiModalCore, { DemmiModalCoreRef } from '@demmi-ui/Modal/ModalCore';
import { demmiRequest } from '@helpers/app.helper';
import { DemmiFS } from '@subhanhabib/demmilib';

import ModalCreateDemmiSupportTicketContent from './ModalCreateDemmiSupportTicketContent';

interface Props {
  vendorDocID: string;
  onClose: () => void;
  onConfirm: (docID: string) => void;
}

const ModalCreateDemmiSupportTicket: React.FC<Props> = ({
  vendorDocID,
  onClose,
  onConfirm,
}) => {
  const CSSBlock = 'modal-create-demmi-support-ticket';
  const modalRef = useRef<DemmiModalCoreRef>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [isDoingRequest, setIsDoingRequest] = useState(false);

  /**
   * 0 = Doing nothing
   * 1 = Creating ticket
   * -1 = Failed to create
   */
  const [state, setState] = useState(0);
  const [isValid, setIsValid] = useState(false);

  const gracefulExit = (postExit: () => void) => {
    modalRef.current ? modalRef.current.gracefulClose(postExit) : postExit();
    setTimeout(() => {
      setIsOpen(false);
      setState(0);
    }, 500);
  };

  const createChat = async () => {
    const chat: DemmiFS.FSDemmiSupportVendor = {
      title,
      vendorDocID: vendorDocID,
      createdAt: Timestamp.now(),
    };

    const firstMessage: DemmiFS.FSDemmiSupportMessage = {
      text: message,
      timestamp: Timestamp.now(),
      isSenderDemmi: false,
      read: false,
    };

    setIsDoingRequest(true);
    await demmiRequest(NetworkService.DemmiSupport.createDemmiSupportChat(chat))
      .then(docID => {
        demmiRequest(
          NetworkService.DemmiSupport.sendDemmiSupportMessage(
            docID,
            firstMessage,
          ),
        ).then(_ => {
          gracefulExit(() => onConfirm(docID));
        });
      })
      .catch(() => setState(-1));
    setIsDoingRequest(false);
  };

  const buttons = [
    <DemmiButton
      key={0}
      text="Discard"
      outlined
      onClick={() => gracefulExit(onClose)}
    />,
    <DemmiButton
      key={1}
      text="Create Ticket"
      disabled={!isValid}
      onClick={() => createChat()}
    />,
  ];

  return (
    <>
      <DemmiIconButton
        faIcon="fa-plus"
        onClick={() => setIsOpen(true)}
        size={IconButtonSize.SMALL}
      />
      <DemmiModalCore
        ref={modalRef}
        isOpen={isOpen}
        onExited={() => {
          setIsOpen(false);
          onClose();
        }}
        contentClassName={CSSBlock}
        allowBackdropClose={false}>
        <ModalCreateDemmiSupportTicketContent
          state={state}
          CSSBlock={CSSBlock}
          title={title}
          setTitle={setTitle}
          message={message}
          setMessage={setMessage}
          setIsValid={setIsValid}
          isDoingRequest={isDoingRequest}
        />
        <DemmiCardButtonArea
          buttons={buttons}
          isDoingRequest={isDoingRequest}
        />
      </DemmiModalCore>
    </>
  );
};

export default ModalCreateDemmiSupportTicket;
