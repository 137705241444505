import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import PageWithSidebar from '@components/page/PageWithSidebar';
import { RoutingViews, getViewFromPath } from '@core/services/routingService';
import { getAppDate, getAppVersion, isProd } from '@helpers/app.helper';
import { DemmiDictionary } from '@subhanhabib/demmilib';

export const CSSBLOCK_SETTINGS = 'vendor-settings';

const Settings: React.FC = () => {
  const [activePage, setActivePage] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  const vendorDictionary = DemmiDictionary.getAllDictionaryValues(
    DemmiDictionary.Vendor,
    'farmer',
  );

  const getIndex = (view?: RoutingViews) => {
    if (!view) return 0;

    switch (view) {
      case RoutingViews.SETTINGS_IMAGES:
        return 1;
      case RoutingViews.SETTINGS_LOCATION:
        return 2;
      case RoutingViews.SETTINGS_OPENING_HOURS:
        return 3;
      case RoutingViews.SETTINGS_THEME:
        return 4;
      case RoutingViews.SETTINGS_COLLECTION_SLOTS:
        return 5;
      case RoutingViews.SETTINGS_DELIVERY_SLOTS:
        return 6;
      default:
        return 0;
    }
  };

  useEffect(() => {
    const view = getViewFromPath(location.pathname);
    setActivePage(getIndex(view));
  }, [location]);

  const version = () => (
    <div className={`${CSSBLOCK_SETTINGS}__version`}>
      <div className={`${CSSBLOCK_SETTINGS}__version-build`}>
        <span className={`${CSSBLOCK_SETTINGS}__version-key`}>
          Build version
        </span>
        <span className={`${CSSBLOCK_SETTINGS}__version-val`}>
          {getAppVersion()}
        </span>
      </div>
      <div className={`${CSSBLOCK_SETTINGS}__version-date`}>
        <span className={`${CSSBLOCK_SETTINGS}__version-key`}>Build date</span>
        <span className={`${CSSBLOCK_SETTINGS}__version-val`}>
          {getAppDate()}
        </span>
      </div>
    </div>
  );

  const sidebarButtons = [
    {
      id: RoutingViews.SETTINGS_DETAILS,
      text: 'Details',
      faIcon: 'fa-address-card',
    },
    {
      id: RoutingViews.SETTINGS_IMAGES,
      text: 'Images',
      faIcon: 'fa-image',
    },
    {
      id: RoutingViews.SETTINGS_LOCATION,
      text: `${vendorDictionary.labelShort} Location`,
      faIcon: 'fa-location-dot',
    },
    {
      id: RoutingViews.SETTINGS_OPENING_HOURS,
      text: 'Opening Hours',
      faIcon: 'fa-regular fa-clock',
    },
    {
      id: RoutingViews.SETTINGS_THEME,
      text: 'Theme',
      faIcon: 'fa-palette',
    },
    ...(isProd()
      ? []
      : [
          {
            id: RoutingViews.SETTINGS_COLLECTION_SLOTS,
            text: 'Collection Time Slots',
            faIcon: 'fa-solid fa-user-clock',
          },
          {
            id: RoutingViews.SETTINGS_DELIVERY_SLOTS,
            text: 'Delivery Slots',
            faIcon: 'fa-solid fa-truck',
          },
        ]),
  ];

  const sidebarOnClick = (id: string) => {
    navigate(id, { replace: true });
  };

  return (
    <PageWithSidebar
      sidebar={{
        buttons: sidebarButtons,
        active: activePage,
        title: 'Farm Management',
        onClick: sidebarOnClick,
        searchPlaceholder: 'Your Support Chats',
      }}
      title="Settings">
      <div className={`${CSSBLOCK_SETTINGS}`}>
        <Outlet />
      </div>
    </PageWithSidebar>
  );
};

export default Settings;
