import React, { useEffect, useRef, useState } from 'react';

import { useAppSelector } from '@core/hooks/appHooks';
import { uploadProductImage } from '@core/services/networkService/functions/_storage';
import { NetworkService } from '@core/services/networkService/networkService';
import { selectVendor } from '@core/store/appSlice/appSlice';
import DemmiButton from '@demmi-ui/Button';
import DemmiIconButton, { IconButtonSize } from '@demmi-ui/IconButton';
import DemmiCardButtonArea from '@demmi-ui/Modal/ModalButtonArea';
import DemmiModalCore, { DemmiModalCoreRef } from '@demmi-ui/Modal/ModalCore';
import { demmiRequest } from '@helpers/app.helper';
import { ProductsHelper } from '@helpers/products.helper';
import { DemmiFS } from '@subhanhabib/demmilib';

import ModalCreateProductContent from './ModalCreateProductContent';

interface Props {
  onClose: () => void;
  onConfirm: (id: string) => void;
}

const ModalCreateProduct: React.FC<Props> = ({ onClose, onConfirm }) => {
  const CSSBlock = 'modal-create-product';
  const modalRef = useRef<DemmiModalCoreRef>(null);
  const [isOpen, setIsOpen] = useState(false);
  const vendor = useAppSelector(selectVendor);
  const [product, setProduct] = useState<DemmiFS.Product>();
  const [selectedImage, setSelectedImage] = useState<File | undefined>();

  /**
   * 0 = Doing nothing
   * 1 = Creating product
   * 2 = Uploading image
   * -1 = Failed to create
   * -2 = Failed to upload image
   */
  const [_, setState] = useState(0);

  useEffect(() => {
    if (product) setIsValid(ProductsHelper.isProductDetailsValid(product!));
    else setIsValid(false);
  }, [product]);

  const [isValid, setIsValid] = useState(false);

  const gracefulExit = (postExit: () => void) => {
    modalRef.current ? modalRef.current.gracefulClose(postExit) : postExit();
    setTimeout(() => {
      setIsOpen(false);
    }, 500);
  };

  const pushProduct = async () => {
    if (isValid && selectedImage && product) {
      const { docID: _, ...newProduct } = product;
      demmiRequest(NetworkService.Product.createProduct(newProduct))
        .then(newID => pushImageToProduct(newID))
        .catch(() => setState(-1));
    }
  };

  const pushImageToProduct = (productID: string) => {
    if (vendor && selectedImage) {
      const extension = selectedImage.name.split(/\.(?=[^.]+$)/);
      const filename = `${vendor.docID}-${productID}-${crypto.randomUUID()}.${
        extension[extension.length - 1]
      }`;

      demmiRequest(uploadProductImage(vendor.docID, selectedImage, filename))
        .then(name => {
          if (name) {
            demmiRequest(
              NetworkService.Product.updateProductImages(productID, [name]),
            ).then(() => {
              gracefulExit(() => onConfirm(productID));
            });
          } else {
            // TODO: Add toast.
            gracefulExit(() => onConfirm(productID));
          }
        })
        .catch(() => setState(-2));
    }
  };

  const buttons = [
    <DemmiButton
      key={0}
      text="Discard"
      outlined
      onClick={() => gracefulExit(onClose)}
    />,
    <DemmiButton
      key={1}
      text="Create Product"
      disabled={!isValid || !selectedImage}
      onClick={() => pushProduct()}
    />,
  ];

  return (
    <>
      <DemmiIconButton
        faIcon="fa-plus"
        onClick={() => setIsOpen(true)}
        size={IconButtonSize.SMALL}
      />
      <DemmiModalCore
        ref={modalRef}
        isOpen={isOpen}
        onExited={() => {
          setIsOpen(false);
          onClose();
        }}
        contentClassName={CSSBlock}
        allowBackdropClose={false}>
        <ModalCreateProductContent
          CSSBlock={CSSBlock}
          setProduct={setProduct}
          selectedImage={selectedImage}
          setSelectedImage={setSelectedImage}
        />
        <DemmiCardButtonArea buttons={buttons} />
      </DemmiModalCore>
    </>
  );
};

export default ModalCreateProduct;
