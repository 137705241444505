import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { getDownloadURL, ref } from 'firebase/storage';

import { FSCollections } from '@providers/firestoreProvider';
import {
  DemmiFS,
  DemmiHelpers,
  DemmiLogType,
  Logger,
} from '@subhanhabib/demmilib';

import { db, storage } from '../../../../firebase';
import { FSCollectionNames, StoragePaths } from '../networkService.helper';

export class FSVendors {
  static _getVendorUser = async (
    vendorerID: string,
  ): Promise<DemmiFS.VendorUser | undefined> => {
    Logger({ objs: { vendorerID } }, this._getVendorUser);
    const snap = await getDoc(
      doc(db, FSCollectionNames.VENDOR_USERS, vendorerID),
    );
    if (snap.exists()) {
      return {
        docID: snap.id,
        ...(snap.data() as DemmiFS.FSVendorUser),
      };
    } else {
      Logger(
        { messages: ['No such document!'], type: DemmiLogType.error },
        this._getVendorUser,
      );
    }
    return undefined;
  };

  static _getVendor = async (
    vendorID: string,
  ): Promise<DemmiFS.Vendor | undefined> => {
    Logger({ objs: { vendorID } }, this._getVendor);
    const snap = await getDoc(doc(db, FSCollectionNames.VENDORS, vendorID));

    if (snap.exists()) {
      const newVendor: DemmiFS.FSVendor = snap.data() as DemmiFS.FSVendor;
      if (!newVendor.shop?.openingHours) {
        newVendor.shop = {
          openingHours: DemmiHelpers.Vendor.EmptyOpeningHours,
        };
      }
      return {
        docID: snap.id,
        ...newVendor,
      };
    } else {
      Logger(
        { messages: ['No such document!'], type: DemmiLogType.error },
        this._getVendor,
      );
    }
    return undefined;
  };

  static _updateVendorDetails = async (
    vendor: DemmiFS.Vendor,
  ): Promise<void> => {
    Logger({ objs: { vendor } }, this._updateVendorDetails);
    const docRef = doc(FSCollections.Vendors, vendor.docID);
    return updateDoc(docRef, {
      name: vendor.name,
      contactNumber: vendor.contactNumber,
      shortDescription: vendor.shortDescription,
      longDescription: vendor.longDescription,
    });
  };

  static _updateVendorTheme = async (vendor: DemmiFS.Vendor): Promise<void> => {
    Logger({ objs: { vendor } }, this._updateVendorTheme);
    const docRef = doc(FSCollections.Vendors, vendor.docID);
    return updateDoc(docRef, {
      themeHue: vendor.themeHue,
    });
  };

  static _updateVendorHours = async (vendor: DemmiFS.Vendor): Promise<void> => {
    Logger({ objs: { vendor } }, this._updateVendorHours);
    const docRef = doc(db, FSCollectionNames.VENDORS, vendor.docID);
    return updateDoc(docRef, {
      ['shop.openingHours']: vendor.shop.openingHours,
    });
  };

  static _updateVendorAddress = async (
    vendorID: string,
    address: DemmiFS.VendorAddress,
  ): Promise<void> => {
    Logger({ objs: { vendorID, address } }, this._updateVendorAddress);
    const docRef = doc(FSCollections.Vendors, vendorID);
    return updateDoc(docRef, {
      address: {
        geo: address.geo,
        countryCode: address.countryCode,
        address: address.address,
      },
    });
  };

  static _checkVendorImageExists = async (
    vendorDocID: string,
    imageURL: string,
  ) => {
    const pathReference = ref(
      storage,
      `/${vendorDocID}/${StoragePaths.VENDOR_IMAGES}/${imageURL}`,
    );
    return getDownloadURL(pathReference)
      .then(_ => true)
      .catch(_ => false);
  };

  static _updateVendorImages = async (
    id: string,
    images: DemmiFS.DemmiImageResource[],
  ): Promise<void> => {
    Logger({ objs: { id, images } }, this._updateVendorImages);
    const docRef = doc(FSCollections.Vendors, id);
    return updateDoc(docRef, {
      images,
    });
  };
}
