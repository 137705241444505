import {
  DocumentData,
  QueryDocumentSnapshot,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';

import { FSCollections } from '@providers/firestoreProvider';
import { DemmiFS, DemmiLogType, Logger } from '@subhanhabib/demmilib';

import { db } from '../../../../firebase';
import { FSCollectionNames } from '../networkService.helper';

export class FSCoupons {
  static _createCoupon = async (coupon: DemmiFS.Coupon): Promise<boolean> => {
    Logger({ objs: { coupon } }, this._createCoupon);
    const { docID, ...couponDetails } = coupon;
    const isAvailable = await this._checkCodeAvailability(docID);
    if (isAvailable) {
      const docRef = await setDoc(doc(db, FSCollectionNames.COUPONS, docID), {
        ...couponDetails,
        type: DemmiFS.CouponHelper.getType(couponDetails.type),
      });
      Logger(
        { messages: ['New Coupon ID'], objs: { docRef } },
        this._createCoupon,
      );
      return true;
    } else {
      Logger(
        {
          messages: ['Coupon code already exists!'],
          objs: { coupon },
          type: DemmiLogType.error,
        },
        this._createCoupon,
      );
      return false;
    }
  };

  static _checkCodeAvailability = async (
    couponCode: string,
  ): Promise<boolean> => {
    Logger({ objs: { couponCode } }, this._checkCodeAvailability);
    const docSnap = await getDoc(
      doc(db, FSCollectionNames.COUPONS, couponCode),
    );
    return !docSnap.exists();
  };

  static _getCoupons = async (vendorID: string): Promise<DemmiFS.Coupon[]> => {
    Logger({ objs: { vendorID } }, this._getCoupons);
    const q = query(FSCollections.Coupons, where('vendorID', '==', vendorID));
    const querySnapshot = await getDocs(q);
    const coupons: DemmiFS.Coupon[] = [];
    querySnapshot.forEach((doc: QueryDocumentSnapshot<DocumentData>) => {
      const c: DemmiFS.FSCoupon = doc.data() as DemmiFS.FSCoupon;
      c.type = DemmiFS.CouponHelper.getType(c.type);
      coupons.push({
        docID: doc.id,
        ...c,
      });
    });
    return coupons;
  };

  static _deleteCoupon = async (
    couponCode: string,
    vendorID: string,
  ): Promise<void> => {
    Logger({ objs: { couponCode, vendorID } }, this._deleteCoupon);
    const docSnap = await getDoc(
      doc(db, FSCollectionNames.COUPONS, couponCode),
    );
    if (docSnap.exists() && docSnap.data()['vendorID'] === vendorID) {
      const docRef = doc(FSCollections.Coupons, couponCode);
      return deleteDoc(docRef);
    }
  };

  static _updateCouponDates = async (coupon: DemmiFS.Coupon): Promise<void> => {
    Logger({ objs: { coupon } }, this._updateCouponDates);
    const docRef = doc(db, FSCollectionNames.COUPONS, coupon.docID);
    return updateDoc(docRef, {
      startDate: coupon.startDate ? coupon.startDate : null,
      expirationDate: coupon.expirationDate ? coupon.expirationDate : null,
    });
  };
}
