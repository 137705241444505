import { DateTime } from 'luxon';
import React from 'react';

import { faStar } from '@fortawesome/pro-light-svg-icons';
import { faStar as faStarSolid } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DemmiFS } from '@subhanhabib/demmilib';

export interface Props {
  CSSBlock: string;
  review: DemmiFS.VendorReview;
  active?: boolean;
  onClick?: (e: React.MouseEvent) => void;
}

const ReviewsSidebarButton: React.FC<Props> = ({
  CSSBlock: parentCSS,
  review,
  active,
  onClick,
}) => {
  const CSSBlock = `${parentCSS}-sidebar-button`;

  return (
    <div
      className={`${CSSBlock}  ${active ? `${CSSBlock}--active` : ``}`}
      onClick={onClick}>
      <div className={`${CSSBlock}__top`}>
        {review.customer && (
          <div className={`${CSSBlock}__customer-name`}>
            {review.customer.title} {review.customer.firstName}{' '}
            {review.customer.lastName.substring(0, 1)}
          </div>
        )}
        <div className={`${CSSBlock}__rating`}>
          {[...Array(5)].map((_, i) => (
            <FontAwesomeIcon
              key={i}
              icon={review.rating < i ? faStar : faStarSolid}
            />
          ))}
        </div>
      </div>
      <div className={`${CSSBlock}__content`}>
        <div className={`${CSSBlock}__title`}>{review.title}</div>
        <div className={`${CSSBlock}__body`}>{review.body}</div>
      </div>
      <div className={`${CSSBlock}__date`}>
        Reviewed on:{' '}
        {review.timestamp
          ? DateTime.fromJSDate(
              DemmiFS.getDateFromFirestore(review.timestamp)!,
            ).toLocaleString()
          : '-'}
      </div>
    </div>
  );
};

export default ReviewsSidebarButton;
