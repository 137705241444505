import { Logger } from '@subhanhabib/demmilib';

export const Cloud_Function_Base_URL_DEV =
  'https://us-central1-demmi-dev.cloudfunctions.net';
export const Cloud_Function_Base_URL_PROD =
  'https://us-central1-demmi-prod.cloudfunctions.net';

const _CF_HTTP = 'http-vendorsPortal-';

export enum CloudFunctions {
  // Images
  DELETE_PRODUCT_IMAGE = `${_CF_HTTP}deleteProductImage`,
  DELETE_VENDOR_IMAGE = `${_CF_HTTP}deleteVendorImage`,
  // Vendor Collection Schedule
  CREATE_VENDOR_COLLECTION_SCHEDULE = `${_CF_HTTP}createVendorCollectionSchedule`,
  DELETE_VENDOR_COLLECTION_SCHEDULE = `${_CF_HTTP}deleteVendorCollectionSchedule`,
  UPDATE_VENDOR_COLLECTION_SCHEDULE = `${_CF_HTTP}updateVendorCollectionSchedule`,
  // Vendor
  UPDATE_VENDOR_OPENING_HOURS = `${_CF_HTTP}updateVendorOpeningHours`,
}

const getCloudFunctionBaseURL = () =>
  process.env.IS_PRODUCTION
    ? Cloud_Function_Base_URL_PROD
    : Cloud_Function_Base_URL_DEV;

export const getCloudFunctionURL = (func: CloudFunctions) => {
  Logger(
    { objs: { url: `${getCloudFunctionBaseURL()}/${func}` } },
    getCloudFunctionURL,
  );
  return `${getCloudFunctionBaseURL()}/${func}`;
};
