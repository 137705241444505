import React, { useEffect, useState } from 'react';

import ModalCreateVariant from '@components/modals/CreateVariant/ModalCreateVariant';
import { demmiRequest } from '@helpers/app.helper';
import { DemmiFS } from '@subhanhabib/demmilib';

import AddProductVariant from './ProductVariant';
import { NetworkService } from '@core/services/networkService/networkService';

interface Props {
  product: DemmiFS.Product;
}

const ProductVariants: React.FC<Props> = ({ product }) => {
  const CSSBlock = 'product-variants';
  const [variants, setVariants] = useState<DemmiFS.ProductVariant[]>();

  useEffect(() => {
    if (product)
      demmiRequest(
        NetworkService.ProductVariants.getProductVariants(product.docID),
      ).then(variants => {
        setVariants(variants);
      });
    else setVariants(undefined);
  }, [product]);

  const onClickDeleteVariant = (id: string) => {
    demmiRequest(
      NetworkService.ProductVariants.deleteProductVariant(product.docID, id),
    ).then(() =>
      demmiRequest(
        NetworkService.ProductVariants.getProductVariants(product.docID),
      ).then(variants => {
        setVariants(variants);
      }),
    );
  };

  const onUpdateVariant = (variant: DemmiFS.ProductVariant, i: number) =>
    variants &&
    setVariants([...variants.slice(0, i), variant, ...variants.slice(i + 1)]);

  const onCreateNewVariant = () => {
    demmiRequest(
      NetworkService.ProductVariants.getProductVariants(product.docID),
    ).then(variants => {
      setVariants(variants);
    });
  };

  return (
    <div className={`${CSSBlock}`}>
      <div className={`${CSSBlock}__title`}>Variants</div>
      {variants ? (
        <>
          {variants.map((variant, i) => (
            <AddProductVariant
              variant={variant}
              key={i}
              onClickDelete={() => onClickDeleteVariant(variant.docID)}
              onUpdate={value => onUpdateVariant(value, i)}
            />
          ))}
          <ModalCreateVariant
            product={product}
            onClose={() => {}}
            onConfirm={() => onCreateNewVariant()}
          />
        </>
      ) : (
        <div>No product selected</div>
      )}
    </div>
  );
};

export default ProductVariants;
