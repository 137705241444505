import { doc, getDoc } from 'firebase/firestore';

import { DemmiFS } from '@subhanhabib/demmilib';

import { FSCollections } from '@providers/firestoreProvider';

export class FSDemmi {
  static _populate = async (): Promise<DemmiFS.DemmiConstants | undefined> => {
    const contents = await this._getFSDemmiConstants();
    if (contents) return contents;
    else return undefined;
  };

  static _getFSDemmiConstants = async (): Promise<
    DemmiFS.FSDemmiConstants | undefined
  > => {
    return getDoc(doc(FSCollections.Demmi, 'constants')).then(doc => {
      if (doc.exists()) {
        const firestoreDemmi: DemmiFS.FSDemmiConstants =
          doc.data() as DemmiFS.FSDemmiConstants;

        firestoreDemmi.categories = firestoreDemmi.categories.map(parent => ({
          ...parent,
          icon: DemmiFS.CategoriesHelper.getIconFromOnlyCategoryID(parent.id),
          children: parent.children.map(child => ({
            ...child,
            icon: DemmiFS.CategoriesHelper.getIconFromOnlyCategoryID(child.id),
          })),
        }));
        return firestoreDemmi;
      }
      return undefined;
    });
  };
}
