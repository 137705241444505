import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import PageWithSidebar from '@components/page/PageWithSidebar';
import { useAppSelector } from '@core/hooks/appHooks';
import { NetworkService } from '@core/services/networkService/networkService';
import { SearchParams } from '@core/services/routingService';
import { selectVendor } from '@core/store/appSlice/appSlice';
import { demmiRequest } from '@helpers/app.helper';
import { DemmiFS } from '@subhanhabib/demmilib';

import ReviewsSidebarButton from './_sidebarButton';
import ReviewItem from './reviewItem/ReviewItem';

const Reviews: React.FC = () => {
  const CSSBlock = 'reviews';
  const [searchParams, setSearchParams] = useSearchParams();
  const [reviews, setReviews] = useState<DemmiFS.VendorReview[]>([]);
  const vendor = useAppSelector(selectVendor);
  const [selectedReview, setSelectedReview] = useState<DemmiFS.VendorReview>();
  const [filteredReviews, setFilteredReviews] = useState<
    DemmiFS.VendorReview[]
  >([]);

  useEffect(() => {
    if (vendor)
      demmiRequest(NetworkService.Reviews.getAllReviews(vendor!.docID)).then(
        o => {
          setReviews(o);
          if (searchParams.get(SearchParams.REVIEW_ID)) {
            setSelectedReview(
              o.find(c => c.docID === searchParams.get(SearchParams.REVIEW_ID)),
            );
          } else {
            setSelectedReview(undefined);
          }
        },
      );
  }, [vendor]);

  useEffect(() => {
    filterReviews();
  }, [reviews]);

  const onClickRefresh = () => {
    if (vendor)
      demmiRequest(NetworkService.Reviews.getAllReviews(vendor!.docID)).then(
        o => setReviews(o),
      );
  };

  const onUpdateReviewResponse = (
    reviewID: string,
    response: DemmiFS.FSVendorReviewResponse,
  ) => {
    const i = reviews.findIndex(r => r.docID === reviewID);
    if (i > -1) {
      setReviews([
        ...reviews.slice(0, i),
        { ...reviews[i], response },
        ...reviews.slice(i + 1),
      ]);
    }
  };

  const onClickReview = (review: DemmiFS.VendorReview) => {
    setSearchParams({ [SearchParams.REVIEW_ID]: review.docID });
    setSelectedReview(review);
  };

  const filterReviews = (filter?: string) => {
    const organised = reviews.reduce(
      (acc: DemmiFS.VendorReview[], cur: DemmiFS.VendorReview) => {
        if (filter && !cur.title.toLowerCase().includes(filter.toLowerCase()))
          return acc;
        else return [...acc, cur];
      },
      [],
    );
    setFilteredReviews(organised);
  };

  const sidebarButtons = () =>
    filteredReviews.map((review, i) => (
      <ReviewsSidebarButton
        key={i}
        review={review}
        active={selectedReview?.docID === review.docID}
        CSSBlock={CSSBlock}
      />
    ));

  const sidebarTitleButtons = [
    { faIcon: 'fa-rotate-right', onClick: onClickRefresh },
  ];

  return (
    <PageWithSidebar
      sidebar={{
        title: 'Reviews',
        titleButtons: sidebarTitleButtons,
        customButtons: sidebarButtons(),
        active: reviews.findIndex(f => selectedReview?.docID === f.docID),
        onClick: (i: number) => onClickReview(filteredReviews[i]),
        searchPlaceholder: 'Filter reviews by title',
        onSearch: filterReviews,
      }}
      title="Reviews">
      <div className={`${CSSBlock}`}>
        {selectedReview && vendor && (
          <ReviewItem
            review={selectedReview}
            vendor={vendor}
            onUpdateReviewResponse={onUpdateReviewResponse}
          />
        )}
      </div>
    </PageWithSidebar>
  );
};

export default Reviews;
