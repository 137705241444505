import { Unsubscribe, doc, onSnapshot } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';

import { FSCollections } from '@providers/firestoreProvider';
import { DemmiFS, DemmiLogType, Logger } from '@subhanhabib/demmilib';

import { functions, storage } from '../../../../firebase';
import { CloudFunctions } from '../cloudFunctions';

const StorageImageCache: { [src: string]: string } = {};

const STORAGE_PATH_PRODUCT_IMAGES = '/product_images';
const STORAGE_PATH_VENDOR_IMAGES = '/vendor_images';

export const getProductImage = (
  vendorDocID: string,
  src: string,
): Promise<string> => {
  return getCachedImage(`/${vendorDocID}${STORAGE_PATH_PRODUCT_IMAGES}/${src}`);
};

export const getVendorImage = (
  vendorDocID: string,
  src: string,
): Promise<string> => {
  return getCachedImage(`/${vendorDocID}${STORAGE_PATH_VENDOR_IMAGES}/${src}`);
};

const getCachedImage = (src: string): Promise<string> => {
  if (StorageImageCache[src]) return Promise.resolve(StorageImageCache[src]);
  return getImage(src).then(url => {
    StorageImageCache[src] = url;
    return url;
  });
};

export const getImage = (src: string): Promise<string> => {
  const pathReference = ref(storage, src);

  return new Promise((resolve, reject) => {
    getDownloadURL(pathReference)
      .then(url => {
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = _ => {};
        xhr.open('GET', url);
        resolve(url);
      })
      .catch(error => {
        switch (error.code) {
          case 'storage/object-not-found':
            // File doesn't exist
            break;
          case 'storage/unauthorized':
            // User doesn't have permission to access the object
            break;
          case 'storage/canceled':
            // User canceled the upload
            break;
          // ...
          case 'storage/unknown':
            // Unknown error occurred, inspect the server response
            break;
          default:
            break;
        }
        reject();
      });
  });
};

export const uploadProductImage = (
  vendorDocID: string,
  file: File,
  name: string,
): Promise<void | DemmiFS.DemmiImageResource> => {
  const storageRef = ref(
    storage,
    `/${vendorDocID}${STORAGE_PATH_PRODUCT_IMAGES}/${name}`,
  );
  Logger(
    {
      objs: {
        vendorDocID,
        file,
        name,
        storageRef,
        url: `/${vendorDocID}${STORAGE_PATH_PRODUCT_IMAGES}/${name}`,
      },
    },
    uploadProductImage,
  );

  return uploadBytes(storageRef, file, {
    contentType: file.type,
  })
    .then(snapshot => {
      Logger(
        {
          messages: ['Uploaded a blob or file!'],
          objs: {
            snapshot,
            resourceNames: DemmiFS.ImageHelper.getResourceNames(
              snapshot.metadata.name,
            ),
          },
        },
        uploadProductImage,
      );
      return DemmiFS.ImageHelper.getResourceNames(snapshot.metadata.name);
    })
    .catch(e => {
      Logger({ objs: { e }, type: DemmiLogType.error }, uploadProductImage);
    });
};

export const uploadVendorImage = (
  vendorDocID: string,
  file: File,
  name: string,
): Promise<void | DemmiFS.DemmiImageResource> => {
  const storageRef = ref(
    storage,
    `/${vendorDocID}${STORAGE_PATH_VENDOR_IMAGES}/${name}`,
  );

  return uploadBytes(storageRef, file)
    .then(snapshot => {
      Logger(
        {
          messages: ['Uploaded a blob or file!'],
          objs: {
            snapshot,
            resourceNames: DemmiFS.ImageHelper.getResourceNames(
              snapshot.metadata.name,
            ),
          },
        },
        uploadVendorImage,
      );
      return DemmiFS.ImageHelper.getResourceNames(snapshot.metadata.name);
    })
    .catch(e => {
      Logger({ objs: { e }, type: DemmiLogType.error }, uploadVendorImage);
    });
};

export const deleteProductImage = (
  productID: string,
  imageIndex: number,
): Promise<boolean> => {
  Logger({ objs: { productID, imageIndex } }, deleteProductImage);

  return httpsCallable(
    functions,
    CloudFunctions.DELETE_PRODUCT_IMAGE,
  )({ productID, imageIndex })
    .then(result => {
      Logger({ objs: result }, deleteProductImage);
      return true;
    })
    .catch(error => {
      Logger({ objs: { error }, type: DemmiLogType.error }, deleteProductImage);
      return false;
    });
};

export const deleteVendorImage = (
  vendorID: string,
  imageIndex: number,
): Promise<boolean> => {
  Logger({ objs: { vendorID, imageIndex } }, deleteVendorImage);

  return httpsCallable(
    functions,
    CloudFunctions.DELETE_VENDOR_IMAGE,
  )({ vendorID, imageIndex })
    .then(result => {
      Logger({ objs: result }, deleteVendorImage);
      return true;
    })
    .catch(error => {
      Logger({ objs: { error }, type: DemmiLogType.error }, deleteVendorImage);
      return false;
    });
};

export const listenToImageResizing = async (
  vendorID: string,
  filename: string,
  callback: (success: boolean) => void,
): Promise<Unsubscribe> => {
  Logger({ objs: { vendorID, filename } }, listenToImageResizing);
  return onSnapshot(
    doc(FSCollections.LogsImageResizing(), filename),
    docSnapshot => {
      Logger(
        { objs: { exists: docSnapshot.exists(), data: docSnapshot.data() } },
        listenToImageResizing,
      );
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        callback(data.status === 'deleted');
      }
    },
  );
};
