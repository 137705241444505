import { limit, orderBy, query } from 'firebase/firestore';

import { FSCollections } from '@providers/firestoreProvider';

import { FSSubCollectionNames } from '../networkService.helper';

export const vendorReviewsQuery = (vendorID: string) =>
  query(
    FSCollections.OrderReviews([vendorID, FSSubCollectionNames.REVIEWS]),
    orderBy('timestamp', 'desc'),
  );

export const vendorReviewsQueryLimited = (vendorID: string, count: number) =>
  query(
    FSCollections.OrderReviews([vendorID, FSSubCollectionNames.REVIEWS]),
    orderBy('timestamp', 'desc'),
    limit(count),
  );
