import React, { useEffect, useState } from 'react';

import DemmiCard from '@demmi-ui/Card/Card';
import Menu from '@demmi-ui/Menu/Menu';
import MenuTriggerButton from '@demmi-ui/Menu/MenuTriggerButton';

import { getMenuContent } from '@helpers/categories.helper';
import { DemmiFS } from '@subhanhabib/demmilib';
import { useFirestoreDemmi } from '@core/contexts/FirestoreDemmiContext';

interface Props {
  categoryID?: string;
  setCategoryID: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const ProductCategory: React.FC<Props> = ({ categoryID, setCategoryID }) => {
  const CSSBlock = 'product-category';
  const firestoreDemmi = useFirestoreDemmi();
  const [primaryCategory, setPrimaryCategory] =
    useState<DemmiFS.CategoryParent>();
  const [secondaryCategory, setSecondaryCategory] =
    useState<DemmiFS.Category>();
  const [categories, setCategories] = useState<DemmiFS.CategoryParent[]>();

  useEffect(() => {
    if (firestoreDemmi) {
      const cat = firestoreDemmi.categories.find(c => {
        // if selected category is "Other" within parent category
        if (c.id === categoryID) {
          setSecondaryCategory(
            DemmiFS.CategoriesHelper.getCategoryOther(c.name),
          );
          return true;
        }

        // if selected child category is specified
        return c.children.find((x: DemmiFS.Category) => {
          if (x.id === categoryID) {
            setSecondaryCategory(x);
            return true;
          }
        });
      });
      setPrimaryCategory(cat);
      setCategories(firestoreDemmi.categories);
    }
  }, [firestoreDemmi, categoryID]);

  return (
    <DemmiCard title="Category" className={CSSBlock}>
      <div className={`${CSSBlock}__list`}>
        <Menu<DemmiFS.CategoryParent>
          content={getMenuContent<DemmiFS.CategoryParent>(categories ?? [])}
          triggerEl={
            <MenuTriggerButton
              icon={primaryCategory?.icon}
              text={primaryCategory?.name || '-'}
            />
          }
          onClick={cat => {
            setPrimaryCategory(cat);
          }}
        />
        <Menu<DemmiFS.Category>
          content={getMenuContent<DemmiFS.Category>(
            primaryCategory?.children ?? [],
            [
              DemmiFS.CategoriesHelper.getCategoryOther(
                primaryCategory?.name ?? '',
              ),
            ],
          )}
          triggerEl={
            <MenuTriggerButton
              icon={secondaryCategory?.icon}
              text={secondaryCategory?.name || '-'}
            />
          }
          onClick={cat => {
            setSecondaryCategory(cat);
            cat.id === DemmiFS.CategoriesHelper.CATEGORY_ID_OTHER
              ? setCategoryID(primaryCategory!.id)
              : setCategoryID(cat.id);
          }}
        />
      </div>
    </DemmiCard>
  );
};

export default ProductCategory;
