import React, { PropsWithChildren, useEffect, useState } from 'react';

import Sidebar from '@components/sidebar/Sidebar';
import { SidebarProps } from '@components/sidebar/_helper';
import DemmiIconButton, { IconButtonSize } from '@demmi-ui/IconButton';
import * as faLight from '@fortawesome/pro-light-svg-icons';

export interface PageProps extends PropsWithChildren {
  sidebar: SidebarProps;
  title?: string;
}

const PageWithSidebar: React.FC<PageProps> = ({ sidebar, title, children }) => {
  const CSSBlock = 'page';
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(sidebar.active === undefined || sidebar.active < 0);
  }, [sidebar.active]);

  return (
    <div
      className={`${CSSBlock} ${CSSBlock}--with-sidebar ${visible ? `${CSSBlock}--visible` : ''}`}>
      <div className={`${CSSBlock}__sidebar`}>
        <Sidebar {...sidebar} onClickParent={() => setVisible(!visible)} />
      </div>
      <div className={`${CSSBlock}__view`}>
        <div className={`${CSSBlock}__view-header`}>
          <DemmiIconButton
            icon={visible ? faLight.faArrowLeft : faLight.faBars}
            onClick={() => setVisible(!visible)}
            size={IconButtonSize.SMALL}
          />
          {title && <div className={`${CSSBlock}__view-title`}>{title}</div>}
        </div>
        <div className={`${CSSBlock}__view-content`}>{children}</div>
      </div>
    </div>
  );
};

export default PageWithSidebar;
