import { Timestamp } from 'firebase/firestore';
import React from 'react';

import { useAppSelector } from '@core/hooks/appHooks';
import { selectVendor } from '@core/store/appSlice/appSlice';
import DemmiIconButton, { IconButtonSize } from '@demmi-ui/IconButton';
import Menu from '@demmi-ui/Menu/Menu';
import { MenuContent, MenuItem } from '@demmi-ui/Menu/MenuTypes';
import { demmiRequest } from '@helpers/app.helper';
import { DemmiFS } from '@subhanhabib/demmilib';
import { NetworkService } from '@core/services/networkService/networkService';

interface Props {
  order: DemmiFS.Order;
  status: string;
}

const OrderItemHeader: React.FC<Props> = ({ order, status }) => {
  const CSSBlock = 'order-item-header';
  const vendor = useAppSelector(selectVendor);

  const updateStatus = (
    status: DemmiFS.OrderStatusHelper.OrderVendorStatus,
  ) => {
    if (vendor && order)
      demmiRequest(
        NetworkService.Orders.updateOrderStatus(order.docID, {
          vendorID: vendor.docID,
          timestamp: Timestamp.now(),
          statusUpdate: status,
          updatedBy: DemmiFS.OrderTimelineHelper.UpdatedBy.VENDOR,
        }),
      );
  };

  const content: MenuContent<DemmiFS.OrderStatusHelper.OrderVendorStatus> = {
    sections: [
      {
        items: [
          {
            id: DemmiFS.OrderStatusHelper.OrderVendorStatus.IN_PROGRESS,
            text: DemmiFS.OrderStatusHelper.getOrderVendorStatusText(
              DemmiFS.OrderStatusHelper.OrderVendorStatus.IN_PROGRESS,
            ),
            item: DemmiFS.OrderStatusHelper.OrderVendorStatus.IN_PROGRESS,
          } as MenuItem<DemmiFS.OrderStatusHelper.OrderVendorStatus>,
          {
            id: DemmiFS.OrderStatusHelper.OrderVendorStatus
              .READY_FOR_COLLECTION,
            text: DemmiFS.OrderStatusHelper.getOrderVendorStatusText(
              DemmiFS.OrderStatusHelper.OrderVendorStatus.READY_FOR_COLLECTION,
            ),
            item: DemmiFS.OrderStatusHelper.OrderVendorStatus
              .READY_FOR_COLLECTION,
          } as MenuItem<DemmiFS.OrderStatusHelper.OrderVendorStatus>,
          {
            id: DemmiFS.OrderStatusHelper.OrderVendorStatus.COLLECTED,
            text: DemmiFS.OrderStatusHelper.getOrderVendorStatusText(
              DemmiFS.OrderStatusHelper.OrderVendorStatus.COLLECTED,
            ),
            item: DemmiFS.OrderStatusHelper.OrderVendorStatus.COLLECTED,
          } as MenuItem<DemmiFS.OrderStatusHelper.OrderVendorStatus>,
        ],
      },
    ],
  };

  return (
    <div className={`${CSSBlock}`}>
      <div className={`${CSSBlock}__id`}>{`Order #${order.orderID}`}</div>
      <div className={`${CSSBlock}__status`}>
        <div className={`${CSSBlock}__status-badge`}>{status}</div>
        <Menu<DemmiFS.OrderStatusHelper.OrderVendorStatus>
          content={content}
          triggerEl={
            <DemmiIconButton
              size={IconButtonSize.MINI}
              faIcon="fa-caret-down"
            />
          }
          onClick={updateStatus}
        />
      </div>
    </div>
  );
};

export default OrderItemHeader;
