import { LSKeys, LocalStorage } from '@core/localStorage';
import { ThemeHelper } from '@helpers/theme.helper';
import { DemmiToast } from '@models/toast.model';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DemmiFS } from '@subhanhabib/demmilib';

import { RootState } from '../store';

interface AppState {
  themeMode: ThemeHelper.ThemeMode;
  vendor?: DemmiFS.Vendor;
  firestoreDemmi?: DemmiFS.DemmiConstants;
  activeRequests: string[];
  globalContentMounted: boolean;
  toasts: DemmiToast[];
}

const initialState: AppState = {
  themeMode: LocalStorage.get(LSKeys.THEME_MODE) as ThemeHelper.ThemeMode,
  vendor: undefined,
  firestoreDemmi: undefined,
  activeRequests: [],
  globalContentMounted: false,
  toasts: [],
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  extraReducers: builder => {
    builder.addCase('LOGOUT', state => {
      Object.assign(state, initialState);
    });
  },
  reducers: {
    setThemeMode: (
      state,
      action: PayloadAction<ThemeHelper.ThemeModeConstants>,
    ) => {
      state.themeMode = action.payload;
    },

    pushNetworkRequestUUID: (
      state,
      { payload: uuid }: PayloadAction<string>,
    ) => {
      state.activeRequests = [...state.activeRequests, uuid];
    },

    popNetworkRequestUUID: (
      state,
      { payload: uuid }: PayloadAction<string>,
    ) => {
      state.activeRequests = state.activeRequests.filter(
        request => request !== uuid,
      );
    },
    setVendor: (
      state,
      { payload: vendor }: PayloadAction<DemmiFS.Vendor | undefined>,
    ) => {
      state.vendor = vendor;
    },
    setFirestoreDemmi: (
      state,
      { payload }: PayloadAction<DemmiFS.DemmiConstants | undefined>,
    ) => {
      state.firestoreDemmi = payload;
    },
    setGlobalContentMounted: (state, { payload }: PayloadAction<boolean>) => {
      if (state.globalContentMounted !== payload)
        state.globalContentMounted = payload;
    },

    pushToast: (state, { payload: toast }: PayloadAction<DemmiToast>) => {
      console.log('pushToast', toast);
      Object.assign(state, { toasts: [...state.toasts, toast] });
    },

    popToast: (state, { payload: uuid }: PayloadAction<string>) => {
      console.log('popToast', uuid);
      state.toasts = state.toasts.filter(toast => toast.uuid !== uuid);
    },
  },
});

export const {
  setThemeMode,
  pushNetworkRequestUUID,
  popNetworkRequestUUID,
  setVendor,
  setFirestoreDemmi,
  setGlobalContentMounted,
  pushToast,
  popToast,
} = appSlice.actions;

export const selectThemeMode = (state: RootState) => state.app.themeMode;
export const selectVendor = (state: RootState) => state.app.vendor;
export const selectFirestoreDemmi = (state: RootState) =>
  state.app.firestoreDemmi;
export const selectIsLoading = (state: RootState) =>
  state.app.activeRequests.length > 0;
export const selectActiveRequests = (state: RootState) =>
  state.app.activeRequests;
export const selectGlobalContentMounted = (state: RootState) =>
  state.app.globalContentMounted;
export const selectToasts = (state: RootState) => state.app.toasts;

export default appSlice.reducer;
