import React, { useRef, useState } from 'react';

import DemmiButton from '@demmi-ui/Button';
import DemmiCardButtonArea from '@demmi-ui/Modal/ModalButtonArea';
import DemmiModalCore, { DemmiModalCoreRef } from '@demmi-ui/Modal/ModalCore';
import { demmiRequest } from '@helpers/app.helper';
import { DemmiFS, Logger } from '@subhanhabib/demmilib';

import ModalEditCouponContent from './ModalEditCouponContent';
import { NetworkService } from '@core/services/networkService/networkService';

interface Props {
  onClose: () => void;
  onConfirm: (coupon: DemmiFS.Coupon) => void;
  coupon: DemmiFS.Coupon;
}

const ModalEditCoupon: React.FC<Props> = ({ onClose, onConfirm, coupon }) => {
  const CSSBlock = 'modal-edit-coupon';
  const modalRef = useRef<DemmiModalCoreRef>(null);
  const [isOpen, setIsOpen] = useState(false);

  const [updatedCoupon, setUpdatedCoupon] = useState<DemmiFS.Coupon>();

  /**
   * 0 = Doing nothing
   * 1 = Updating coupon
   * -1 = Failed to update
   */
  const [state, setState] = useState(0);
  const [isValid, setIsValid] = useState(false);

  const gracefulExit = (postExit: () => void) => {
    modalRef.current ? modalRef.current.gracefulClose(postExit) : postExit();
    setTimeout(() => {
      setIsOpen(false);
      setState(0);
    }, 500);
  };

  const pushCoupon = async () => {
    Logger({ objs: { isValid, updatedCoupon } }, pushCoupon);
    if (isValid && updatedCoupon) {
      demmiRequest(NetworkService.Coupons.updateCouponDates(updatedCoupon))
        .catch(() => setState(-1))
        .then(_ => gracefulExit(() => onConfirm(updatedCoupon)));
    }
  };

  const buttons = [
    <DemmiButton
      key={0}
      text="Discard"
      outlined
      onClick={() => gracefulExit(onClose)}
    />,
    <DemmiButton
      key={1}
      text="Confirm Changes"
      disabled={!isValid}
      onClick={() => pushCoupon()}
    />,
  ];

  return (
    <>
      <DemmiButton
        faIcon="fa-pencil"
        text="Modify Dates"
        onClick={() => setIsOpen(true)}
      />
      <DemmiModalCore
        ref={modalRef}
        isOpen={isOpen}
        onExited={() => {
          setIsOpen(false);
          onClose();
        }}
        contentClassName={CSSBlock}
        allowBackdropClose={false}>
        <ModalEditCouponContent
          coupon={coupon}
          state={state}
          CSSBlock={CSSBlock}
          setCoupon={setUpdatedCoupon}
          setIsValid={setIsValid}
        />
        <DemmiCardButtonArea buttons={buttons} />
      </DemmiModalCore>
    </>
  );
};

export default ModalEditCoupon;
