import React, { useEffect } from 'react';
import { Navigate, useLocation, useOutlet } from 'react-router-dom';

import VendorWrapper from '@components/vendorWrapper/VendorWrapper';
import AppTheme from '@core/AppTheme';
import { useAppDispatch, useAppSelector } from '@core/hooks/appHooks';
import {
  selectGlobalContentMounted,
  setGlobalContentMounted,
} from '@core/store/appSlice/appSlice';
import { useAuth } from '@providers/authProvider';
import { DemmiHooks } from '@subhanhabib/demmilib';

const ProtectedRoute: React.FC = () => {
  const isMounted = useAppSelector(selectGlobalContentMounted);
  const shouldRender = DemmiHooks.useDelayUnmount(isMounted, 100);
  const { user } = useAuth();
  const { pathname } = useLocation();
  const outlet = useOutlet();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setGlobalContentMounted(true));
  }, [pathname]);

  return user ? (
    <>
      <AppTheme isLogin={false} />
      <VendorWrapper isMounted={isMounted} shouldRender={shouldRender}>
        {outlet}
      </VendorWrapper>
    </>
  ) : (
    <Navigate to="/" />
  );
};
export default ProtectedRoute;
