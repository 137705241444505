import React, { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '@core/hooks/appHooks';
import {
  selectVendor,
  setDemmiSupportChatID,
  setDemmiSupportOpen,
  setVendor,
} from '@core/store/appSlice/appSlice';
import DemmiButton from '@demmi-ui/Button';
import { demmiRequest } from '@helpers/app.helper';
import {
  DemmiSupportTypes,
  createSupportTicket,
} from '@helpers/demmiSupport.helper';
import { DemmiFS, DemmiHelpers } from '@subhanhabib/demmilib';

import { CSSBLOCK_SETTINGS } from '../Settings';
import GeoLocationMap from './GeoLocationMap';
import RegionAddressFields from './RegionAddressFields';
import { NetworkService } from '@core/services/networkService/networkService';

const SettingsLocation: React.FC = () => {
  const CSSBlock = 'settings-location';
  const dispatch = useAppDispatch();
  const vendor = useAppSelector(selectVendor);

  // Address
  const [address, setAddress] = useState<DemmiFS.VendorAddress>();
  const [detectedChanges, setDetectedChanges] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [isCreatingSupportTicket, setIsCreatingSupportTicket] = useState(false);

  useEffect(() => {
    if (vendor) setAddress(vendor.address);
  }, [vendor]);

  useEffect(() => {
    if (vendor) {
      setDetectedChanges(
        address
          ? DemmiHelpers.Vendor.addressHasChanges(vendor?.address, address)
          : vendor.address === address,
      );
      setIsValid(address ? DemmiHelpers.Vendor.isAddressValid(address) : false);
    }
  }, [address]);

  const discardChanges = () => {
    if (vendor) setAddress(vendor.address);
  };

  const saveChanges = async () => {
    if (isValid && vendor && address) {
      const f: DemmiFS.Vendor = {
        ...vendor,
        address,
      };
      demmiRequest(
        NetworkService.Vendors.updateVendorAddress(vendor.docID, address),
      ).then(() => {
        dispatch(setVendor(f));
      });
    }
  };

  const openSupportTicket = async () => {
    if (!vendor) return;
    setIsCreatingSupportTicket(true);
    await createSupportTicket(
      DemmiSupportTypes.MISSING_LOCATION,
      vendor,
      (success, chatID) => {
        if (!success) {
          setIsCreatingSupportTicket(false);
        }
        dispatch(setDemmiSupportOpen(true));
        dispatch(setDemmiSupportChatID(chatID));
        setIsCreatingSupportTicket(false);
      },
    );
  };

  return (
    <div className={`${CSSBLOCK_SETTINGS}__content-wrapper ${CSSBlock}`}>
      {address && (
        <>
          <GeoLocationMap
            CSSBlock={CSSBlock}
            vendorAddress={address}
            setVendorAddress={setAddress}
          />
          <RegionAddressFields
            CSSBlock={CSSBlock}
            vendorAddress={address}
            setVendorAddress={setAddress}
          />
        </>
      )}
      <div className={`${CSSBlock}__button-not-found`}>
        <div className={`${CSSBlock}__button-not-found-text`}>
          Can&apos;t find your location? We&apos;ll sort that out for you!
        </div>
        <DemmiButton
          disabled={isCreatingSupportTicket}
          text={'Open Support Ticket'}
          faIcon="fa-message-bot"
          onClick={openSupportTicket}
        />
      </div>
      <div className={`${CSSBlock}__buttons`}>
        <DemmiButton
          text={'Discard Changes'}
          faIcon="fa-ban"
          disabled={!detectedChanges}
          onClick={discardChanges}
        />
        <DemmiButton
          text={'Save Changes'}
          faIcon="fa-regular fa-floppy-disk"
          disabled={!detectedChanges || !isValid}
          onClick={saveChanges}
        />
      </div>
    </div>
  );
};

export default SettingsLocation;
