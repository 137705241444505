import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import PageWithSidebar from '@components/page/PageWithSidebar';
import { SearchParams } from '@core/services/routingService';
import DemmiIconButton, { IconButtonSize } from '@demmi-ui/IconButton';
import Menu from '@demmi-ui/Menu/Menu';
import MenuTriggerButton from '@demmi-ui/Menu/MenuTriggerButton';
import { DemmiFS } from '@subhanhabib/demmilib';

import OrderSidebarButton from './_sidebarButton';
import OrderItem from './orderItem/OrderItem';

interface Props {
  orders: DemmiFS.Order[];
  onClickRefresh: () => void;
}

const OrdersList: React.FC<Props> = ({ orders, onClickRefresh }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [sortBy, setSortBy] = useState(
    DemmiFS.OrderHelper.SORT_BY.COLLECTION_TIME_ASC,
  );
  const [sortedOrders, setSortedOrders] = useState(
    DemmiFS.OrderHelper.sortOrders(orders, sortBy),
  );
  const [selectedOrder, setSelectedOrder] = useState<
    DemmiFS.Order | undefined
  >();

  useEffect(() => {
    if (searchParams.get(SearchParams.ORDER_ID)) {
      setSelectedOrder(
        orders.find(o => o.docID === searchParams.get(SearchParams.ORDER_ID)),
      );
    } else {
      setSelectedOrder(undefined);
    }
  }, [orders, searchParams]);

  useEffect(() => {
    setSortedOrders(DemmiFS.OrderHelper.sortOrders(orders, sortBy));
  }, [orders, sortBy]);

  const getMenuContent = () => {
    return {
      sections: [
        {
          items: (
            Object.values(
              DemmiFS.OrderHelper.SORT_BY,
            ) as Array<DemmiFS.OrderHelper.SORT_BY>
          ).map(c => ({
            id: c,
            text: c,
            item: c,
          })),
        },
      ],
    };
  };

  const onClick = (i: number) => {
    const order = orders[i];
    if (order) {
      setSearchParams({ [SearchParams.ORDER_ID]: order.docID });
      setSelectedOrder(order);
    }
  };

  const sidebarButtons = sortedOrders.map((order, i) => (
    <OrderSidebarButton
      active={selectedOrder?.docID === order.docID}
      order={order}
      key={i}
    />
  ));

  const sidebarTitleButtons = [
    <Menu<DemmiFS.OrderHelper.SORT_BY>
      content={getMenuContent()}
      triggerEl={<MenuTriggerButton title="Sort By" text={sortBy} />}
      onClick={sortBy => setSortBy(sortBy)}
    />,
    <div style={{ marginLeft: 'auto' }}>
      <DemmiIconButton
        faIcon="fa-rotate-right"
        onClick={onClickRefresh}
        size={IconButtonSize.SMALL}
      />
    </div>,
  ];

  return (
    <PageWithSidebar
      sidebar={{
        customButtons: sidebarButtons,
        titleButtons: sidebarTitleButtons,
        active: orders.findIndex(f => selectedOrder?.docID === f.docID),
        onClick: onClick,
        searchPlaceholder: 'Filter Orders',
        onSearch: (filter: string) => {},
      }}
      title="Orders">
      {/* <div className={`${CSSBlock}`}> */}
      {selectedOrder && <OrderItem orderDocID={selectedOrder.docID} />}
      {/* </div> */}
    </PageWithSidebar>
  );
};

export default OrdersList;
