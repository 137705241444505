import React from 'react';

import ElementLoadingIndicator from '@components/loadingIntro/ElementLoadingIndicator';
import { useAppSelector } from '@core/hooks/appHooks';
import { selectVendor } from '@core/store/appSlice/appSlice';
import DemmiButton from '@demmi-ui/Button';
import DemmiTimeline, { DemmiTimelineItem } from '@demmi-ui/Timeline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DemmiFS } from '@subhanhabib/demmilib';

import OrderItemTabRefundDetails from './_tab-refund-details';
import OrderItemTabRefundUpdate from './_tab-refund-update';
import { faMoneyBillTransfer } from '@fortawesome/pro-light-svg-icons';

interface Props {
  refundRequest: DemmiFS.RefundRequest;
  isLoading: boolean;
  order: DemmiFS.Order;
}

const OrderItemTabRefundExists: React.FC<Props> = ({
  refundRequest,
  isLoading,
  order,
}) => {
  const CSSBlock = 'order-refund-request';
  const vendor = useAppSelector(selectVendor);

  const timelineItems: DemmiTimelineItem[] = refundRequest
    ? refundRequest.updates.map(update => ({
        ...(update.statusUpdate
          ? {
              title: `Refund ${DemmiFS.RefundHelper.getRefundStatusText(
                update.statusUpdate,
              ).toLowerCase()}`,
            }
          : {}),
        date: DemmiFS.getDateFromFirestore(update.timestamp),
        ...(update.text
          ? {
              text: update.text,
              textBadge: update.vendorID ? 'You' : 'Customer',
            }
          : {}),
      }))
    : [];

  return (
    <div
      className={`${CSSBlock} ${isLoading ? `${CSSBlock}--is-loading` : ''}`}>
      <div className={`${CSSBlock}__title`}>
        <FontAwesomeIcon size={'lg'} icon={faMoneyBillTransfer} />
        {DemmiFS.RefundHelper.getRefundTypeText(refundRequest.type)} refund of{' '}
        <span>
          {DemmiFS.CurrencyHelper.getCurrencySymbolFromCurrencyCode(
            refundRequest.costEstimate.currencyCode,
          )}
          {Number(refundRequest.costEstimate.totalRefund).toFixed(2)}
        </span>{' '}
        {DemmiFS.RefundHelper.getCurrentRefundStatus(refundRequest)}
      </div>
      <OrderItemTabRefundDetails
        CSSBlock={CSSBlock}
        refundRequest={refundRequest}
        order={order}
      />
      <DemmiTimeline items={timelineItems} />
      {DemmiFS.RefundHelper.getCurrentRefundStatus(refundRequest) ===
      DemmiFS.RefundHelper.RefundStatus.REQUESTED ? (
        <div className={`${CSSBlock}__action-area`}>
          <div className={`${CSSBlock}__key-val`}>
            <div className={`${CSSBlock}__buttons`}>
              <DemmiButton text="Accept" onClick={() => {}} />
              <DemmiButton text="Reject" onClick={() => {}} />
            </div>
          </div>
        </div>
      ) : (
        <> </>
      )}

      <ElementLoadingIndicator isLoading={isLoading} />

      {vendor && refundRequest && (
        <OrderItemTabRefundUpdate
          CSSBlock={CSSBlock}
          vendorID={vendor.docID}
          refundRequest={refundRequest}
        />
      )}
    </div>
  );
};

export default OrderItemTabRefundExists;
