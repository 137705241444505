import React, { useEffect, useState } from 'react';

import DemmiButton from '@demmi-ui/Button';
import DemmiCard from '@demmi-ui/Card/Card';
import DemmiIconButton from '@demmi-ui/IconButton';
import InputTime from '@demmi-ui/InputTime/InputTime';
import DemmiToggle from '@demmi-ui/Toggle';
import * as faLight from '@fortawesome/pro-light-svg-icons';
import { TimeSlotsHelper } from '@helpers/timeslots.helper';
import { DemmiFS, DemmiHelpers } from '@subhanhabib/demmilib';

import ShopManagementOpeningHoursDayPreview from './_dayPreview';

interface Props {
  CSSBlock: string;
  dayTitle: string;
  dayDetail: TimeSlotsHelper.DayDetail;
  toggleDayOpen: (open: boolean) => void;
  onChangeTime: (
    key: TimeSlotsHelper.TimeSlotKeys,
    i: number,
    type: 'start' | 'end',
  ) => (val: number) => void;
  addTimeSlot: (key: TimeSlotsHelper.TimeSlotKeys) => () => void;
  removeTimeSlot: (key: TimeSlotsHelper.TimeSlotKeys, i: number) => () => void;
}

const ShopManagementOpeningHoursDay: React.FC<Props> = ({
  CSSBlock,
  dayTitle,
  dayDetail,
  toggleDayOpen,
  onChangeTime,
  addTimeSlot,
  removeTimeSlot,
}) => {
  const [calculatedOpenHours, setCalculatedOpenHours] =
    useState<TimeSlotsHelper.CalculatedDayDetail>(
      DemmiHelpers.VendorTimeSlotsHelper.calculateOpenHoursDay(
        dayDetail.openSlots,
        dayDetail.exceptionSlots,
      ),
    );

  useEffect(() => {
    setCalculatedOpenHours(
      DemmiHelpers.VendorTimeSlotsHelper.calculateOpenHoursDay(
        dayDetail.openSlots,
        dayDetail.exceptionSlots,
      ),
    );
  }, [dayDetail]);

  const slotsContainer = (
    title: string,
    slots: DemmiFS.VendorHelper.TimeInterval[],
    key: TimeSlotsHelper.TimeSlotKeys,
  ) => (
    <div className={`${CSSBlock}__slots-container`}>
      <div className={`${CSSBlock}__slots-title`}>
        {title}
        <DemmiButton text="Add Slot" onClick={addTimeSlot(key)} />
      </div>
      {slots.map((slot, i) => (
        <div className={`${CSSBlock}__slot-row`} key={i}>
          <div className={`${CSSBlock}__slot-item`}>
            <div className={`${CSSBlock}__slot-title`}>Start</div>
            <InputTime
              value={slot.start}
              onChange={onChangeTime(key, i, 'start')}
            />
          </div>
          <div className={`${CSSBlock}__slot-item`}>
            <div className={`${CSSBlock}__slot-title`}>End</div>
            <InputTime
              value={slot.end}
              onChange={onChangeTime(key, i, 'end')}
            />
          </div>
          <DemmiIconButton
            icon={faLight.faTrashCan}
            onClick={removeTimeSlot(key, i)}
          />
        </div>
      ))}
    </div>
  );

  return (
    <DemmiCard className={`${CSSBlock}__day`}>
      <div className={`${CSSBlock}__day-title`}>
        {dayTitle}
        <div className={`${CSSBlock}__day-toggle`}>
          <DemmiToggle
            text={'Open'}
            onToggle={toggleDayOpen}
            isActive={dayDetail.isOpen}
          />
        </div>
      </div>

      <ShopManagementOpeningHoursDayPreview
        CSSBlock={CSSBlock}
        dayDetail={calculatedOpenHours}
        isOpen={dayDetail.isOpen}
      />

      {dayDetail.isOpen && (
        <>
          {slotsContainer(
            'Opening Hour Slots',
            dayDetail.openSlots,
            'openSlots',
          )}
          {slotsContainer(
            'Exceptions',
            dayDetail.exceptionSlots,
            'exceptionSlots',
          )}
        </>
      )}
    </DemmiCard>
  );
};
export default ShopManagementOpeningHoursDay;
