import { PropsWithChildren } from 'react';
import { PageTabBase } from './_helper';
import PageTab from './_pageTab';

export interface Props<T extends PageTabBase> extends PropsWithChildren {
  tabs: T[];
}

const PageWithTabs = <T extends PageTabBase>({ tabs, children }: Props<T>): JSX.Element => {
  const CSSBlock = 'page';

  return (
    <div className={`${CSSBlock} ${CSSBlock}--with-tabs`}>
      <div className={`${CSSBlock}__tabs`}>
        {tabs.map(tab => (
          <PageTab tab={tab} key={tab.id} />
        ))}
      </div>
      <div className={`${CSSBlock}__view`}>{children}</div>
    </div>
  );
};

export default PageWithTabs;
