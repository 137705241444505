import { Timestamp } from 'firebase/firestore';

import {
  VendorMappedSchedule,
  VendorMappedScheduleDay,
} from '@models/vendorMappedSchedule.model';
import { DemmiFS } from '@subhanhabib/demmilib';

export namespace ScheduleHelper {
  /**
   * Mapping functions
   */
  export const mapToVendorMappedSchedule = (
    vendorID: string,
    scheduleID: string,
    startDate: Date,
    endDate: Date,
    schedule: DemmiFS.FSVendorTimeSlotSchedule[],
  ): VendorMappedSchedule => {
    const mappedSchedule: VendorMappedSchedule = {
      vendorID,
      scheduleID,
      startDate,
      endDate,
      days: [],
    };
    schedule.forEach(slot => {
      mappedSchedule.days.push({
        dayOfWeek: slot.dayOfWeek,
        startTime: slot.startTime,
        endTime: slot.endTime,
        slotDuration: slot.slotDuration,
        capacity: slot.capacity,
        enabled: true,
        breaks: slot.breaks ?? [],
      });
    });
    return mappedSchedule;
  };

  export const mapToVendorTimeSlotSchedule = (
    vendorID: string,
    scheduleID: string,
    startDate: Date,
    endDate: Date,
    days: VendorMappedScheduleDay[],
  ): DemmiFS.FSVendorTimeSlotSchedule[] => {
    const mappedSchedule: DemmiFS.FSVendorTimeSlotSchedule[] = [];
    days
      .filter(day => day.enabled)
      .forEach(slot => {
        mappedSchedule.push({
          vendorID: vendorID,
          scheduleID: scheduleID,
          dayOfWeek: slot.dayOfWeek,
          startTime: slot.startTime,
          endTime: slot.endTime,
          slotDuration: slot.slotDuration,
          capacity: slot.capacity,
          startDate: Timestamp.fromDate(startDate),
          endDate: Timestamp.fromDate(endDate),
          breaks: slot.breaks,
        });
      });
    return mappedSchedule;
  };

  export const getTimeSlotDuration = (
    duration: DemmiFS.VendorHelper.TimeSlotScheduleDuration,
  ): string => {
    switch (duration) {
      case DemmiFS.VendorHelper.TimeSlotScheduleDuration.MIN_15:
        return '15 minutes';
      case DemmiFS.VendorHelper.TimeSlotScheduleDuration.MIN_30:
        return '30 minutes';
      case DemmiFS.VendorHelper.TimeSlotScheduleDuration.MIN_60:
        return '60 minutes';
      default:
        return '';
    }
  };

  export const getDayTitleFromKey = (
    key: DemmiFS.DemmiHelper.DayKeys,
  ): string => {
    switch (key) {
      case DemmiFS.DemmiHelper.DayKeys.MONDAY:
        return 'Monday';
      case DemmiFS.DemmiHelper.DayKeys.TUESDAY:
        return 'Tuesday';
      case DemmiFS.DemmiHelper.DayKeys.WEDNESDAY:
        return 'Wednesday';
      case DemmiFS.DemmiHelper.DayKeys.THURSDAY:
        return 'Thursday';
      case DemmiFS.DemmiHelper.DayKeys.FRIDAY:
        return 'Friday';
      case DemmiFS.DemmiHelper.DayKeys.SATURDAY:
        return 'Saturday';
      case DemmiFS.DemmiHelper.DayKeys.SUNDAY:
        return 'Sunday';
      default:
        return '';
    }
  };

  export const doesDayExistInDateRange = (
    day: DemmiFS.DemmiHelper.DayKeys,
    startDate: Date,
    endDate: Date,
  ) => {
    const start = new Date(startDate);

    while (start <= endDate) {
      const dayIndex = start.getDay();
      // Checking for sunday
      if (day === DemmiFS.DemmiHelper.DayKeys.SUNDAY) {
        if (dayIndex === 0) return true;
      } else if (
        start.getDay() - 1 ===
        Object.values(DemmiFS.DemmiHelper.DayKeys).findIndex(d => d === day)
      ) {
        return true;
      }
      start.setDate(start.getDate() + 1);
    }
    return false;
  };
}
