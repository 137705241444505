import React, { useEffect, useState } from 'react';

import ModalCreateProduct from '@components/modals/CreateProduct/ModalCreateProduct';
import DemmiCard from '@demmi-ui/Card/Card';
import DemmiIconButton, { IconButtonSize } from '@demmi-ui/IconButton';
import DemmiInput from '@demmi-ui/Input';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DemmiFS } from '@subhanhabib/demmilib';

import InventorySidebarButton from './SidebarButton';
import { useFirestoreDemmi } from '@core/contexts/FirestoreDemmiContext';

interface ProductCategory {
  icon: IconDefinition;
  categoryName: string;
  products: DemmiFS.Product[];
}

interface CategorisedProducts {
  [key: string]: ProductCategory;
}

interface Props {
  active: string;
  onClick: (id: string) => void;
  onClickRefresh: () => void;
  onClickPreview: () => void;
  onCreate: (id: string) => void;
  products: DemmiFS.Product[];
}

const InventorySidebar: React.FC<Props> = ({
  onCreate,
  onClickRefresh,
  onClickPreview,
  products,
  onClick,
  active,
}) => {
  const CSSBlock = 'sidebar';
  const firestoreDemmi = useFirestoreDemmi();
  const [filteredProducts, setFilteredProducts] = useState<CategorisedProducts>(
    {},
  );

  useEffect(() => {
    organiseProducts();
  }, [products]);

  const organiseProducts = (filter?: string) => {
    const organised = products.reduce(
      (acc: CategorisedProducts, cur: DemmiFS.Product) => {
        if (filter && !cur.name.toLowerCase().includes(filter.toLowerCase()))
          return acc;
        if (acc[cur.categoryID]) {
          return {
            ...acc,
            [cur.categoryID]: {
              ...{ ...acc[cur.categoryID] },
              products: [...acc[cur.categoryID].products, cur],
            },
          };
        } else {
          const cat = DemmiFS.CategoriesHelper.getCategory(
            firestoreDemmi?.categories,
            cur.categoryID,
          );
          return {
            ...acc,
            [cur.categoryID]: {
              icon:
                cat?.child?.icon ||
                cat?.parent?.icon ||
                DemmiFS.CategoriesHelper.getIconFromCategoryID(
                  firestoreDemmi?.categories,
                  cur.categoryID,
                ),
              categoryName: cat?.child?.name || cat?.parent?.name || '',
              products: [cur],
            },
          };
        }
      },
      {},
    );
    setFilteredProducts(organised);
  };

  return (
    <div className={`${CSSBlock} ${CSSBlock}--inventory`}>
      <DemmiCard darkOnly className={`${CSSBlock}__header`}>
        <div className={`${CSSBlock}__title`}>
          Products
          <div className={`${CSSBlock}__title-buttons`}>
            <DemmiIconButton
              faIcon={'fa-rotate-right'}
              onClick={onClickRefresh}
              size={IconButtonSize.SMALL}
            />
            <ModalCreateProduct onClose={() => {}} onConfirm={onCreate} />
            <DemmiIconButton
              disabled={active.length === 0}
              faIcon={'fa-magnifying-glass'}
              onClick={onClickPreview}
              size={IconButtonSize.SMALL}
            />
          </div>
        </div>
        <div className={`${CSSBlock}__search`}>
          <DemmiInput
            onChangeValue={val => organiseProducts(val)}
            invert
            type="text"
            placeholder="Filter products"
          />
        </div>
      </DemmiCard>
      <div className={`${CSSBlock}__content`}>
        {Object.values(filteredProducts).map((category, i) => (
          <DemmiCard darkOnly key={i} rootClassName={`${CSSBlock}__category`}>
            <div className={`${CSSBlock}__category-title`}>
              <FontAwesomeIcon icon={category.icon} />
              {category.categoryName}
            </div>
            {category.products.map((product, i) => (
              <InventorySidebarButton
                product={product}
                active={product.docID === active}
                key={i}
                onClick={() => {
                  onClick(product.docID);
                }}
              />
            ))}
          </DemmiCard>
        ))}
      </div>
    </div>
  );
};

export default InventorySidebar;
