import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import ModalCreateProduct from '@components/modals/CreateProduct/ModalCreateProduct';
import PageWithSidebar from '@components/page/PageWithSidebar';
import { useFirestoreDemmi } from '@core/contexts/FirestoreDemmiContext';
import { useAppSelector } from '@core/hooks/appHooks';
import { NetworkService } from '@core/services/networkService/networkService';
import { SearchParams } from '@core/services/routingService';
import { selectVendor } from '@core/store/appSlice/appSlice';
import DemmiCard from '@demmi-ui/Card/Card';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { demmiRequest } from '@helpers/app.helper';
import { CSSVARS, setCSSVar } from '@helpers/theme.helper';
import { DemmiFS } from '@subhanhabib/demmilib';

import InventorySidebar from './Sidebar';
import InventorySidebarButton from './_sidebarButton';
import ProductDetails from './productDetails/ProductDetails';
import ProductVariants from './productVariants/ProductVariants';

interface ProductCategory {
  icon: IconDefinition;
  categoryName: string;
  products: DemmiFS.Product[];
}

interface CategorisedProducts {
  [key: string]: ProductCategory;
}

export const organiseProducts = (
  products: DemmiFS.Product[],
  firestoreDemmi: DemmiFS.DemmiConstants | undefined,
  filter?: string,
) => {
  const organised = products.reduce(
    (acc: CategorisedProducts, cur: DemmiFS.Product) => {
      if (filter && !cur.name.toLowerCase().includes(filter.toLowerCase()))
        return acc;
      if (acc[cur.categoryID]) {
        return {
          ...acc,
          [cur.categoryID]: {
            ...{ ...acc[cur.categoryID] },
            products: [...acc[cur.categoryID].products, cur],
          },
        };
      } else {
        const cat = DemmiFS.CategoriesHelper.getCategory(
          firestoreDemmi?.categories,
          cur.categoryID,
        );
        return {
          ...acc,
          [cur.categoryID]: {
            icon:
              cat?.child?.icon ||
              cat?.parent?.icon ||
              DemmiFS.CategoriesHelper.getIconFromCategoryID(
                firestoreDemmi?.categories,
                cur.categoryID,
              ),
            categoryName: cat?.child?.name || cat?.parent?.name || '',
            products: [cur],
          },
        };
      }
    },
    {},
  );
  return organised;
};
