import React from 'react';

import { ThemeHelper } from '@helpers/theme.helper';

interface Props {
  hue: number;
  onClick: (hue: number) => void;
}

const ShopManagementThemeHueItem: React.FC<Props> = ({
  hue,
  onClick: onClickProp,
}) => {
  const CSSBlock = 'shop-management-theme-item';

  const onClick = () => {
    ThemeHelper.setCSSVar(
      ThemeHelper.CSSVARS.PHONE_PREVIEW_HUE,
      hue.toString(),
    );
    onClickProp(hue);
  };

  return (
    <div className={CSSBlock} onClick={onClick}>
      {Object.entries(ThemeHelper.DemmiTheme.get(hue).palette).map(
        ([_, v], i) =>
          ThemeHelper.isHSL(v) ? (
            <div
              className={`${CSSBlock}__block`}
              style={{ backgroundColor: `hsl(${v.h}, ${v.s}%, ${v.l}%)` }}
              key={i}
            />
          ) : (
            <></>
          ),
      )}
    </div>
  );
};

export default ShopManagementThemeHueItem;
