import { Timestamp } from 'firebase/firestore';
import React from 'react';
import { DemmiFS } from '@subhanhabib/demmilib';
import { NetworkService } from '@core/services/networkService/networkService';
import { demmiRequest } from '@helpers/app.helper';
import DemmiButton from '@demmi-ui/Button';
import { selectVendor } from '@core/store/appSlice/appSlice';
import { useAppSelector } from '@core/hooks/appHooks';

interface Props {
  order: DemmiFS.Order;
  onCreate: (docID: string) => void;
}

const OrderItemTabRefundNone: React.FC<Props> = ({ order, onCreate }) => {
  const CSSBlock = 'order-refund-request';
  const vendor = useAppSelector(selectVendor);

  const calculateRefundRequest = (): DemmiFS.RefundRequestCostEstimate => {
    const productsCost = order.vendor!.products.reduce(
      (acc, cur) => acc + cur.price,
      0,
    );
    let couponDeduction = 0;
    if (order.vendor!.coupon) {
      if (
        order.vendor!.coupon.type ===
        DemmiFS.CouponHelper.CouponTypes.coupon_discount_fixed
      ) {
        couponDeduction = order.vendor!.coupon.amount;
      } else if (
        order.vendor!.coupon.type ===
        DemmiFS.CouponHelper.CouponTypes.coupon_discount_percent
      ) {
        couponDeduction = order.vendor!.products.reduce(
          (acc, cur) => acc + cur.price * order.vendor!.coupon!.amount,
          0,
        );
      }
    }
    return {
      productsCost: productsCost,
      couponDeduction: couponDeduction,
      totalRefund: productsCost - couponDeduction,
      currencyCode: order.vendor!.cost.currencyCode,
    };
  };

  const createRefundRequest = () => {
    const request: DemmiFS.FSRefundRequest = {
      userID: '',
      orderDocID: order.docID,
      type: DemmiFS.RefundHelper.RefundType.FULL_VENDOR,
      vendorID: vendor!.docID,
      productIDs: order.vendor!.products.map(p => p.uID),
      timestamp: Timestamp.now(),
      updates: [],
      costEstimate: calculateRefundRequest(),
    };
    return request;
  };

  const initiateRefundRequest = () => {
    if (!vendor) return;
    const request = createRefundRequest();
    demmiRequest(NetworkService.RefundRequests.createRefundRequest(request))
      .then(onCreate)
      .catch(() => {});
  };

  return (
    <div className={`${CSSBlock}__none`}>
      <div className={`${CSSBlock}__message`}>
        This order has no refund request.
      </div>
      <DemmiButton
        text="Initiate Refund"
        onClick={() => initiateRefundRequest()}
      />
    </div>
  );
};

export default OrderItemTabRefundNone;
