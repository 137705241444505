import React, { useState } from 'react';

import AsyncImage from '@components/asyncImage/AsyncImage';
import { useAppSelector } from '@core/hooks/appHooks';
import {
  deleteProductImage,
  listenToImageResizing,
  uploadProductImage,
} from '@core/services/networkService/functions/_storage';
import { selectVendor } from '@core/store/appSlice/appSlice';
import DemmiCard from '@demmi-ui/Card/Card';
import DemmiIconButton, { IconButtonSize } from '@demmi-ui/IconButton';
import { demmiRequest } from '@helpers/app.helper';
import { useAuth } from '@providers/authProvider';
import { DemmiFS, Logger } from '@subhanhabib/demmilib';

import ModalAddImage from '../../../../components/modals/AddImage/ModalAddImage';
import { NetworkService } from '@core/services/networkService/networkService';

interface Props {
  product: DemmiFS.Product;
  images: DemmiFS.DemmiImageResource[];
  setImages: React.Dispatch<React.SetStateAction<DemmiFS.DemmiImageResource[]>>;
}

const Images: React.FC<Props> = ({ images, setImages, product }) => {
  const CSSBlock = 'product-images';
  const vendor = useAppSelector(selectVendor);
  const [isDoingRequest, setIsDoingRequest] = useState(false);
  const { user } = useAuth();

  const onClickDelete = (i: number) => {
    setIsDoingRequest(true);
    demmiRequest(deleteProductImage(product.docID, i)).then(res => {
      if (res === false) {
        // TODO: show error toast
      } else {
        const updatedImages = [...images.slice(0, i), ...images.slice(i + 1)];
        setImages(updatedImages);
      }
      setIsDoingRequest(false);
    });
  };

  const onMoveLeft = (i: number) => {
    setImages([
      ...images.slice(0, i - 1),
      images[i],
      images[i - 1],
      ...images.slice(i + 1),
    ]);
  };
  const onMoveRight = (i: number) => {
    setImages([
      ...images.slice(0, i),
      images[i + 1],
      images[i],
      ...images.slice(i + 2),
    ]);
  };

  const onImageConfirm = (file: File) => {
    if (vendor && product) {
      const filename = DemmiFS.ImageHelper.Vendor.generateProductImageName(
        file,
        product.docID,
        product.vendorID,
      );
      Logger({ objs: { user, file, filename } }, onImageConfirm);

      setIsDoingRequest(true);
      demmiRequest(uploadProductImage(product.vendorID, file, filename)).then(
        imageResource => {
          if (imageResource) {
            demmiRequest(
              NetworkService.Product.updateProductImages(product.docID, [
                ...images,
                imageResource,
              ]),
            ).then(async () => {
              const unsubscribe = await listenToImageResizing(
                vendor.docID,
                filename,
                success => {
                  if (success) {
                    setImages([...images, imageResource]);
                    setIsDoingRequest(false);
                    unsubscribe();
                  } else {
                    // TODO: Show error toast
                    setImages([...images, imageResource]);
                    setIsDoingRequest(false);
                    unsubscribe();
                  }
                },
              );
            });
          } else {
            // TODO: Add toast.
            setIsDoingRequest(false);
          }
        },
      );
    }
  };

  return (
    <DemmiCard
      title="Images"
      subtitle="Max 6 images"
      className={CSSBlock}
      isLoading={isDoingRequest}
      titleButtons={[
        <ModalAddImage
          key={'modal-add'}
          disabled={images.length >= 5}
          onClose={() => {}}
          onConfirm={onImageConfirm}
        />,
      ]}>
      <div className={`${CSSBlock}__grid`}>
        {images.map((image, i) => (
          <div
            className={`${CSSBlock}__image-item ${
              i === 0 ? `${CSSBlock}__image-item--primary` : ''
            }`}
            key={i}>
            {i === 0 && (
              <div className={`${CSSBlock}__image-text`}>Primary image</div>
            )}
            <div className={`${CSSBlock}__image-wrapper`}>
              <AsyncImage src={image.main} />
            </div>
            <div className={`${CSSBlock}__image-controls`}>
              <DemmiIconButton
                faIcon="fa-solid fa-left"
                disabled={images.length === 1 || i === 0}
                overlay
                size={IconButtonSize.SMALL}
                onClick={() => onMoveLeft(i)}
              />
              <DemmiIconButton
                faIcon="fa-solid fa-right"
                size={IconButtonSize.SMALL}
                overlay
                disabled={images.length === 1}
                onClick={() => onMoveRight(i)}
              />
              <DemmiIconButton
                faIcon="fa-solid fa-trash"
                overlay
                size={IconButtonSize.SMALL}
                disabled={images.length === 1}
                onClick={() => onClickDelete(i)}
              />
            </div>
          </div>
        ))}
      </div>
    </DemmiCard>
  );
};

export default Images;
