import {
  QueryDocumentSnapshot,
  Unsubscribe,
  addDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
} from 'firebase/firestore';

import { FSCollections } from '@providers/firestoreProvider';
import { DemmiFS, DemmiLogType, Logger } from '@subhanhabib/demmilib';

import { FSSubCollectionNames } from '../networkService.helper';
import {
  parseToDemmiSupportChat,
  parseToDemmiSupportMessage,
  sortSupportChats,
} from './_helper';
import { demmiSupportChatQuery, demmiSupportQuery } from './_queries';

export class FSDemmiSupport {
  static _listenToDemmiSupport = async (
    vendorID: string,
    callback: (requests: DemmiFS.DemmiSupport[]) => void,
  ): Promise<Unsubscribe> => {
    Logger({ objs: { vendorID } }, this._listenToDemmiSupport);
    return onSnapshot(demmiSupportQuery(vendorID), async querySnapshot => {
      const chats: DemmiFS.DemmiSupport[] = [];
      const messageRequests: {
        [key: string]: Promise<DemmiFS.DemmiSupportMessage | undefined>;
      } = {};
      querySnapshot.forEach(
        async (doc: QueryDocumentSnapshot<DemmiFS.FSDemmiSupport>) => {
          messageRequests[doc.id] = this._getLastDemmiSupportMessage(doc.id);
        },
      );
      const messages = await Promise.all(Object.values(messageRequests));
      querySnapshot.forEach(
        async (doc: QueryDocumentSnapshot<DemmiFS.FSDemmiSupport>) => {
          const lastMessage =
            messages[Object.keys(messageRequests).indexOf(doc.id)];

          chats.push({
            lastMessage,
            ...parseToDemmiSupportChat(doc),
          });
        },
      );
      callback(sortSupportChats(chats));
    });
  };

  static _listenToDemmiSupportChatMessages = async (
    chatID: string,
    callback: (requests: DemmiFS.DemmiSupportMessage[]) => void,
  ): Promise<Unsubscribe> => {
    Logger({ objs: { chatID } }, this._listenToDemmiSupportChatMessages);
    return onSnapshot(demmiSupportChatQuery(chatID), querySnapshot => {
      const messages: DemmiFS.DemmiSupportMessage[] = [];
      querySnapshot.forEach(
        (doc: QueryDocumentSnapshot<DemmiFS.FSDemmiSupportMessage>) => {
          const m = parseToDemmiSupportMessage(doc);
          if (m.timestamp) messages.push(m);
        },
      );
      callback(messages.sort((a, b) => (a.timestamp! > b.timestamp! ? 1 : -1)));
    });
  };

  static _getLastDemmiSupportMessage = async (
    chatID: string,
  ): Promise<DemmiFS.DemmiSupportMessage | undefined> => {
    Logger({ objs: { chatID } }, this._getLastDemmiSupportMessage);
    const q = query(
      FSCollections.DemmiSupportMessages([
        chatID,
        FSSubCollectionNames.DEMMI_SUPPORT_MESSAGES,
      ]),
      orderBy('timestamp', 'desc'),
      limit(1),
    );

    const querySnapshot = await getDocs(q).catch(e => {
      Logger(
        { objs: { chatID, e }, type: DemmiLogType.error },
        this._getLastDemmiSupportMessage,
      );
    });
    if (!querySnapshot || querySnapshot.empty) return undefined;
    return parseToDemmiSupportMessage(querySnapshot.docs[0]);
  };

  static _getDemmiSupportChat = async (
    chatID: string,
  ): Promise<DemmiFS.DemmiSupport | undefined> => {
    Logger({ objs: { chatID } }, this._getDemmiSupportChat);
    const docSnap = await getDoc(doc(FSCollections.DemmiSupport, chatID));
    if (docSnap.exists()) {
      return parseToDemmiSupportChat(docSnap);
    } else {
      Logger(
        { messages: ['No such document!'], type: DemmiLogType.error },
        this._getDemmiSupportChat,
      );
    }
    return undefined;
  };

  static _sendDemmiSupportMessage = async (
    chatID: string,
    message: DemmiFS.FSDemmiSupportMessage,
  ): Promise<string> => {
    Logger({ objs: { chatID, message } }, this._sendDemmiSupportMessage);
    const docRef = await addDoc(
      FSCollections.DemmiSupportMessages([
        chatID,
        FSSubCollectionNames.DEMMI_SUPPORT_MESSAGES,
      ]),
      message,
    );
    return docRef.id;
  };

  static _createDemmiSupportChat = async (
    chat: DemmiFS.FSDemmiSupport,
  ): Promise<string> => {
    Logger({ objs: { chat } }, this._createDemmiSupportChat);
    const docRef = await addDoc(FSCollections.DemmiSupport, chat);
    return docRef.id;
  };
}
