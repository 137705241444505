import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';

import { useAppSelector } from '@core/hooks/appHooks';
import { selectVendor } from '@core/store/appSlice/appSlice';
import { DemmiFS, DemmiHelpers } from '@subhanhabib/demmilib';

interface Props {
  order: DemmiFS.Order;
  active: boolean;
}

const OrderSidebarButton: React.FC<Props> = ({ order, active }) => {
  const CSSBlock = 'orders-sidebar-button';
  const vendor = useAppSelector(selectVendor);
  const [status, setStatus] = useState('');

  useEffect(() => {
    if (vendor)
      setStatus(
        DemmiFS.OrderStatusHelper.getStatusFromOrder(vendor.docID, order),
      );
  }, [vendor, order]);

  return (
    order.vendor && (
      <div className={`${CSSBlock} ${active ? `${CSSBlock}--active` : ''}`}>
        {<div className={`${CSSBlock}__status`}>{status}</div>}
        <div className={`${CSSBlock}__key-val-row`}>
          <div className={`${CSSBlock}__key-val`}>
            <div className={`${CSSBlock}__key`}>Collection Date</div>
            <div className={`${CSSBlock}__value`}>
              {order.vendor.collectionDate
                ? DemmiHelpers.Dates.formatCollectionDate(
                    order.vendor.collectionDate,
                  )
                : 'No date given'}
            </div>
          </div>
          <div className={`${CSSBlock}__key-val`}>
            <div className={`${CSSBlock}__key`}>Collection Time</div>
            <div className={`${CSSBlock}__value`}>
              {order.vendor.collectionTime
                ? `${DemmiHelpers.DemmiTime.timeToString(
                    order.vendor.collectionTime,
                  )} - ${DemmiHelpers.DemmiTime.timeToString(
                    order.vendor.collectionTime + 30,
                  )}`
                : 'No time given'}
            </div>
          </div>
        </div>
        <div className={`${CSSBlock}__key-val-row`}>
          <div className={`${CSSBlock}__key-val`}>
            <div className={`${CSSBlock}__key`}>Order Date</div>
            <div className={`${CSSBlock}__value`}>
              {order.orderDate &&
                format(
                  DemmiFS.getDateFromFirestore(order.orderDate)!,
                  'dd MMM yyyy, HH:mm:ss',
                )}
            </div>
          </div>
          <div className={`${CSSBlock}__key-val`}>
            <div className={`${CSSBlock}__key`}>Total Order Cost</div>
            <div className={`${CSSBlock}__value`}>
              {DemmiFS.CurrencyHelper.getCurrencySymbolFromCurrencyCode(
                order.vendor.cost.currencyCode,
              )}
              {Number(
                DemmiFS.OrderHelper.getTotalOrderCost(order.vendor),
              ).toFixed(2)}
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default OrderSidebarButton;
