import React, { useState } from 'react';

import AsyncImage from '@components/asyncImage/AsyncImage';
import ModalAddImage from '@components/modals/AddImage/ModalAddImage';
import { useAppSelector } from '@core/hooks/appHooks';
import {
  deleteVendorImage,
  listenToImageResizing,
  uploadVendorImage,
} from '@core/services/networkService/functions/_storage';
import { selectVendor } from '@core/store/appSlice/appSlice';
import DemmiCard from '@demmi-ui/Card/Card';
import DemmiIconButton from '@demmi-ui/IconButton';
import DemmiInput from '@demmi-ui/Input';
import { demmiRequest } from '@helpers/app.helper';
import { DemmiFS, Logger } from '@subhanhabib/demmilib';
import { NetworkService } from '@core/services/networkService/networkService';

interface Props {
  images: DemmiFS.DemmiImageResource[];
  setImages: React.Dispatch<React.SetStateAction<DemmiFS.DemmiImageResource[]>>;
}

const Images: React.FC<Props> = ({ images, setImages }) => {
  const CSSBlock = 'settings-images';
  const vendor = useAppSelector(selectVendor);
  const [isDoingRequest, setIsDoingRequest] = useState(false);

  const onClickDelete = (i: number) => {
    if (vendor) {
      setIsDoingRequest(true);
      demmiRequest(deleteVendorImage(vendor.docID, i)).then(res => {
        if (res === false) {
          // TODO: show error toast
        } else {
          const updatedImages = [...images.slice(0, i), ...images.slice(i + 1)];
          setImages(updatedImages);
        }
        setIsDoingRequest(false);
      });
    }
  };

  const onImageConfirm = (file: File) => {
    if (vendor) {
      const filename = DemmiFS.ImageHelper.Vendor.generateVendorImageName(
        file,
        vendor.docID,
        vendor.name,
      );
      Logger({ objs: { file, filename } }, onImageConfirm);

      setIsDoingRequest(true);
      demmiRequest(uploadVendorImage(vendor.docID, file, filename)).then(
        imageResource => {
          if (imageResource) {
            demmiRequest(
              NetworkService.Vendors.updateVendorImages(vendor.docID, [
                ...images,
                imageResource,
              ]),
            ).then(async () => {
              const unsubscribe = await listenToImageResizing(
                vendor.docID,
                filename,
                success => {
                  if (success) {
                    setImages([...images, imageResource]);
                    setIsDoingRequest(false);
                    unsubscribe();
                  }
                },
              );

              // Don't forget to unsubscribe from the Firestore listener when the component unmounts or when you no longer need it
              // return () => unsubscribe();
            });
          } else {
            // TODO: Add toast.
            setIsDoingRequest(false);
          }
        },
      );
    }
  };

  return (
    <DemmiCard
      title="Images"
      className={CSSBlock}
      isLoading={isDoingRequest}
      titleButtons={[
        <ModalAddImage onClose={() => {}} onConfirm={onImageConfirm} key={0} />,
      ]}>
      <div className={`${CSSBlock}__grid`}>
        {images.map((image, i) => (
          <div className={`${CSSBlock}__image-item`} key={i}>
            <div className={`${CSSBlock}__image-wrapper`}>
              <AsyncImage src={image.main} isVendor />
            </div>
            <div className={`${CSSBlock}__image-details`}>
              <DemmiInput
                key={i}
                type="text"
                value={image.main}
                disabled
                onChangeValue={_ => {}}
              />
              <DemmiIconButton
                faIcon="fa-regular fa-trash-can"
                onClick={() => onClickDelete(i)}
              />
            </div>
          </div>
        ))}
      </div>
    </DemmiCard>
  );
};

export default Images;
