import { Toast } from 'radix-ui';

export enum DemmiToastType {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}

export interface DemmiToast extends DemmiToastBase {
  toastType: DemmiToastType;
  uuid: string;
}

export interface DemmiToastBase extends Toast.ToastProps {
  title: string;
  description?: string;
  action?: {
    text: string;
    onClick: () => void;
  };
}
