import React from 'react';

import SidebarButtonsView from './_buttonsView';
import SidebarHeader from './_header';
import { SidebarProps, isSidebarSelfManaged } from './_helper';

const SidebarDesktop: React.FC<SidebarProps> = props => {
  const CSSBlock = 'sidebar';
  const { title, titleButtons, onSearch } = props;
  const isSelfManaged = isSidebarSelfManaged(props);

  return (
    <div className={`${CSSBlock}`}>
      <div
        className={`${CSSBlock} ${isSelfManaged ? `${CSSBlock}--custom-buttons` : ``}`}>
        {(title || titleButtons || onSearch) && <SidebarHeader {...props} />}
        <SidebarButtonsView {...props} />
      </div>
    </div>
  );
};

export default SidebarDesktop;
