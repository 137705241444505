import React, { createContext, useContext, useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '@core/hooks/appHooks';
import { NetworkService } from '@core/services/networkService/networkService';
import {
  selectFirestoreDemmi,
  setFirestoreDemmi,
} from '@core/store/appSlice/appSlice';
import { demmiRequest } from '@helpers/app.helper';
import { DemmiFS } from '@subhanhabib/demmilib';

export const FirestoreDemmiContext = createContext<
  DemmiFS.DemmiConstants | undefined
>(undefined);

export const FirestoreDemmiProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const firestoreDemmi = useAppSelector(selectFirestoreDemmi);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!firestoreDemmi) {
      demmiRequest(NetworkService.Demmi.populate()).then(o =>
        dispatch(setFirestoreDemmi(o)),
      );
    }
  }, [firestoreDemmi, dispatch]);

  return (
    <FirestoreDemmiContext.Provider value={firestoreDemmi}>
      {children}
    </FirestoreDemmiContext.Provider>
  );
};

export const useFirestoreDemmi = (): DemmiFS.DemmiConstants | undefined => {
  const context = useContext(FirestoreDemmiContext);
  if (context === undefined) {
    throw new Error(
      'useFirestoreDemmi must be used within a FirestoreDemmiProvider',
    );
  }
  return context;
};
