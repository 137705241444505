import { TimeSlotsHelper } from '@helpers/timeslots.helper';
import { DemmiFS, DemmiHelpers } from '@subhanhabib/demmilib';

export const onToggleDay =
  (
    day: DemmiFS.DemmiHelper.DayKeys,
    daysDetails: TimeSlotsHelper.DaysDetails,
    setDaysDetails: React.Dispatch<
      React.SetStateAction<TimeSlotsHelper.DaysDetails>
    >,
  ) =>
  (state: boolean) => {
    setDaysDetails({
      ...daysDetails,
      ...{ [day]: { ...daysDetails[day], isOpen: state } },
    });
  };

/**
 * For Open Slots
 */
export const addTimeSlot =
  (
    day: DemmiFS.DemmiHelper.DayKeys,
    setDaysDetails: React.Dispatch<
      React.SetStateAction<TimeSlotsHelper.DaysDetails>
    >,
    key: TimeSlotsHelper.TimeSlotKeys,
  ) =>
  () => {
    setDaysDetails(prevDaysDetails => {
      const updatedDaysDetails = { ...prevDaysDetails };
      updatedDaysDetails[day] = {
        ...updatedDaysDetails[day],
        [key]: [...updatedDaysDetails[day][key], { start: 0, end: 0 }],
      };
      return updatedDaysDetails;
    });
  };

export const removeTimeSlot =
  (
    day: DemmiFS.DemmiHelper.DayKeys,
    setDaysDetails: React.Dispatch<
      React.SetStateAction<TimeSlotsHelper.DaysDetails>
    >,
    key: TimeSlotsHelper.TimeSlotKeys,
  ) =>
  (i: number) =>
  () => {
    setDaysDetails(prevDaysDetails => {
      const updatedDaysDetails = { ...prevDaysDetails };
      updatedDaysDetails[day] = {
        ...updatedDaysDetails[day],
        [key]: updatedDaysDetails[day][key].filter((_, index) => index !== i),
      };
      return updatedDaysDetails;
    });
  };

export const onChangeTime =
  (
    day: DemmiFS.DemmiHelper.DayKeys,
    setDaysDetails: React.Dispatch<
      React.SetStateAction<TimeSlotsHelper.DaysDetails>
    >,
    key: TimeSlotsHelper.TimeSlotKeys,
  ) =>
  (i: number, type: 'start' | 'end') =>
  (value: number) => {
    setDaysDetails(prevDaysDetails => {
      const updatedDaysDetails = { ...prevDaysDetails };
      updatedDaysDetails[day] = {
        ...updatedDaysDetails[day],
        [key]: updatedDaysDetails[day][key].map((interval, index) =>
          index === i ? { ...interval, [type]: value } : interval,
        ),
      };
      return updatedDaysDetails;
    });
  };

export const mapHoursToDetails = (
  hours: DemmiFS.VendorOpeningHours,
): TimeSlotsHelper.DaysDetails => {
  const details: Partial<TimeSlotsHelper.DaysDetails> = {};
  Object.values(DemmiFS.DemmiHelper.DayKeys).forEach(day => {
    details[day] = {
      isOpen: DemmiHelpers.VendorTimeSlotsHelper.isOpenOnDay(hours[day]),
      openSlots: hours[day].intervals,
      exceptionSlots: hours[day].breaks || [],
    };
  });

  return details as TimeSlotsHelper.DaysDetails;
};

export const mapDaysDetailsToHours = (
  daysDetails: TimeSlotsHelper.DaysDetails,
) =>
  Object.entries(daysDetails).reduce(
    (acc: DemmiFS.VendorOpeningHours, [key, details]) => {
      return {
        ...acc,
        [key]: {
          intervals: details.openSlots,
          breaks: details.exceptionSlots,
        },
      };
    },
    {} as DemmiFS.VendorOpeningHours,
  );

export const hasOpeningHoursChanged = (
  current: DemmiFS.VendorOpeningHours,
  newDetails: TimeSlotsHelper.DaysDetails,
) => {
  const currentDetails = mapHoursToDetails(current);
  for (const day of Object.keys(
    currentDetails,
  ) as DemmiFS.DemmiHelper.DayKeys[]) {
    if (
      currentDetails[day]!.isOpen !== newDetails[day].isOpen ||
      currentDetails[day].openSlots.length !== newDetails[day].openSlots.length
    ) {
      return true;
    }
    for (let i = 0; i < currentDetails[day].openSlots.length; i++) {
      if (
        currentDetails[day].openSlots[i].start !==
          newDetails[day].openSlots[i].start ||
        currentDetails[day].openSlots[i].end !==
          newDetails[day].openSlots[i].end
      ) {
        return true;
      }
    }
  }
  return false;
};

export const isOpeningHoursValid = (
  daysDetails: TimeSlotsHelper.DaysDetails,
) => {
  for (const day of Object.values(daysDetails)) {
    if (day.isOpen) {
      if (day.openSlots.length === 0) return false;
      for (const slot of day.openSlots) {
        if (slot.start >= slot.end) return false;
      }
      for (const slot of day.exceptionSlots) {
        if (slot.start >= slot.end) return false;
      }
    }
  }
  return true;
};
