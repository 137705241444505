import { ThemeHelper } from '@helpers/theme.helper';

export interface AppSnapshot {
  activeTab: File;
}

export enum LSKeys {
  THEME_MODE = 'THEME_MODE',
}

export class LocalStorage {
  static get(key: LSKeys): ThemeHelper.ThemeMode | undefined {
    if (key === LSKeys.THEME_MODE) {
      const val = localStorage.getItem(key);
      switch (val) {
        case ThemeHelper.ThemeModeConstants.LIGHT:
          return ThemeHelper.ThemeModeConstants.LIGHT;
        case ThemeHelper.ThemeModeConstants.DARK:
          return ThemeHelper.ThemeModeConstants.DARK;
        default:
          return ThemeHelper.ThemeModeConstants.SYSTEM;
      }
    }
  }

  static set(key: LSKeys, value: ThemeHelper.ThemeMode) {
    if (key === LSKeys.THEME_MODE) {
      localStorage.setItem(key, value as ThemeHelper.ThemeMode);
    }
  }
}
