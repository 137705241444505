import { matchPath } from 'react-router-dom';

export enum SearchParams {
  PRODUCT_ID = 'productID',
  ORDER_ID = 'orderID',
  COUPON_ID = 'couponCode',
  REVIEW_ID = 'reviewID',
  ACTIVE_TAB = 'activeTab',
}

export enum RoutingViews {
  HOME = 'home',
  INVENTORY = 'inventory',
  ANALYTICS = 'analytics',
  REVIEWS = 'reviews',
  COUPONS = 'coupons',

  ORDERS = 'orders',
  ORDERS_INCOMING = 'orders-incoming',
  ORDERS_COMPLETED = 'orders-completed',
  ORDERS_REFUNDS = 'orders-refunds',
  ORDERS_ALL = 'orders-all',

  SETTINGS = 'settings',
  SETTINGS_DETAILS = 'settings-details',
  SETTINGS_IMAGES = 'settings-images',
  SETTINGS_LOCATION = 'settings-location',
  SETTINGS_OPENING_HOURS = 'settings-opening-hours',
  SETTINGS_COLLECTION_SLOTS = 'settings-collection-slots',
  SETTINGS_DELIVERY_SLOTS = 'settings-delivery-slots',
  SETTINGS_THEME = 'settings-theme',
}

export const routingPaths = {
  [RoutingViews.HOME]: `/${RoutingViews.HOME}`,
  [RoutingViews.INVENTORY]: `/${RoutingViews.INVENTORY}`,
  [RoutingViews.ANALYTICS]: `/${RoutingViews.ANALYTICS}`,
  [RoutingViews.REVIEWS]: `/${RoutingViews.REVIEWS}`,
  [RoutingViews.COUPONS]: `/${RoutingViews.COUPONS}`,

  [RoutingViews.ORDERS]: `/${RoutingViews.ORDERS}`,
  [RoutingViews.ORDERS_INCOMING]: `/${RoutingViews.ORDERS}/${RoutingViews.ORDERS_INCOMING}`,
  [RoutingViews.ORDERS_COMPLETED]: `/${RoutingViews.ORDERS}/${RoutingViews.ORDERS_COMPLETED}`,
  [RoutingViews.ORDERS_REFUNDS]: `/${RoutingViews.ORDERS}/${RoutingViews.ORDERS_REFUNDS}`,
  [RoutingViews.ORDERS_ALL]: `/${RoutingViews.ORDERS}/${RoutingViews.ORDERS_ALL}`,

  [RoutingViews.SETTINGS]: `/${RoutingViews.SETTINGS}`,
  [RoutingViews.SETTINGS_DETAILS]: `/${RoutingViews.SETTINGS}/${RoutingViews.SETTINGS_DETAILS}`,
  [RoutingViews.SETTINGS_IMAGES]: `/${RoutingViews.SETTINGS}/${RoutingViews.SETTINGS_IMAGES}`,
  [RoutingViews.SETTINGS_LOCATION]: `/${RoutingViews.SETTINGS}/${RoutingViews.SETTINGS_LOCATION}`,
  [RoutingViews.SETTINGS_OPENING_HOURS]: `/${RoutingViews.SETTINGS}/${RoutingViews.SETTINGS_OPENING_HOURS}`,
  [RoutingViews.SETTINGS_COLLECTION_SLOTS]: `/${RoutingViews.SETTINGS}/${RoutingViews.SETTINGS_COLLECTION_SLOTS}`,
  [RoutingViews.SETTINGS_DELIVERY_SLOTS]: `/${RoutingViews.SETTINGS}/${RoutingViews.SETTINGS_DELIVERY_SLOTS}`,
  [RoutingViews.SETTINGS_THEME]: `/${RoutingViews.SETTINGS}/${RoutingViews.SETTINGS_THEME}`,
};

export const getViewFromPath = (
  path: string,
  exact = true,
  { exclude }: { exclude?: RoutingViews[] } = {},
) => {
  return Object.values(RoutingViews).find(view => {
    if (exclude && exclude.includes(view)) return false;
    return matchPath(
      exact ? routingPaths[view] : routingPaths[view] + '/*',
      path,
    );
  });
};
