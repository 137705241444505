import React, { useState } from 'react';

import ElementLoadingIndicator from '@components/loadingIntro/ElementLoadingIndicator';
import DemmiIconButton from '@demmi-ui/IconButton';

import DemmiCardTabs from './CardTabs';
import DemmiCardTitleArea from './CardTitleArea';
import {
  DemmiCardProps,
  DemmiCardPropsDefault,
  DemmiCardPropsSelfManagedTabbedContent,
  DemmiCardPropsTabbedContent,
  doPropsHaveTabs,
  getSpreadableProps,
  isEditableContent,
  isSelfManagedTabbedContent,
  useActiveTabState,
} from './_helper';

const getTitleButtons = (props: DemmiCardProps) => {
  if (isEditableContent(props)) {
    return !props.editMode
      ? [
          <DemmiIconButton
            key={'edit'}
            faIcon="fa-pencil"
            onClick={props.onClickEdit}
          />,
        ]
      : [
          <DemmiIconButton
            key={'cancel'}
            faIcon="fa-cancel"
            onClick={props.onCancel}
          />,
          <DemmiIconButton
            key={'save'}
            disabled={!props.isValid}
            faIcon="fa-save"
          />,
        ];
  }
  return (props as DemmiCardPropsDefault).titleButtons;
};

const renderTabs = (
  props: DemmiCardPropsSelfManagedTabbedContent | DemmiCardPropsTabbedContent,
  activeTab: number,
  setActiveTab: React.Dispatch<React.SetStateAction<number>>,
) => (
  <DemmiCardTabs
    tabs={props.tabs}
    activeTab={activeTab}
    setActiveTab={
      isSelfManagedTabbedContent(props) ? props.onClickTab : setActiveTab
    }
  />
);

const DemmiCard: React.FC<DemmiCardProps> = ({
  rootClassName,
  children,
  title,
  subtitle,
  darkOnly,
  isLoading,
  collapsible,
  noPadding,
  collapsed: startCollapsed = false,
  ...props
}) => {
  const CSSBlock = 'demmi-ui-card';
  const [collapsed, setCollapsed] = useState(startCollapsed);

  // Tabs
  const hasTabs = doPropsHaveTabs(props);
  const [activeTab, setActiveTab] = useActiveTabState(props);
  if (hasTabs)
    props = props as
      | DemmiCardPropsSelfManagedTabbedContent
      | DemmiCardPropsTabbedContent;

  // Title Bar
  const titleButtons = getTitleButtons(props);
  const titleExtras = isEditableContent(props)
    ? undefined
    : (props as DemmiCardPropsDefault).titleExtras;

  return (
    <div
      {...getSpreadableProps(props)}
      className={`${CSSBlock} ${darkOnly ? `${CSSBlock}--dark-only` : ''} ${
        rootClassName ? rootClassName : ''
      } ${collapsed ? `${CSSBlock}--collapsed` : ''} ${
        isLoading ? `${CSSBlock}--loading` : ``
      } ${noPadding ? `${CSSBlock}--no-padding` : ``} ${
        hasTabs ? `${CSSBlock}--has-tabs` : ``
      }`}>
      {hasTabs &&
        renderTabs(
          props as
            | DemmiCardPropsSelfManagedTabbedContent
            | DemmiCardPropsTabbedContent,
          activeTab,
          setActiveTab,
        )}
      <span className={`${CSSBlock}__content`}>
        {title && (
          <DemmiCardTitleArea
            collapsible={!!collapsible}
            collapsed={collapsed}
            setCollapsed={setCollapsed}
            title={title}
            subtitle={subtitle}
            titleButtons={titleButtons}
            titleExtras={titleExtras}
          />
        )}
        <div
          className={`${CSSBlock}__main-content ${
            props.className ? props.className : ``
          }`}>
          {hasTabs
            ? (
                props as
                  | DemmiCardPropsSelfManagedTabbedContent
                  | DemmiCardPropsTabbedContent
              ).tabContents[activeTab]
            : children}
        </div>
        <ElementLoadingIndicator isLoading={isLoading ?? false} />
      </span>
    </div>
  );
};

export default DemmiCard;
