import React, { useState } from 'react';

import { useAppSelector } from '@core/hooks/appHooks';
import { selectVendor } from '@core/store/appSlice/appSlice';
import DemmiButton from '@demmi-ui/Button';
import DemmiInput from '@demmi-ui/Input';
import { DemmiFS } from '@subhanhabib/demmilib';
import { Timestamp } from 'firebase/firestore';
import { NetworkService } from '@core/services/networkService/networkService';

interface Props {
  onChatCreated: (chatID: string) => void;
}

const DemmiSupportPanelNewChat: React.FC<Props> = ({ onChatCreated }) => {
  const CSSBlock = 'demmi-support-panel-new-chat';
  const [title, setTitle] = useState('');
  const vendor = useAppSelector(selectVendor);

  const createChat = async (title: string) => {
    if (!vendor) return;
    const chat: DemmiFS.FSDemmiSupport = {
      title,
      vendorID: vendor.docID,
      createdAt: Timestamp.now(),
    };

    const id = await NetworkService.DemmiSupport.createDemmiSupportChat(chat);
    onChatCreated(id);
  };

  return (
    <div className={`${CSSBlock}`}>
      <div className={`${CSSBlock}__title`}>Start a new conversation</div>
      <DemmiInput type="text" value={title} onChangeValue={setTitle} />
      <DemmiButton
        faIcon="fa-plus"
        text="Create Chat"
        disabled={title.length < 5}
        onClick={() => createChat(title)}
      />
    </div>
  );
};

export default DemmiSupportPanelNewChat;
