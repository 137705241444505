import { VendorMappedScheduleDay } from '@models/vendorMappedSchedule.model';
import { DemmiFS } from '@subhanhabib/demmilib';

const _emptyDay = (
  day: DemmiFS.DemmiHelper.DayKeys,
): VendorMappedScheduleDay => ({
  dayOfWeek: day,
  startTime: 0,
  endTime: 0,
  slotDuration: DemmiFS.VendorHelper.TimeSlotScheduleDuration.MIN_15,
  capacity: 0,
  enabled: true,
  breaks: [],
});

export enum COLLECTION_SCHEDULE_VALIDATION_ERRORS {
  MISSING_DURATION = 'Missing duration',
  MISSING_CAPACITY = 'Missing capacity',
  INVALID_TIME = 'Invalid time',
  UNKNOW_ERROR = 'Unknown error',
}

/**
 * Helper methods
 */

const _isDayValid = (
  day?: VendorMappedScheduleDay,
): COLLECTION_SCHEDULE_VALIDATION_ERRORS | undefined => {
  if (!day || !day.enabled) return;
  if (day.capacity === 0)
    return COLLECTION_SCHEDULE_VALIDATION_ERRORS.MISSING_CAPACITY;
  if (!(day.slotDuration in DemmiFS.VendorHelper.TimeSlotScheduleDuration))
    return COLLECTION_SCHEDULE_VALIDATION_ERRORS.MISSING_DURATION;
  if (day.startTime >= day.endTime)
    return COLLECTION_SCHEDULE_VALIDATION_ERRORS.INVALID_TIME;
  return;
};

export const isCollectionScheduleValid = (
  schedule: VendorMappedScheduleDay[],
): boolean => {
  const enabledDays = schedule.filter(s => s.enabled);
  return enabledDays.length > 0 && enabledDays.every(day => _isDayValid(day));
};

export type VendorCollectionScheduleValidation = {
  [key in DemmiFS.DemmiHelper.DayKeys]:
    | COLLECTION_SCHEDULE_VALIDATION_ERRORS
    | undefined;
};

export const isCollectionScheduleDayValid = (
  schedule: VendorMappedScheduleDay[],
): VendorCollectionScheduleValidation => {
  return Object.values(DemmiFS.DemmiHelper.DayKeys).reduce(
    (acc, day) => ({
      ...acc,
      [day]: _isDayValid(schedule.find(s => s.dayOfWeek === day)!),
    }),
    {} as VendorCollectionScheduleValidation,
  );
};

/**
 * On change UI elements (toggle, time, duration, capacity, breaks)
 */

export const toggleDay = (
  day: DemmiFS.DemmiHelper.DayKeys,
  schedules: VendorMappedScheduleDay[],
  enable: boolean,
): VendorMappedScheduleDay[] => {
  return enable ? enableDay(day, schedules) : disableDay(day, schedules);
};

export const enableDay = (
  day: DemmiFS.DemmiHelper.DayKeys,
  schedules: VendorMappedScheduleDay[],
): VendorMappedScheduleDay[] => {
  if (!schedules.find(s => s.dayOfWeek === day)) {
    schedules.push(_emptyDay(day));
    return schedules;
  } else {
    return schedules.map(s =>
      s.dayOfWeek === day ? { ...s, enabled: true } : s,
    );
  }
};

export const disableDay = (
  day: DemmiFS.DemmiHelper.DayKeys,
  schedules: VendorMappedScheduleDay[],
): VendorMappedScheduleDay[] => {
  return schedules.map(s =>
    s.dayOfWeek === day ? { ...s, enabled: false } : s,
  );
};

export const onChangeDayTime = (
  day: DemmiFS.DemmiHelper.DayKeys,
  schedules: VendorMappedScheduleDay[],
  type: 'start' | 'end',
  val: number,
): VendorMappedScheduleDay[] => {
  return schedules.map(s =>
    s.dayOfWeek === day
      ? { ...s, [type === 'start' ? 'startTime' : 'endTime']: val }
      : s,
  );
};

export const onChangeDayDuration = (
  day: DemmiFS.DemmiHelper.DayKeys,
  schedules: VendorMappedScheduleDay[],
  duration: DemmiFS.VendorHelper.TimeSlotScheduleDuration,
): VendorMappedScheduleDay[] => {
  return schedules.map(s =>
    s.dayOfWeek === day ? { ...s, slotDuration: duration } : s,
  );
};

export const onChangeDayCapacity = (
  day: DemmiFS.DemmiHelper.DayKeys,
  schedules: VendorMappedScheduleDay[],
  capacity: number,
): VendorMappedScheduleDay[] => {
  return schedules.map(s => (s.dayOfWeek === day ? { ...s, capacity } : s));
};

export const onAddDayBreak = (
  day: DemmiFS.DemmiHelper.DayKeys,
  schedules: VendorMappedScheduleDay[],
): VendorMappedScheduleDay[] => {
  return schedules.map(s =>
    s.dayOfWeek === day
      ? { ...s, breaks: [...s.breaks, { start: 0, end: 0 }] }
      : s,
  );
};

export const onRemoveDayBreak = (
  day: DemmiFS.DemmiHelper.DayKeys,
  schedules: VendorMappedScheduleDay[],
  i: number,
): VendorMappedScheduleDay[] => {
  return schedules.map(s =>
    s.dayOfWeek === day
      ? { ...s, breaks: s.breaks.filter((_, index) => index !== i) }
      : s,
  );
};

export const onUpdateDayBreak = (
  day: DemmiFS.DemmiHelper.DayKeys,
  schedules: VendorMappedScheduleDay[],
  i: number,
  key: keyof DemmiFS.VendorHelper.TimeInterval,
  val: number,
): VendorMappedScheduleDay[] => {
  return schedules.map(s => {
    if (s.dayOfWeek === day) {
      const updatedBreaks = [...s.breaks];
      updatedBreaks[i] = { ...updatedBreaks[i], [key]: val };
      return { ...s, breaks: updatedBreaks };
    } else {
      return s;
    }
  });
};
